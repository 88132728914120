<template>
  <form class="login" @submit.prevent='loginUser'>
    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4>{{ this.state.lan.login.title }}</h4>
          <p class="deactive">{{ this.state.lan.login.GoogleTitle }}</p>
          <button @click.prevent="googleSso" >
            <inline-svg :src="require('../../assets/Authentication/google.svg')"/>
            <span :class="{'rtl':state.lang ==='fa', 'ltr':state.lang ==='en'} ">{{ this.state.lan.login.GoogleBtn }}</span>
          </button>



        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="mail">
        <label for="email">{{state.lan.login.EmailLable}}</label>
      <div class="mail-container">
        <label>
          <inline-svg :src="require('../../assets/Authentication/email.svg')"/>
        </label>
        <input :placeholder="state.lan.login.EmailPlace" name="email" id="email" type="email" v-model="data.email">
      </div>
      </div>
      <div class="pass">
        <label for="pass">{{state.lan.login.PassLabel}}</label>
        <div class="pass-container">
          <label>
            <inline-svg :src="require('../../assets/Authentication/pass.svg')"/>
          </label>
          <input id="pass" :placeholder="state.lan.login.PassPlace" :type=type v-model="data.password"/>
          <a href="" @click.prevent="showingPass">
            <inline-svg :src="require('../../assets/Authentication/show.svg')"/>
          </a>
        </div>
        <div class="forget">
          <a @click.prevent="$router.push('/forget')">{{ state.lan.login.Forget }}</a>
        </div>
      </div>
    </div>
    <vue-recaptcha class="recap" sitekey="6LcAfcEgAAAAAFplxs1jMuOdRLMe445ZljkcEjLH
" ref="recaptcha" @verify="verifyMethod" :loadRecaptchaScript="true"></vue-recaptcha>
    <button :disabled="!data.email || !data.password" >{{ state.lan.login.Submit }}</button>
    <div class="create">
      <p>{{state.lan.login.NotMember}} </p>
      <a  @click.prevent="$router.push('/sign-up')">{{state.lan.login.Signup}}</a>
    </div>
  </form>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
export default {
  name: "Login",
  data() {
    return {
      type: 'password',
      data:{
        email: "",
        password: "",
        osType: "",
        deviceType: "",
        browser: "",
        referralCode: "",
   recaptcha: false,
      }
    }
  },
  components:{
        VueRecaptcha
  },
  methods: {

        verifyMethod() {

      this.recaptcha = true;
    },






    showingPass() {









      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },

    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        this.emailError = "ایمیل صحیح وارد نمایید";
        this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    googleSso() {
      window.location.href = this.$google_sso;
    },
    //login with google
    async loginUser() {
      this.checkEmail();
      this.state.loading = true;
      const res = await this.$axios.post("/users/login", this.data);

      if (res.message == "login successful, otp is required") {
        //this function will set username as email into cookies
        this.$cookies.set("username", this.data.email);
        this.state.userInfo.email = this.data.email;
        this.state.userInfo.mobilenumber =
            res.baseDTO.starTwoStepAuthentication || "";
        this.state.towStepAuthenticationType =
            res.baseDTO.towStepAuthenticationType;
 console.log(this.state.towStepAuthenticationType)
        await this.$router.push({name:'OTP'});
      }
      // else if (res.token) {
      //   this.$setToken(res.token, this.state.userInfo.remember)
      //   await this.$router.push('/profile')
      // }
    },
  },
  mounted() {
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();
  }
}
</script>

<style lang="scss" scoped>


.pass-container {
  a {
    display: flex;
    align-items: center;
  }
}

.recap{
  align-self: center;
}

.rtl {

  margin-right: 27%;

}

.ltr {
  margin-left: 22.5%;
}


.login {
  width: 100%;
  max-width: 390px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px 24px;
  row-gap: 24px;
  //first row contains title and google login
  .first-row {
    //main title
    .title {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      button {
        display: flex;
        border-radius: 4px !important;
        align-items: center;
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 400;
        background: #04235C;
        border: 1px solid rgba(178, 176, 176, 0.5);
        min-height: 40px;
        padding: 0 12px;
      }


    }

    //texts of title

  }

  //second row contains inputs
  .second-row {
//second row styles
    display: flex;
    flex-direction: column;
    gap: 18px;
    //email and password main container
    .mail, .pass {


      display: flex;

      flex-direction: column;
      row-gap: 6px;
    }

    #email , #pass{
      width: 100%;
    }
    //container of the email tha contains email input and svg in it
    .mail-container , .pass-container{
      gap: 5px;
      display: flex;
      border: 1px solid rgba(179, 176, 176, 0.5);
      border-radius: 4px;
      background: #131330;
      align-items: center;
      height: 36px;
      padding: 0 9px;
      label{
        display: flex;
      }
    }
.forget{
 a{
   color: var(--light-blue)
 }
}


  }
}
//recaptcha image
img{
  align-self: center;
}
//submit button
button{

  background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
  border-radius: 12px;
height: 40px;

}
//create account text
.create{
  display: flex;
  justify-content: center;
  p,a{
    font-size: clamp(10px,1vw,13px);
  }
  a{
    color: var(--light-blue)
  }

}


//passed some style to deactive text
.deactive {
  font-size: clamp(10px, 1vw, 13px);
}

@media (max-width: 650px){
  .login{
    width: 90%;
    //row-gap: 5px;
  }

  @media(max-width: 500px){
    .mail-container ,.pass-container{
      >label{
        >svg{
          width: 12px;
        }
      }
    }
  }

}
</style>