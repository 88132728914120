<template>
  <div class="wallet">
    <div class="inventory-container">
      <inventory :chartData="chartData" :wallets="wallets" :contents="contents" :overall="overall" :tomancash="tomancash" :tethercash="tethercash" />

    </div>
    <div class="table"><main-table :wallets="wallets" /></div>
  </div>
</template>

<script>
import Inventory from "./Inventory.vue";
import MainTable from "./tables/MainTable.vue";
export default {
  components: { Inventory, MainTable },
  name: "Wallet",
  data(){
return {
  wallets:{},
  contents:[],
  chartData:'',
  overall:0,
  tomancash:0,
  tethercash:0,
}
  },
  methods:{
    async getWalletData() {
      this.state.loading=true;
      const res = await this.$axios(`/wallets/customer-addresses`)
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contents = entireRes
      this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
      this.wallets = entireRes.filter(e => e[0] !== "TOMAN")
      let sum = 0;

      for (let i = 0; i < entireRes.length; i++) {

        sum += entireRes[i][1].credit
// console.log(sum)
        this.overall = sum;

      }

      this.tomancash = res["TOMAN"].credit
      this.tethercash = res["TETHER"].credit

this.state.loading=false;
    },
  },
  async mounted() {
    await this.getWalletData()
  },

};
</script>

<style lang="scss" scoped>
/* 
TODO:
1. add api to rial Deposit table & Check out rial withdrawal table 
FIXME: another look into sidebar and planning for rest of the project
*/
.wallet {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
</style>
