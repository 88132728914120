import { render, staticRenderFns } from "./Userinfo.vue?vue&type=template&id=0da4913e&scoped=true&"
import script from "./Userinfo.vue?vue&type=script&lang=js&"
export * from "./Userinfo.vue?vue&type=script&lang=js&"
import style0 from "./Userinfo.vue?vue&type=style&index=0&id=0da4913e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0da4913e",
  null
  
)

export default component.exports