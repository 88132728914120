<template>
<div class="mc d-flex">
  <div class="trading-info" :key="tradeFrom + tradeTo + 'wow'">
    <ul>
      <li>
        <span>{{ tradeTo ==='TOMAN' ? $toLocal(header.lastPrice,$decimal['TOMAN']): $toLocal(header.lastPrice,$decimal[tradeFrom])  }}</span>
        <span class="titles">{{state.lan.trade.mc.mc}}</span>
      </li>
      <li>
        <span class="buy green">{{tradeTo ==='TOMAN' ? $toLocal(header.maxPrice,$decimal['TOMAN']): $toLocal(header.maxPrice,$decimal[tradeFrom]) }}</span>
        <span class="titles">{{state.lan.trade.mc.max}}</span>

      </li>
      <li>
        <span class="red">
{{tradeTo ==='TOMAN' ? $toLocal(header.minPrice,$decimal['TOMAN']): $toLocal(header.minPrice,$decimal[tradeFrom]) }}
  </span>
        <span class="titles">{{state.lan.trade.mc.min}}</span>



      </li>

      <li>



        <span>{{tradeTo ==='TOMAN' ? $toLocal(header.last24HourVolume,$decimal['TOMAN']): $toLocal(header.last24HourVolume,$decimal[tradeFrom]) }}</span>
        <span class="titles">{{state.lan.trade.mc.vol}}</span>
      </li>
      <li>
        <span class="positive">{{tradeTo ==='TOMAN' ? $toLocal(header.last24HourChange,$decimal['TOMAN']): $toLocal(header.last24HourChange,$decimal[tradeFrom]) }} %</span>
        <span class="titles">{{state.lan.trade.mc.change}}</span>

      </li>
    </ul>
  </div>
  <div class="buttons" v-if="state.lang==='fa'">

    <button :class="{'activate': !toman , 'not-active' : toman===true }" @click="changeToman">تتر</button>
    <button :class="{'activate': toman , 'not-active' : toman===false }" @click="changeTether">تومان</button>
  </div>
</div>
</template>

<script>
import Crypto from '../../../library/cryptoTrade'
import {tradeTo, tradeFrom} from "@/library/reuseableFunction";

export default {

  name: "MarketCap",
  data(){
    return{
      coins:Crypto,
      headercoin:null,
      toman:true,
      header:{}
    }
  },

  watch:{
    tradeFrom(){
      this.getHeaderCoin()
    },
    tradeTo(){
      this.getHeaderCoin()
    }
  },


  computed:{
tradeTo,tradeFrom
  },
  methods:{
    changeToman(){
   this.toman=false
      this.$router.push(`/trade/TETHER/${this.tradeFrom}`)
      this.getHeaderCoin()
    },
    changeTether(){
      this.toman=true;
      this.$router.push(`/trade/TOMAN/${this.tradeFrom}`)
      this.getHeaderCoin()
    },
    async getHeaderCoin(){
      const res = await this.$axios.get('/trades/market-info?market_type=' + `${this.$route.params.tradeFrom}_${this.$route.params.tradeTo}`,{
        params:{
          weeklyDataIncluded:false
        }
      })


      console.log(res)
      this.header = res.content[0]
      console.log(this.header)
    }

  },
  mounted() {
    this.headercoin=this.coins[0]
    this.getHeaderCoin()
  }
}
</script>

<style lang="scss" scoped>
.buttons{
flex:1 1 200px;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  button{
    width: 50%;
  }
}

.trading-info{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex:2 2 auto;
  >ul{
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  li{
    display: flex;
    align-items: center;
    gap: 8px;
  }

}
.mc{

  min-height: 55px;
}
.titles{
  font-size: clamp(10px,1vw,12px);
  color: var(--gray);
}

//activation of buttons
.activate{
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active{
  border-radius: 20px;
  border:1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color:rgba(61, 61, 61, 0.98) ;

}


@media(max-width: 800px){
  .mc{
    flex-wrap:wrap;
  }
}

</style>