<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="close=true">
      <div class="modal">
        <img
          @click="closeModale"
          class="close-icon"
          src="../../../assets/profile/closeIcon.svg"
          alt=""
        />
        <span class="message-title"> {{state.lan.modals.title}} </span>
        <div class="message">
          <p>
     {{state.lan.modals.textmobile}}
          </p>

        </div>
          <div class="verification d-flex flex-col gap-xss">
    <CodeInput
:loading="false" @change="onChange" @complete="submit" ref="code-input"
    />
  <div v-if="finished" class="timer">
    <a @click.prevent="finished = !finished">0:00</a>
  </div>
  <div v-else class="timer">
    {{ display }}
  </div>
  </div>
        <button v-if="!finished" @click='submit' class="yes">{{ state.lan.modals.submit }}</button>
        <button v-if="finished" class="yes" @click='send'>{{ fa? 'ارسال مجدد':'Resend Code' }}</button>
    
    
      </div>
    </div>
  </transition>
</template>

<script>
import Verification from "../../Tools/Verification"
import CodeInput from "vue-verification-code-input";
export default {
  name: "ExitModal",
  components: { Verification , CodeInput},
  data() {
    return {
      fa:this.state.lang=='fa'  ,
      close: false,
               now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 10}),
            tick: null,
            disablebtn: true,
            smscode: [],
                 otp: {
        code: '',
      },
    };
  },
  methods: {
    closeModale() {
      this.$emit("closeModal");
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
        async send() {
let fa = this.state.lang =='fa'
      // this.reset()
      this.state.loading = true
      const a = {
        EMAIL: 'ایمیل',
        SMS: 'شماره'
      }
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=EMAIL`)
      if (res.message === 'Verification Code Sent') {
       fa? this.$error('', 'کد به ' + a[this.twoStep] + ' شما ارسال شد', 'success') :  this.$error('', 'Code' + a[this.twoStep] + 'Sent', 'success')
       
        if (this.countDown === 0)
          this.countDown = 120
        this.countDownTimer()
      }
    },
    // close() {
    //     this.$emit('close');
    // },
    onChange(e) {
      this.otp.code = e
    },
    async submit() {
      let fa = this.state.lang=='fa'
      this.disablebtn = false
      // const a = {
      //     'EMAIL': 'ایمیل',
      //     'SMS': 'پیامک',
      //     'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
      // }
      if (this.otp.code.toString().length === 6 && (this.twoStep === 'GOOGLE_AUTHENTICATOR' || this.countDown !== 0)) {
        if (this.$route.name !== 'Wallet') {
          this.state.loading = true
          const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=SMS&code=${this.otp.code}`)
          if (res) {
            // this.reset()
            this.$emit('closeModal')
           fa? this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + 'شماره' + ' تغییر یافت.'):  this.$emit('submit', 'OTP ' + 'changed To' + 'Mobile Number')
          }
        } else {
          this.$emit('submit', this.otp.code)
        }
      }
    },
  },
      watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".modal , .exit")) {
        this.$emit("closeModal", this.close);
      } else {
        return true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal {
  display: flex;
  row-gap: 50px;
  flex-direction: column;
  align-items: center;
  min-width: 367px;
  min-height: 276px;
  background: #191730;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.message-title {
  font-weight: bold;
  font-size: 18px;
  //color: #febc00;
}

.message {
  max-width: 340px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.yes {
  background: #19196f;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 400px) {
  .modal {
    width: 90%;
    min-width: 300px;
  }
}
</style>
<style lang="scss" scoped>
.react-code-input-container {
  direction: ltr !important;
  display: flex;
  column-gap: 17px;
  width: unset !important;
  align-self: center;
}

.react-code-input {
  display: flex;
  gap: 17px;

  input {
    color:white !important;
    border: 1px solid rgba(178, 176, 176, 0.5) !important;
    border-radius: 4px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    &:focus{
      border: 1px solid var(--light-blue) !important;
    }

  }
}

@media(max-width: 403px) {
  .otp{
    width: 90% !important;
    min-width: unset !important;
  }
  .react-code-input {
    gap: 5px;
  }
}
</style>