<template>
  <div class="otp">
    <div class="otp-title">
      <h4>{{ state.lan.otp.title }}</h4>
      <p v-if="state.lang == 'fa'">
        کد ارسال شده به
        {{ otp.email }}
        را وارد کنید
      </p>
      <p v-else>Enter the code sent to{{ otp.email }}</p>
    </div>
    <div class="input" @paste="paste">
      <CodeInput       @change="onChange"
                       @complete="submit"
                       :fields="num"
                       ref="code-input" class="input" />
      <!--                 v-on:change="onChange"-->
      <!--                 v-on:complete="onComplete"-->

      <div class="timer">
        <div class="timer" v-if="finished">
          <a class="timer">0:00</a>
        </div>
        <div class="timer" v-else>
          {{ display }}
        </div>
    </div>
    <button v-if="!finished" @click.prevent="submit">
      {{ state.lan.otp.submit }}
    </button>
      <button v-if="finished" @click.prevent="send">
        {{state.lang==='fa' ?'ارسال مجدد':'Resend Code'}}
      </button>
    <div class="change">
      <a href="" @click.prevent="$router.push('/login')">{{
        state.lan.otp.change
      }}</a>
    </div>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import {DateTime,Duration} from 'luxon'
import {paste} from '@/library/reuseableFunction'

export default {
  name: "OTP",
  components: {
    CodeInput,

  },
  data() {
    return {
      //timer
      now: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: 120 }),
      tick: null,
      num: 6,
      //otp
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username"),
        mobile: this.state.userInfo.mobile,
        // google: this.state.userInfo.google,
      },
    };
  },

  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods: {
    paste,
    countDownTimer() {
      if (!this.finished) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.state.lang !== 'fa' ? this.$error("زمان شما به اتمام رسید", "") : this.$error('Request Timeout', '');

      }
    },
    async send() {
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local()
          .set({ milliseconds: 0 })
          .plus({ seconds: 120 });

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({ milliseconds: 0 });
      }, 1000);
      this.state.loading = true;

      await this.$axios.post(
          `/users/resend-otp-code?email=${this.$cookies.get("username")}`
      );
      // const text = {
      //     email: 'ایمیل',
      //     sms: 'شماره'
      // }
      this.state.lang == 'fa' ? this.$error(
          "کد جدید به " + this.otp.email + " شما ارسال شد.",
          "",
          "success"
      ) : this.$error(`New code sent to ${this.otp.email}`);
      this.$cookies.set("username", this.state.userInfo.email);
      if (this.countDown === 0) {
        this.countDown = 120;
        this.countDownTimer();
      }
    },
    onChange(e) {
      this.otp.code = e;
    },
    async submit() {
      this.disablebtn = false;
      if (this.countDown !== 0) {
        this.state.loading = true;
        const res = await this.$axios.post("/users/check-otp-code", this.otp);
        if (res.token) {
          await this.$setToken(res.token);
          await this.$router.push({name:'KYC'});
        }
      }
    },
    // reset() {
    //     this.otp.code = ''
    //     for (let i = 0; i < this.num; i++) {
    //         this.$refs['code-input'].values[i] = ''
    //         this.$refs['code-input'].$refs['input_' + i][0].value = ''
    //     }
    //     this.$refs['code-input'].$refs['input_0'][0].focus()
    // }
  },
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
  mounted() {
    console.log(this.state.userInfo)
    this.countDownTimer();
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({ milliseconds: 0 });
    }, 1000);
    if (!this.otp.email) {
      this.$router.push("/login");
    }
    if (this.state.towStepAuthenticationType !== "GOOGLE_AUTHENTICATOR") {
      this.now = DateTime.local().set({ milliseconds: 0 });
      this.end = DateTime.local()
          .set({ milliseconds: 0 })
          .plus({ seconds: 120 });

      this.tick = setInterval(() => {
        this.now = DateTime.local().set({ milliseconds: 0 });
      }, 1000);
    }
  },

};
</script>

<style lang="scss" scoped>
// a little change on timer div :)
.timer {
  padding: 0 10px;
}

//main container of otp
.otp {
  width: 100%;
  max-width: 390px;
  min-height: 397px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px 24px;
  row-gap: 40px;
}

//otp text and titles
.otp-title {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  p {
    color: var(--gray);
    font-size: clamp(10px, 1vw, 13px);
  }
}

//otp input and timer is here
.input {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

//submit btn is here
button {
  display: flex;
  //border-radius: 4px !important;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px, 1vw, 12px);
  font-weight: 400;
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
  border-radius: 12px;
  border: 1px solid rgba(178, 176, 176, 0.5);
  height: 40px;
  padding: 0 12px;
}

//change account
.change {
  align-self: center;

  a {
    color: var(--light-blue);
    font-size: clamp(10px, 1vw, 12px);
    font-weight: 300;
  }
}
</style>
//styles for react code input
