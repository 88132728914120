import { render, staticRenderFns } from "./Invitefriends.vue?vue&type=template&id=325ae1a8&scoped=true&"
import script from "./Invitefriends.vue?vue&type=script&lang=js&"
export * from "./Invitefriends.vue?vue&type=script&lang=js&"
import style0 from "./Invitefriends.vue?vue&type=style&index=0&id=325ae1a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325ae1a8",
  null
  
)

export default component.exports