import { render, staticRenderFns } from "./Contactinfo.vue?vue&type=template&id=518824d8&scoped=true&"
import script from "./Contactinfo.vue?vue&type=script&lang=js&"
export * from "./Contactinfo.vue?vue&type=script&lang=js&"
import style0 from "./Contactinfo.vue?vue&type=style&index=0&id=518824d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518824d8",
  null
  
)

export default component.exports