<template>
  <div class="notifications">
    <h1>{{state.lan.notif.notif}}</h1>
<div class="buttons">
  <button :class="{'activate': all , 'not-active' : all===false }" @click.prevent="all =true , unread=false" >{{state.lan.notif.all}}</button>
  <button :class="{'activate': unread , 'not-active' : unread===false }" @click.prevent="unread =true , all=false">{{state.lan.notif.unread}}</button>
</div>
    <div class="notification-container" v-if="all">
      <div v-for="(item,index) in contents" :key="index" class="notification"  @click="selectIndex(index,item)" >
        <div class="header">
          <div class="title-container" >
<!--            <span  :class="{'checked':''}" class="checkbox" name="terms"></span>-->
            <h4>{{ item.title }}</h4>
          </div>
          <div  class="time-container">
            <div class="time small">
{{item.createdAtDateTime}}
              <img src="../../../assets/Public/selector.svg" alt="">
            </div>

          </div>
        </div>
        <transition v-if="activeIndex === index" name="view">
          <p  class="text">{{item.content}}</p>
        </transition>
      </div>
    </div>
    <div class="notification-container" v-if="unread">
      <div v-for="(item,index) in unRead" :key="index" class="notification"  @click="selectIndex(index,item)" >
        <div class="header">
          <div class="title-container" >
            <span  :class="{'checked':''}" class="checkbox" name="terms"></span>
            <h4>{{ item.title }}</h4>
          </div>
          <div  class="time-container">
            <div class="time small">
              {{item.createdAtDateTime}}
              <img src="../../../assets/Public/selector.svg" alt="">
            </div>

          </div>
        </div>
        <transition v-if="activeIndex === index" name="view">
          <p  class="text">{{item.content}}</p>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'Notifications',
  data () {
    return{
      termscheck:false,
      all:true,
      unread:false,
      notifications:[
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
              ,
     '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
      ],
      activeIndex:null,
      notification:[
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
        {
          title:'lorem',
          text: [
            'لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد'
            ,
            '         Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi consectetur cumque debitis dolorem ex iure laudantium libero molestiae nisi nobis, odit quas recusandae unde. Atque commodi dolorem dolores ducimus quod?'
          ] ,
          year:1400,
          mounth:11,
          day:12,
          hours:13,
          minute:13,
          checked:false,
          complete:false,
        },
      ],
      contents:[],
      unRead:[],
    }
  },
  methods:{
    async getNotifs(){
      const res = await this.$axios('users/notifications')
      console.log(res)
      this.contents = res.content;
      this.unRead = res.content.filter(notif => notif.read === false);
      console.log(this.unRead)
    },
async    selectIndex(index,item){
      console.log(item.id)
await this.$axios.put('users/notifications/read/'+item.id)
if(index !== this.activeIndex){
  this.activeIndex = index;
}

else this.activeIndex = null;








    },

  },
  mounted() {
    this.getNotifs();
  }
}
</script>

<style lang="scss" scoped>

.activate{
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active{
  border-radius: 20px;
  border:1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color:rgba(61, 61, 61, 0.98) ;

}

p{
  color: white;
  font-weight: 400;
  font-size: clamp(10px,3vw,14px);
}
h4,span{
  font-weight: 400;
  font-size: clamp(10px,3vw,14px);
}
h1{
  align-self: flex-start;
  font-weight: 700;
  font-size: clamp(18px,3vw,24px);
}
.view-leave-active {
  transition: height .6s ease-in-out, transform .6s ease;
}

.view-enter-active {
  transition: height .6s ease-in-out, transform .6s ease;
}

.view-enter, .view-leave-to {
  height: 0;
}

.view-enter-to, .view-leave {
  height: 40px;
}


//buttons
.buttons{
  display: flex;
  gap: 15px;
button{
  min-width: 44px;
}
}

.title-container{
  display: flex;
  column-gap: 16px !important;
}
.notifications{


  background: #191730;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  row-gap: 28px;
  box-sizing: border-box;
  padding: 24px;
  width: 100%
}
.notification-container{
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.title-container,.time-container{
  display: flex;
  flex-direction: row;
  width: 20%;
  align-items: center;
  column-gap: 5px;

}
.time-container{
  justify-content: flex-end;
  img{
    cursor: pointer;
  }
}
.notification{
  //background: var(--primary-color);
  width: 100%;
  cursor: pointer;
  border-radius:4px ;
  box-sizing: border-box;
  padding: 10px;
  overflow: hidden;
}
.time{
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  color: var(--gray);
}
.header{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.checkbox{
  //margin: 0 16px;
  cursor: pointer;
  width: clamp(10px,1.5vw,16px);
  height: clamp(10px,1.5vw,16px);
  background: var(--light-blue);
  border-radius: 50%;

}
.checked{
  background: var(--gray);
  border: none !important;
  width: clamp(10px,1.5vw,16px);
  height: clamp(10px,1.5vw,16px);

}
.complete{
  transform: rotate(180deg);
}
@media only screen and(max-width:750px){
  .title-container,.time-container{
    width: 40%;
  }
}
@media only screen and(max-width:400px){
  .time-container{
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  .title-container{
    width: 50%;
  }
}
</style>