<template>
  <div class="depth">
    <div class="buttons">
      <button
        :class="{ activate: depths, 'not-active': depths === false }"
        @click.prevent="(depths = true), (lastt = false)"
      >
        {{ state.lan.trade.depth.md }}
      </button>
      <button
        :class="{ activate: lastt, 'not-active': lastt === false }"
        @click.prevent="(lastt = true), (depths = false)"
      >
        {{ state.lan.trade.depth.rt }}
      </button>
    </div>
    <div class="table-container">
      <table v-if="depths">
        <tr class="tr-head">
          <td>{{ state.lan.trade.depth.price }}</td>
          <td>{{ state.lan.trade.depth.amount }}</td>
          <td>{{ state.lan.trade.depth.total }}</td>
        </tr>
        <tr
          class="tr-body pointer hover"
          :class="{ disabled: item.myOrder }"
          v-for="(item, index) in tableContentsSell"
          :key="index"
          :style="{
            background:
              'linear-gradient(to right,rgba(255, 0, 92, .3) ' +
              Math.floor(item.executedPercent) +
              '%,#030920; 0%)',
          }"
          @click="getAmount(item)"
        >
          <td style="text-align: start" class="red">
            {{ item.unitPrice.toLocaleString() }}
          </td>
          <td style="text-align: center">{{ item.volume }}</td>
          <td style="text-align: end">
            {{ item.wholePrice.toLocaleString() }}
          </td>
        </tr>
      </table>
      <tr v-if="depths" class="tr-body middle">
        <td class="negative">{{ depth[0].price.toLocaleString() }}</td>
        <td>
          <img
            src="../../../assets/Public/fall.png"
            alt=""
            width="21"
            height="21"
          />
        </td>
      </tr>
      <table v-if="depths">
        <tr
          class="tr-body"
          :class="{ disabled: item.myOrder }"
          v-for="(item, index) in tableContentsBuy"
          :key="index"
          :style="{
            background:
              'linear-gradient(to right,rgba(51, 158, 0, .3) ' +
              Math.floor(item.executedPercent) +
              '%, 0%)',
          }"
        >
          <td style="text-align: start" class="green">
            {{ item.unitPrice.toLocaleString() }}
          </td>
          <td style="text-align: center">{{ item.volume }}</td>
          <td style="text-align: end">
            {{ item.wholePrice.toLocaleString() }}
          </td>
        </tr>
      </table>

      <table v-if="lastt">
        <tr class="tr-head">
          <td>{{ state.lan.trade.depth.price }}</td>
          <td>{{ state.lan.trade.depth.amount }}</td>
          <td>{{ state.lan.trade.depth.date }}</td>
        </tr>
        <tr
          class="tr-body"
          v-for="(trade, index) in changeTrades"
          :key="index"
          :class="trade.orderType.includes('BUY') ? 'green' : 'red'"
        >
          <td>
            {{ $toLocal(trade.unitPrice, $decimal[$route.params.tradeTo]) }}
          </td>
          <td>
            {{ $toLocal(trade.amount, $decimal[$route.params.tradeFrom]) }}
          </td>
          <td>{{ $G2J(trade.createdAtDateTimed, "time", "en") }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { Loop } from "@/library/reuseableFunction";

export default {
  name: "Depth",
  data() {
    return {
      depths: true,
      lastt: false,
      loop: undefined,
      average: 1258473000,
      tableContentsSell: [],
      tableContentsBuy: [],
      LastTradesToman: [],
      LastTradesTether: [],
      depth: [
        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },
        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },

        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },
        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },
        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },
        {
          price: 1260039260,
          volume: 0.836,
          total: 60823235,
        },
      ],

      last: [
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/2",
          type: "sell",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },

        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/2",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/3",
          type: "sell",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },

        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/2",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/3",
          type: "sell",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },

        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/2",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/1",
          type: "buy",
        },
        {
          price: 1260039260,
          volume: 0.836,
          date: "2022/11/3",
          type: "sell",
        },
      ],
    };
  },
  methods: {
    getAmount(item){

    },
    async getLastTradesToman() {
      const res = await this.$axios.get("/trades/last-trades", {
        params: {
          marketType: this.$route.params.tradeFrom + "_" + "TOMAN",
          size: 20,
          type: "P2P",
        },
      });
      this.LastTradesToman = res.content;
      this.loading = false;
    },
    async getLastTradesTether() {
      const res = await this.$axios.get("/trades/last-trades", {
        params: {
          marketType: this.$route.params.tradeFrom + "_" + "TETHER",
          size: 20,
          type: "P2P",
        },
      });
      this.LastTradesTether = res.content;
      this.loading = false;
    },
    sort(data) {
      data.sort((a, b) => a - b);
    },
    async getMarketDepth() {
      const [res] = await this.$http.get("/orders/order-book", {
        params: {
          marketType:
            this.$route.params.tradeFrom + "_" + this.$route.params.tradeTo,
        },
      });
      if (res) {
        this.tableContentsSell = res.sellOrderBook;
        this.tableContentsBuy = res.buyOrderBook;
        this.state.shopInfo.minSell = res.sellOrderBook.length
          ? res.sellOrderBook[0].unitPrice
          : 0;
        this.state.shopInfo.maxBuy = res.buyOrderBook.length
          ? res.buyOrderBook[0].unitPrice
          : 0;
      }
      this.loading = false;
    },
  },
  watch: {
    $route() {
      this.getLastTradesToman();
      this.getLastTradesTether();
      this.getMarketDepth();
    },
  },
  computed: {
    changeTrades() {
      let allTrades;
      if (this.$route.params.tradeTo == "TETHER") {
        allTrades = this.LastTradesTether;
      } else {
        allTrades = this.LastTradesToman;
      }
      return allTrades;
    },
  },
  mounted() {
    this.loading = true;
    if (this.state.loop) {
      this.loop = new Loop(
        this.getMarketDepth,
        this.state.time,
        "marketDepth:" + this.tradeFrom + "-" + this.tradeTo
      );
      this.loop.start();
    } else {
      this.getMarketDepth();
    }

    this.getLastTradesToman();
    this.getLastTradesTether();
  },
  beforeDestroy() {
    this.loop.stop();
  },
};
</script>

<style lang="scss" scoped>
.middle {
  padding: 8px 16px;
  align-items: center;
  font-size: clamp(12px, 1vw, 16px) !important;
  background: #1c1a34;
  td:last-child {
    display: flex;
    align-items: center;
  }
}
table {
  width: 100%;
  max-width: 286px;
  table-layout: fixed;
  border-collapse: collapse;
  td {
    text-align: center;
    width: 33%;
  }
}
.tr-head,
.tr-body {
  font-size: clamp(9px, 1vw, 11px);
  width: 100%;
  display: flex;
  justify-content: space-between;

  height: 30px;
}
.tr-head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
.table-container {
  padding: 8px 12px;
  background: #191730;
  border-radius: 12px;
  //min-width: 431px;
}
.depth {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-height: 468px;
}
//buttons that changes the tables
.buttons {
  display: flex;
  gap: 15px;
  button {
    min-width: 44px;
    padding: 0 12px;
    height: 30px;
  }
}

//activation of buttons
.activate {
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active {
  border-radius: 20px;
  border: 1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color: rgba(61, 61, 61, 0.98);
}
</style>