var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"trw"},[_c('vue-trading-view',{staticStyle:{"border-radius":"12px"},attrs:{"options":{
              symbol: 'BINANCE:' + 'BTC' + 'USDT',
              height:'100%',
              autosize:true,
             theme:'dark',
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:false,
                      hide_bottom_toolbar:true,
                      // hide_legend:true,
                      locale: 'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border' ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }