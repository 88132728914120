<template>
  <div class="input-password">
    <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      class="password-input"
    />
    <button class="show-pass" @click.prevent="showingPass">
      <slot></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
    },
    type: {
      default: "password",
    },

    svg: String,
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
  },
};
</script>

<style lang="scss">
.input-password {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .show-pass {
    height: 36px;

    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #279bc0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: 0;
  }
  .password-input {
    padding: 0 15px;
    color: white;
    width: 100%;
    min-height: 36px;
    border: 0.5px solid #279bc0;
    border-radius: 4px;
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent;
  }
}
</style>
