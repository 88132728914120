<template >

<div class="" :key="state.lang + 'newpass'">

  <div class="newpass"v-if="isPasswordCorrect==true" >
    <div class="title" >
      <h4>{{ state.lang!='fa'?'Confirm Code': 'تایید کد' }}</h4>
      <p>{{ state.lang!='fa'?'Please Enter OTP here':'لطفا کد ارسال شده را وارد کنید' }}</p>
      <div class="input" v-if="isPasswordCorrect==true">
        <CodeInput       @change="onChange"
                         @complete="submit"
                         :fields="num"
                         ref="code-input" class="input" />
        <!--                 v-on:change="onChange"-->
        <!--                 v-on:complete="onComplete"-->

        <div class="timer">
          <div class="timer" v-if="finished">
            <a class="timer">0:00</a>
          </div>
          <div class="timer" v-else>
            {{ display }}
          </div>
        </div>
        <img src="../../assets/Authentication/recap.png" alt="" width="267">
      </div></div>
    <button v-if="finished==false" @click.prevent="submit" >
      {{ state.lan.otp.submit }}
    </button>
    <button v-if="finished" @click.prevent="resetCode">
      {{state.lang==='fa' ?'ارسال مجدد':'Resend Code'}}
    </button>
  </div>






  <div class="newpass" v-if="isPasswordCorrect==false">
    <div class="title" >
      <h4>{{ newpass.title }}</h4>
      <p>{{ newpass.text }}</p>

    </div >
    <!--password Fields-->
    <div class="pass">
      <label for="pass">{{ newpass.passlabel }}</label>
      <div class="pass-container">
        <label>
          <inline-svg :src="require('../../assets/Authentication/pass.svg')"/>
        </label>
        <input id="pass" :placeholder="newpass.passplace" :type=type v-model="otp.password" />
        <a href="" @click.prevent="showingPass">
          <inline-svg :src="require('../../assets/Authentication/show.svg')"/>
        </a>
      </div>

    </div>
    <div class="pass">
      <label for="pass">{{newpass.repeatlabel}}</label>
      <div class="pass-container">
        <label>
          <inline-svg :src="require('../../assets/Authentication/pass.svg')"/>
        </label>
        <input id="pass2" :placeholder="newpass.repeatplace" :type=repeateType  v-model="repeat"/>
        <a href="" @click.prevent="showingRepeat">
          <inline-svg :src="require('../../assets/Authentication/show.svg')"/>
        </a>
      </div>

    </div>
    <!--recaptcha will be replaced here -->



    <!--button-->
    <button  @click.prevent="checkPassword" >
      {{state.lang!=='fa'?"Confirm password" :"تایید گذرواژه"}}
    </button>

  </div>



</div>


</template>

<script>
import CodeInput from "vue-verification-code-input";
import { DateTime, Duration } from "luxon";
export default {
  components: {
    CodeInput,
  },
  name: "Newpass",
  data(){
    return{
      newpass:this.state.lan.newpass,
      type:'password',
      repeateType:'password',
      isPasswordCorrect:false,
      now: DateTime.local().set({ milliseconds: 0 }),
      end: DateTime.local().set({ milliseconds: 0 }).plus({ seconds: 5 }),
      tick: null,
      num: 6,
      passError: "",
      otp: {
        code: "",
        email: this.state.userInfo.email || this.$cookies.get("username") || "",
        mobile: this.state.userInfo.mobile || this.$cookies.get("mobile") || "",
        password: "",
      },
      repeat: "",
    }
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    async submit() {
      // await this.checkPassword();

      if (!this.passError) {
        const res = await this.$axios.post("/users/reset-password/", this.otp);
        if (res) {
          this.$error("رمز عبور با موفقیت تغییر پیدا کرد", "", "success");
          await this.$router.push("/login");
        }
      }
    },

    onChange(e) {
      this.otp.code = e;
    },
    showingRepeat(){
      if(this.repeateType === "password"){
        this.repeateType = "text"
      } else if(this.repeateType === "text"){
        this.repeateType = "password"
      }
    },

    countDownTimer() {
      if (!this.finished) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
      }
    },
    async resetCode() {
      this.state.loading = true
      await this.$axios.post(`/users/resend-otp-code?email=${this.$cookies.get('username')}`)
      this.$error('کد جدید  ' +'  ارسال شد.', '', 'success')
      this.$cookies.set('username', this.state.userInfo.email)
      //codes that will reset our code
      if (this.now >= this.end) {
        this.now = DateTime.local().set({ milliseconds: 0 });
        this.end = DateTime.local()
            .set({ milliseconds: 0 })
            .plus({ seconds: 120 });
        this.tick = setInterval(() => {
          this.now = DateTime.local().set({ milliseconds: 0 });
        }, 1000);
      }
    },
    async checkPassword() {
      if (!this.repeat || !this.otp.password) {
        this.passError = "رمز اجباری می باشد";
        this.$error("", this.passError);
      } else if (this.repeat !== this.otp.password) {
        this.passError = "رمز های عبور باید یکی باشند";
        this.$error("خطای تکرار", this.passError);
      } else {
        if (this.otp.password.length < 8) {
          this.passError = "رمز حداقل باید 8 کارکتر باشد";
          this.$error("", this.passError);
        } else if (!this.$validPass(this.otp.password)) {
          this.passError = "رمز باید ترکیبی از حرف بزرگ و کوچک باشد";
          this.$error("", this.passError);
        } else {
          this.isPasswordCorrect =true;
          this.countDownTimer()
          this.now = DateTime.local().set({milliseconds: 0});
          this.end = DateTime.local()
              .set({milliseconds: 0})
              .plus({seconds: 5});

          this.tick = setInterval(() => {
            this.now = DateTime.local().set({milliseconds: 0});
          }, 1000);
          return (this.passError = "");
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
//new password container
.input {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.newpass{
  width: 100%;
  max-width: 390px;
  min-height: 397px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 36px 24px;
  row-gap: 40px;
}

//title
.title {
  display: flex;
  flex-direction: column;
  gap: 24px !important;

  p {
    color: var(--gray);
    font-size: clamp(10px, 1vw, 13px);
  }


}





 .pass {


  display: flex;

  flex-direction: column;
  row-gap: 6px;
}

//password container
.pass-container{
  gap: 5px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(179, 176, 176, 0.5);
  border-radius: 4px;
  background: #131330;
  align-items: center;
  height: 36px;
  padding: 0 9px;
  label{
    display: flex;
  }
  input{
    width: 100%;
  }
  a{
    display: flex;
    align-items: center;
  }
}


//recap

img{
  align-self: center;
}


//submit btn
button{

  background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
  border-radius: 12px;
  height: 40px;

}

@media(max-width: 500px){
  .newpass{
    width: 90%;
  }

.pass-container{
>label{
>svg{
  width: 12px;
}
}
}
}
</style>