<template>
  <div class="content">
    <div class="kyc">
      <div class="uinfo">
        <Userinfo :data.sync="userInfo" />
      </div>
      <div class="cinfo">
        <Contactinfo :data.sync="userInfo" />
      </div>
      <div class="">
        <Bankinfo
          :key="change"
          :data.sync="userInfo"
          :error="duplicateBank"
        />
      </div>
      <div class="">
        <Idupload :data.sync="userInfo" />
      </div>
      <button @click="submit" :disabled="disabled">
        {{ state.lan.kyc.submit }}+
      </button>
    </div>
  </div>
</template>

<script>
import Userinfo from "@/components/Authorization/Userinfo.vue";
import Contactinfo from "@/components/Authorization/Contactinfo";
import Bankinfo from "@/components/Authorization/Bankinfo";
import Idupload from "@/components/Authorization/Idupload";
export default {
  name: "kyc",
  components: { Idupload, Bankinfo, Contactinfo, Userinfo },
  data() {
    return {
      bankChange: "noop",
      profile: true,
      userInfo: {
        authenticationType: "",
        firstName: "",
        lastName: "",
        email: "",
        nationalCode: "",
        birthDay: "",
        mobileNumber: "",
        telephone: "",
        address: "",
        bankAccounts: [
          {
            shebaCode: "",
            cardNumber: "",
            bankName: "",
          },
        ],
        image: "",
      },
      duplicateBank: "",
      change: 0,
    };
  },
  computed: {
    disabled() {
      for (let key in this.userInfo) {
        if (
          [
            "firstName",
            "lastName",
            "email",
            "nationalCode",
            "birthDay",
            "mobileNumber",
            "telephone",
            "bankAccounts",
            "image",
          ].includes(key)
        ) {
          if (key === "bankAccounts") {
            if (this.userInfo.bankAccounts.length) {
              for (let data of this.userInfo.bankAccounts) {
                if (
                  !data.shebaCode ||
                  !data.cardNumber ||
                  data.cardNumber.replace(/-/g, "").length < 16
                ) {
                  return "اطلاعات بانکی اشتباه است";
                }
              }
            } else {
              return "حداقل یک حساب بانکی اضافه نمایید";
            }
          } else if (key === "nationalCode") {
            if (this.userInfo[key] < 10) {
              return "کدملی اشتباه است";
            }
          } 
          // else if (!this.userInfo[key]) {
          //   return inputLabel[key] + " اجباریست";
          // }
        }
      }
      return false;
    },
  },
  methods: {
    async check() {
      const res = await this.$axios("/users/account");
      this.userInfo = res.baseDTO;
      this.change++;
    },
    bankCheck(items) {
      for (let item of items) {
        if (!item.cardNumber || !item.shebaCode) {
          this.$error(
            "اطلاعات ناقص",
            "شماره شبا یا کارت بانکی را وارد نکرده اید"
          );
          return false;
        } else {
          return true;
        }
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, {
        type: mime,
      });
    },
    async submit() {
      if (this.state.loading) return;
      this.state.loading = true;
      let form_data = new FormData();

      for (let key in this.userInfo) {
        if (key === "image") {
          if (typeof this.userInfo.image !== "string") {
            form_data.append("file", this.userInfo.image);
          }
          // else {
          //   form_data.append("image", this.dataURLtoFile(this.userInfo.image, "image"));
          // }

          // form_data.append("file", this.userInfo.image);
          // form_data.append("image", this.userInfo.image);
        }

        form_data.append(key, this.userInfo[key]);
      }
      form_data.append(
        "bankAccountsString",
        JSON.stringify(this.userInfo.bankAccounts)
      );

      if (this.bankCheck(this.userInfo.bankAccounts)) {
        try {
          let vm = this;
          const config = {
            onUploadProgress: function (progressEvent) {
              vm.state.progress = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            },
          };
            //  alert('hi')
          const res = await this.$axios.post(
            "/users/account",
            form_data,
            config
          );


          this.state.userInfo.authenticationType =
            res.baseDTO.authenticationType;
          this.state.userInfo.email = res.baseDTO.email;
          this.state.userInfo.authorized =
            res.baseDTO.authenticationType === "AUTHORIZED";
          this.state.userInfo.pending =
            res.baseDTO.authenticationType === "PENDING";
          await this.state.lang==='fa' ?this.$error("", "اطلاعات با موفقیت ذخیره شد", "success"): this.$error("", "information submitted", "success")
    //  alert('hi')
          if (res.message.includes("need to be verified")) {
            this.state.loading = true;
            await this.$axios(
              `/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`
            );
            this.$router.push("/fino");
          } else {
            this.$getuserInfo();
          }
        } catch (e) {
          if (
            e.response &&
            e.response.data.message.includes(
              "ShebaCode or CardNumber Is For Another User"
            )
          ) {
            this.$error("", " شماره شبا یا کارت قبلا وارد شده است.");
            this.duplicateBank = e.response.data.message;
          }
        }
      }
      this.state.loading = false;
    },
  },
  created() {
    this.check();
  },
  mounted() {
    // console.log(this.userInfo)
  },
};
</script>

<style lang="scss" scoped>
.kyc {
  padding-top: 32px;
  padding-bottom: 10%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 600px) {
  .content {
    padding: 20px;
    padding-bottom: 10%;
  }
}
button {
  width: 239px;
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
  border-radius: 12px;
  height: 40px;
}
button:disabled {
  opacity: 0.6;
}
</style>
