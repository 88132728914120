<template>
  <div class="settings">
    <div class="verifications">
      <div class="titr">

        <h5>{{ state.lan.settings.veriftitle }}</h5>
        <p>{{ state.lan.settings.veriftext }}</p>


      </div>


      <div class="modals">

        <div class="col-one">

        <form class="radio" @change="showModal(options.findIndex(e => e.model === twoStep))">
          <div class="radios" v-for="(options , index) in options" :key="index + state.lang">
            <input :id="'radio-'+index" type="radio"  v-model="twoStep" :value="options.model"/>
            <label :for="'radio-'+index" class="radio-label">
              {{ options.title }}
            </label>
          </div>
        </form>


          <!-- <div class="radio">
            <div class="radios">
              <input id="radio-3" name="radio" type="radio" @change="SMS = true"/>
              <label for="radio-3" class="radio-label">{{ state.lan.settings.sms }}</label>
            </div>
          </div>

          <div class="radio">
            <div class="radios">
              <input id="radio-2" name="radio" type="radio" @change="Email = true"/>
              <label for="radio-2" class="radio-label">{{ state.lan.settings.email }}</label>
            </div>

          </div>


          <div class="radio">
            <div class="radios">
              <input id="radio-1" name="radio" type="radio" @change="Google = true"/>
              <label for="radio-1" class="radio-label">Google authenticator</label>
            </div>

          </div> -->

        </div>

        <div class="col-two">
          <p>{{ state.lan.settings.smstext }}</p>
          <p>{{ state.lan.settings.emailtext }}</p>
          <p>{{ state.lan.settings.googletext }}</p>
        </div>

      </div>

    </div>
    <form class="change-password" @submit.prevent="submit">
      <h1>{{ state.lan.settings.changepass }}</h1>
      <div class="inputs">
        <div class="pass-container">
          <label>
            <inline-svg :src="require('../../../assets/Authentication/pass.svg')"/>
          </label>
          <input
              :type="password0 ? 'password' : 'text'"
              :placeholder="state.lan.settings.pervPass"
              @input="passError = ''"
              v-model="oldpassword"
          />
          <a href="" @click.prevent="password0 = !password0">
            <inline-svg :src="require('../../../assets/Authentication/show.svg')"/>
          </a>
        </div>
        <div class="pass-container">
          <label>
            <inline-svg :src="require('../../../assets/Authentication/pass.svg')"/>
          </label>

          <input
              :type="password1 ? 'password' : 'text'"
              :placeholder="state.lan.settings.newPass"
              @input="pass1Error = ''"
              v-model="newpassword1"
          />


          <a href="" @click.prevent="password1 != password1">
            <inline-svg :src="require('../../../assets/Authentication/show.svg')"/>
          </a>
        </div>
        <div class="pass-container">
          <label>
            <inline-svg :src="require('../../../assets/Authentication/pass.svg')"/>
          </label>
          <input
              :type="password2 ? 'password' : 'text'"
              :placeholder="state.lan.settings.repeatePass"
              @input="pass2Error = ''"
              v-model="newpassword2"
          />
          <a href="" @click.prevent="password2 =! password2">
            <inline-svg :src="require('../../../assets/Authentication/show.svg')"/>
          </a>
        </div>
      </div>
      <button class="submit" :disabled="!oldpassword || !newpassword1 || !newpassword2">{{
          state.lan.settings.submit
        }}
      </button>
    </form>
    <LoginHistory/>
    <SMSModal v-if="options[1].modal" @closeModal='closeP' @submit="submitted($event)" :mobile="mobile"
              :two-step="modal"/>
    <EmailModal v-if="options[0].modal" @closeModal='closeE' @submit="submitted($event)" :qr-link="qrLink"
                :two-step="modal"/>
    <google-modal v-if="options[2].modal" @closeModal='closeG' @submit="submitted($event)" :qr-code="qrCode"
                  :two-step="modal"/>
  </div>

</template>

<script>
import InputPassword from "../../Tools/InputPassword.vue";
import EmailModal from "./EmailModal.vue";
import GoogleModal from "./GoogleModal.vue";
import SMSModal from "./SMSModal.vue";
import LoginHistory from "@/components/Profile/Settings/LoginHistory";

export default {
  components: {LoginHistory, InputPassword, SMSModal, EmailModal, GoogleModal},
  data() {
    return {
      oldpassword: "",
      newpassword1: "",
      newpassword2: "",
      passError: "",
      pass1Error: "",
      pass2Error: "",
      password0: true,
      password1: true,
      password2: true,
      SMS: false,
      Email: false,
      Google: false,
      type: 'password',
            twoStep: this.state.towStepAuthenticationType,
      qrCode: '',
      qrLink: '',
      mobile: '',
      completedModal: false,
      completedText: '',
      modal: '',
      options: [{
        title: "Email",
        modal: false,
        model: 'EMAIL'
      },
        {
          title: "SMS",
          modal: false,
          model: 'SMS'
        },
        {
          title: "Google authenticator",
          modal: false,
          model: 'GOOGLE_AUTHENTICATOR'
        }
      ]
    };
  },
  methods: {
    async submit() {
let fa = this.state.lang=='fa'
      let passSent = {
        oldPassword: this.oldpassword,
        password: this.newpassword1,
      };
      await this.check2Pass();
      if (this.newpassword1 && this.newpassword2) {
        if (this.checkPass()) this.state.loading = true;
        try {
          const res = await this.$axios.post(
              `/users/change-password`,
              passSent
          );
          if (res.message === "password changed successfully") {
            this.state.lang === 'fa' ? this.$error("رمز شما تغییر یافت", "", "success") : this.$error("password changed", "", "success");
            this.oldpassword = "";
            this.newpassword1 = "";
            this.newpassword2 = "";
          }
        } catch (error) {
          if (error.response.data.message === "Old Password Is Incorrect") {
            fa?this.passError = "رمز قبلی اشتباه است.": this.passError = "Old Password Is Incorrect";
          }
        }
      }
    },
    checkPass() {
      let fa = this.state.lang=='fa'
      if (!this.oldpassword) {
        this.passError = "رمز فعلی اجباری";
      } else {
        this.passError = "";
      }
      if (this.newpassword1) {
        if (this.newpassword1.length < 8) {
          fa ?this.pass1Error = "حداقل 8 کاراکتر": this.pass1Error = "Minimum 8 characters";
        } else if (!this.validPass(this.newpassword1)) {
         fa? this.pass1Error = "ترکیبی از حروف کوچک و بزرگ و عدد": this.pass1Error = "Combination of small and capital letters and numbers";
        } else if (!this.newpassword2) {
          this.pass1Error = "";
         fa? this.pass2Error = "تکرار رمز اجباری": this.pass2Error = "Repeat password is required";
        } else if (this.newpassword2 !== this.newpassword1) {
          this.pass1Error = "";
          fa? this.pass2Error = "تکرار رمز مطابقت ندارد": this.pass2Error = "Passwords do not match";
        } else {
          this.pass2Error = "";
        }
      }
      if (
          !this.passError.length &&
          !this.pass1Error.length &&
          !this.pass2Error.length
      ) {
        return true;
      }
    },
    async check2Pass() {
      let fa = this.state.lang=='fa'
      if (!this.$validPass(this.oldpassword) || this.oldpassword.length < 8) {
     fa?   this.$error(
            "اطلاعات وارد شده اشتباه است",
            "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد"
        ): this.$error(
            "Information is incorrect",
            "Password must be at least 8 characters long and contain capital letters, small letters and numbers");
        return Promise.reject("wrong pass : " + this.oldpassword);
      }
      if (this.newpassword1 !== this.newpassword2) {
      fa?  this.$error("اطلاعات وارد شده اشتباه است", "تکرار رمز اشتباه است"): this.$error("Information is incorrect", "Repeat password is incorrect");
        return Promise.reject(
            "repeat failed : " + this.newpassword1 + "!==" + this.newpassword2
        );
      }
    },
    validPass(password) {
      const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
      return re.test(password);
    },    closeG(value) {
      this.options[2].modal = value
    },
    closeP(value) {
      this.options[1].modal = value
    },
    closeE(value) {
      this.options[0].modal = value
    },
    async getTwoStepAuth() {
      this.twoStep = this.state.userInfo.towStepAuthenticationType

    },
    async showModal(index) {
      this.state.loading = true
      const res = await this.$axios.post(`/users/send-verification-code?twoStepAuthorization=${this.twoStep}`).catch(() => {
        this.getTwoStepAuth()
      })
      if (res) {
        this.mobile = res.baseDTO.starTwoStepAuthentication || ''
        this.qrCode = res.baseDTO.file || ''
        this.qrLink = res.baseDTO.qrCodeLink || ''
        this.options[index].modal = true
      }
      this.getTwoStepAuth()
    },
    async submitted(e) {
      this.completedText = e;
      await this.$error('', e, 'success')
      await this.$getuserInfo()
      this.getTwoStepAuth()
    },
    
  },
  watch:{

  },
  mounted(){
        // this.getTwoStepAuth()
  }
};
</script>

<style lang="scss" scoped>


.modals {
  gap: 38px;
  display: flex;
  justify-content: space-between;
  max-width: 600px;

  .col-one, .col-two {
    display: flex;
    flex-direction: column;
    gap: 38px;
    justify-content: center;
  }
}



   form {
      display: flex;
      flex-direction: column;
      gap: 32px;
    }




















// .radio {
//   font-size: clamp(12px, 1vw, 16px);
// }

// .radio-input {
//   font-size: clamp(12px, 1vw, 16px);
// }


// .radio-label {
//   display: flex;
//   width: 100%;
//   gap: clamp(10px, 2vw, 40px);

// }

// .radio {
//   display: flex;
//   justify-content: space-between;
//   max-width: 400px;


// }


// .radios {
//   display: flex;
//   gap: 40px !important;

//   label {
//     font-size: clamp(12px, 1vw, 16px);
//   }

// }


.pass-container {
  a {
    display: flex;
    align-items: center;
  }
}


.pass-container {
  gap: 5px;
  display: flex;
  border: 1px solid rgba(179, 176, 176, 0.5);
  border-radius: 4px;
  background: #131330;
  align-items: center;
  height: 36px;
  padding: 0 9px;

  label {
    display: flex;
  }
}


.settings {
  > h1 {
    color: #febc00;
    font-weight: 500;
    font-size: clamp(14px, 1vw, 24px);
  }

  display: flex;
  flex-direction: column;
  gap: 24px;

  .verifications {
    $color1: #CACACE;
    $color2: #279bc0;

    .radio {
      label {

        font-size: clamp(12px, 1vw, 16px);
      }

      input[type="radio"] {
        position: absolute;
        opacity: 0;

        + .radio-label {
          font-size: clamp(12px, 2vw, 16px);

          &:before {
            align-self: center;
            margin-top: 4px;
            margin-left: 4px;
            content: "";
            background: $color1;
            border-radius: 100%;
            border: 1px solid darken($color1, 25%);
            display: inline-block;
            width: clamp(10px, 2vw, 20px);
            height: clamp(10px, 2vw, 20px);
            position: relative;
            top: -0.2em;

            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }

        &:checked {
          + .radio-label {
            &:before {
              background-color: $color2;
              box-shadow: inset 0 0 0 5.5px $color1;
            }
          }
        }

        &:focus {
          + .radio-label {
            &:before {
              outline: none;
              border-color: $color2;
            }
          }
        }

        &:disabled {
          + .radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px $color1;
              border-color: darken($color1, 25%);
              background: darken($color1, 25%);
            }
          }
        }

        + .radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }

    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border-radius: 16px;
    gap: 40px;

    .titr {
      display: flex;
      flex-direction: column;
      gap: 16px;

      > h5 {
        //color: #febc00;
        font-weight: 500;
        font-size: clamp(20px, 1vw, 24px)
      }

      p {
        color: var(--gray);
      }
    }

    padding: 20px;
    display: flex;
    flex-direction: column;
    background: #191730;
  }

  .change-password {

    width: 100%;
    background: #191730;
    border-radius: 12px;


    > h1 {
      font-weight: 500;
      font-size: clamp(16px, 2vw, 20px);
    }

    gap: 46px;
    padding: 20px;
    min-height: 243px;
    display: flex;
    flex-direction: column;

    .inputs {
      gap: 16px;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
    }

    .submit {
      align-self: flex-start;
      width: 168px;
      height: 32px;
      background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
      border-radius: 12px;

      color: white;
    }
  }
}

@media screen and (max-width: 768px) {
  //.settings {
  //  padding: 2%;
  //}

  .modals {
    .col-two {
      display: none;
    }
  }
  .change-password {
    .pass-container {
      padding: 10px;
      gap: 0;

    }

    input::placeholder {
      font-size: 10px;
    }

    input {
      width: 90%;
    }

    .inputs {
      flex-direction: column;
      width: 100%;
    }
  }
}
</style>
