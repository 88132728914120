<template>
  <div class="views">
    <div class="action">
      <div class="main-title">
        <p class="title">{{ state.lan.wallet.diposit.toman.title }}</p>
        <div class="input-container">
          <input
            type="text"
            :placeholder="state.lan.wallet.diposit.toman.amountt"
            v-model="amount"
            @input="amount = $toLocal(amount , 0)"
          />
          <p>{{ state.lan.wallet.diposit.toman.toman }}</p>
        </div>
      </div>
      <div class="bank_info">
        <button class="choose_bank" @click.prevent="drop = !drop">
          {{ select }}
          <img
            src="../../../assets/Public/selector.svg"
            alt=""
            width="14"
            height="7"
          />
        </button>
        <button
          @click.prevent="$router.push({ name: 'Account' })"
          class="adding"
        >
          <img src="../../../assets/profile/addbank.svg" alt="" />
        </button>
        <div class="drop" v-if="drop">
          <p
            v-for="(card, index) in banks"
            @click.prevent="selectItem(card)"
            :key="index"
          >
            {{ card.cardNumber }}
          </p>
        </div>
      </div>
      <button class="submit" @click.prevent="deposit">
        {{ state.lan.wallet.diposit.toman.sub }}
        <img
          src="../../../assets/profile/flesh.svg"
          alt=""
          :class="{ mirror: state.lang !== 'fa' }"
        />
      </button>
    </div>
    <div class="guide">
      <p class="title">{{ state.lan.wallet.diposit.toman.guide }}</p>
      <div class="text">
        <p>{{ state.lan.wallet.diposit.toman.text }}</p>
        <p>{{ state.lan.wallet.diposit.toman.text2 }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mounted } from "vue-trend-chart";
export default {
  name: "TomanDepositAction",
  props: {
    banks: {},
  },
  data() {
    return {
      amount: "",
      cards: [
        {
          number: "5892 1011 5903 0788",
        },
        {
          number: "6219 8619 0006 2240",
        },
        {
          number: "6104 3377 6332 5421",
        },
      ],
      select: "",
      selectedCard :'',
      drop: false,
    };
  },
  methods: {},
  mounted() {
    this.select = this.state.lan.wallet.diposit.toman.card;
  },
  methods: {
    selectItem(card) {
      this.select = card.cardNumber;
      this.selectedCard = card.cardNumber
      console.log(this.selectedCard)
      // this.selectedid = this.coins[index].ida
      // this.logo = this.coins[index].image;
      // this.price=this.coins[index].price
      this.drop = false;
    },

    async deposit() {
      this.state.loading = true;
      let walletInfo = {
        amount: this.$S2N(this.amount),
        paymentGateway: "ZARRINPAL",
        cardNumber: this.selectedCard,
        callBackURL: this.$callBackURL,
      };

      const res = await this.$axios.post("/wallets/deposit-toman", walletInfo);

      if (res.message === "Deposit Request Created Successfully") {
        this.$emit("close");
        window.open(res.baseDTO.redirectSite, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mirror {
  transform: scale(-1, 1);
}

.drop {
  cursor: pointer;
  display: flex;
  gap: 10px;
  z-index: 5;
  flex-direction: column;
  background: #131330;
  border-radius: 12px;
  padding: 16px;
  width: 87%;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  top: 50px;
}
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
  border-radius: 12px;
  height: 40px;
  width: 40%;
  align-self: center;
}

.choose_bank {
  align-items: center;
  width: 87%;
  height: 44px;
  color: var(--gray);
  display: flex;
  justify-content: space-between;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 12px;
}
.bank_info {
  position: relative;
  flex-wrap: wrap;
  display: flex;
  gap: 18px;
  width: 100%;
}
.adding {
  //align-self: flex-end;
  width: 10%;
}
.views {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .action,
  .guide {
    display: flex;
    flex-direction: column;
    background: #191730;
    border-radius: 12px;
    min-height: 375px;
    padding: 24px;
  }
  .action {
    flex-grow: 2.7;
    flex-basis: 270px;
    display: flex;
    flex-direction: column;
    gap: 64px;
  }
  .guide {
    display: flex;
    width: 268px;
    flex-direction: column;
    gap: 32px;
    color: var(--gray);
    .title {
      font-size: clamp(16px, 1vw, 20vw);
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
.main-title {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .title {
    font-size: clamp(20px, 2vw, 24px);
    color: var(--gray);
  }
  .input-container {
    input {
      width: 110px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 44px;
    background: #131330;
    border: 0.6px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 12px;
    p {
      color: var(--gray);
    }
  }
}
@media (max-width: 500px) {
  .submit {
    width: 100%;
  }
  .choose_bank {
    width: 100%;
  }
  .adding {
    width: 100%;
    align-self: center;
  }
}
</style>