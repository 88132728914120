<template>
<div class="profile">
  <div :class="{'mobile-btn':state.lang==='fa','mobile-btn-rtl':state.lang==='en'} " @click="mobiles=!mobiles" v-if="!mobiles"><img src="../../assets/profile/arrow-left-circle.svg" alt=""></div>

  <div class="main-profile">
    <div class="sidebar">
      <Sidebar/>






    </div>
    <transition name="fade">
      <div :class="{'ltr':state.lang ==='en' , 'sidebar-mobile':state.lang === 'fa'}" v-if="mobiles">
        <div :class="{'close-btn':state.lang==='fa' , 'close-ltr':state.lang==='en' }" @click="mobiles=false"> < </div>
        <p class="title link-title">{{state.lan.side.title}}</p>
        <div class="list">
          <a
              href=""
              @click.prevent="$router.push('/profile')"
              :class="{
            active: $route.name === 'Dashboard' && state.lang === 'fa',
            'active-ltr': $route.name === 'Dashboard' && state.lang === 'en',
          }"
          >

            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"   v-if="$route.name !== 'Dashboard'"/>

            <img src="../../assets/profile/menu.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.dashboard }}</span
          ></a
          >
          <a
              href=""
              @click.prevent="$router.push('/profile/account')"
              :class="{
            active: $route.name === 'Account' && state.lang === 'fa',
            'active-ltr': $route.name === 'Account' && state.lang === 'en',
          }"
          >
            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"   v-if="$route.name !== 'Account'"/>

            <img src="../../assets/profile/profile.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.profile }}</span
          ></a
          >
          <a
              href=""
              @click.prevent="wallet = !wallet && $router.push('/profile/wallet')"
              :class="{
            'active':state.lang === 'fa' && ($route.name=='Wallet' || $route.name=='CryptoDiposit' || $route.name=='CryptoWithdrawl'),
            'active-ltr':state.lang ==='en' &&  ($route.name=='Wallet' || $route.name=='CryptoDiposit' || $route.name=='CryptoWithdrawl') ,

          }"
          ><inline-svg :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"   v-if="$route.name !== 'Wallet'" class="deactivate sepre"/>
            <img src="../../assets/profile/wallet.svg" alt="" /><span
                class="link-title"
            >{{ state.lan.side.wallet }}</span
            ></a
          >
          <div class="wallet-list" v-if="wallet">
            <a
                href=""
                @click.prevent="$router.push('/profile/wallet')"

            >
              <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"
                          :class="{ activate: $route.name === 'Wallet' }"
              />

              <span>{{ state.lan.side.inventory }}</span></a
            >
            <a
                href=""
                @click.prevent="$router.push('/profile/crypto-dipo')"

            >
              <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"
                          :class="{ activate: $route.name === 'CryptoDiposit'  }"
              />

              <span>{{ state.lan.side.diposit }}</span></a
            >
            <a
                href=""
                @click.prevent="$router.push('/profile/crypto-wit')"
                :class="{ active: $route.name === 'CryptoWithdrawl' && state.lang === 'fa' }"
            >
              <inline-svg :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px" class="deactivate"
                          :class="{ activate: $route.name === 'CryptoWithdrawl'  }"
              />
              <span>{{ state.lan.side.withdraw }}</span></a
            >
          </div>

          <a
              href=""
              @click.prevent="$router.push('/profile/history')"
              :class="{
            active: $route.name === 'History' && state.lang === 'fa',
            'active-ltr': $route.name === 'History' && state.lang === 'en',
          }"
          >

            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"   v-if="$route.name !== 'History'"/>

            <img src="../../assets/profile/history.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.history }}</span
          ></a
          >
          <a
              href=""
              @click.prevent="$router.push('/profile/invite')"
              :class="{
            active: $route.name === 'Invite' && state.lang === 'fa',
            'active-ltr': $route.name === 'Invite' && state.lang === 'en',
          }"
          >

            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"    v-if="$route.name !== 'Invite'"/>

            <img src="../../assets/profile/invite.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.invite }}</span
          ></a
          >
          <a
              href=""
              @click.prevent="$router.push('/profile/notif')"
              :class="{
            active: $route.name === 'Notif' && state.lang === 'fa',
            'active-ltr': $route.name === 'Notif' && state.lang === 'en',
          }"
          >

            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px"    v-if="$route.name !== 'Notif'"/>


            <img src="../../assets/profile/notif.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.notif }}</span
          ></a
          >
          <a
              href=""
              @click.prevent="$router.push('/profile/settings')"
              :class="{
            active: $route.name === 'Settings' && state.lang === 'fa',
            'active-ltr': $route.name === 'Settings' && state.lang === 'en',
          }"
          >

            <inline-svg class="deactivate" :src="require('../../assets/profile/seprator.svg')" width="5px" height="40px" v-if="$route.name !== 'Settings'"/>

            <img src="../../assets/profile/settings.svg" alt="" /><span
              class="link-title"
          >{{ state.lan.side.settings }}</span
          ></a
          >
        </div>
      </div>
    </transition>
    <div class="main-view">
<transition :enter-active-class="state.lang==='fa'? 'animate__animated animate__fadeInLeft':  'animate__animated animate__fadeInRight'">
  <router-view/>
</transition>
    </div>
  </div>
</div>
</template>

<script>
import Sidebar from "@/components/Profile/Sidebar";

export default {
  name: "Profile",
  data(){
    return{
      mobiles:false,
      wallet:false,
    }
  },
  components: {Sidebar}
,mounted() {
//  console.log(this.state.userInfo)
}
}

</script>

<style lang="scss" scoped>
.profile{
  padding-top: clamp(12px,3vw,34px);
}
.title{
  margin: 15px;
  font-size: 16px !important;
}
.ltr{
  overflow: hidden;
  left: 0;
  bottom: 0;
  top: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 8px 0;
  background: #2A2B43;
  border-radius: 12px;
  gap: 18px;
  z-index: 10;
.wallet-list{
  gap: 5px;
}

}
.close-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20px;
  font-size:20px;
  position: absolute;
  cursor: pointer;
  background: #1B1849;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.close-ltr{

  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  font-size:20px;
  position: absolute;
  cursor: pointer;
  background: #1B1849;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.sidebar-mobile{
  z-index: 10;
  overflow: hidden;
  right: 0;
  bottom: 0;
  top: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 8px 0;
  background: #2A2B43;
  border-radius: 12px;
  gap: 18px;
}


.mobile-btn , .mobile-btn-rtl{
  display: none;
}



//title of sidebar

.active {
  transition: all 0.7s ease;
  .sepre {
    display: none;
  }
  height: 40px;
  background: linear-gradient(
          90deg,
          #2850a8 0%,
          #1b3161 41.85%,
          rgba(32, 38, 52, 0.21) 100%
  );
  border-radius: 24px 0px 0px 24px;
  img {
    margin-right: 10px;
    min-width: clamp(18px, 2vw, 24px) !important;
    min-height: clamp(18px, 2vw, 24px) !important;
  }
}


.active-ltr {
  transition: all 0.7s ease-out;
  .sepre{
    display: none;
  }

  height: 40px;
  background: linear-gradient(
          -90deg,
          #2850a8 0%,
          #1b3161 41.85%,
          rgba(32, 38, 52, 0.21) 100%
  );
  border-radius:  0px 24px 24px 0px ;

  img {
    margin-left: 10px;
    min-width: clamp(18px, 2vw, 24px) !important;
    min-height: clamp(18px, 2vw, 24px) !important;
  }
}


.list{
  display: flex;
  flex-direction: column;
  a{
    align-items: center;
    gap: clamp(2px,0.8vw,19px);

    display: flex;
    img{
      width: clamp(18px,2vw,24px);
      height: clamp(18px,2vw,24px);
      align-self: center;
    }
    span{
      font-size: clamp(12px,1vw,16px);
    }
  }
  row-gap: 10px;
}


.wallet-list{
  display: flex;
  flex-direction: column;
  a{
    align-items: center;
    gap: clamp(2px,0.8vw,19px);
    display: flex;

  }
  span{
    font-size: clamp(10px,1.2vw,16px);
  }
  row-gap: 10px;
}


.profile{
  position: relative;
  //padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.main-profile {
  gap: 24px;
  width: 80%;
  //margin: 32px auto;
  //padding: 32px 160px;
  display: flex;
  justify-content: center;

}

.sidebar {
  width: 20%;
}

.main-view {
  width: 80%;
}

@media(max-width: 1154px){
  .main-view {
    width: 100%;
  }
.sidebar{
 display: none;
}


  .mobile-btn{
    z-index: 10;
    position: sticky;
    right: 5px;
    top: 50px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;

    //background: #2A2B43;

  }
  .mobile-btn-rtl{

    position: sticky;
    left: 0px;
    top: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    transform: scale(-1,1);

    //background: #2A2B43;

  }


}
</style>