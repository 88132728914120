<template>
  <div class="terms">
    <div class="title">
      <p>{{state.lan.terms.title}}</p>
    </div>

    <div class="term-container">
      <p>{{state.lan.terms.text}}</p>
      <p>{{state.lan.terms.text}}</p>
      <p>{{state.lan.terms.text}}</p>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import FooterComponent from "@/components/Public/Footer";
export default {
  name: "Terms",
  components: {FooterComponent}
}
</script>

<style lang="scss" scoped>
.terms{
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  gap: 29px;
  border-radius: 12px;
}
.title{
  position: relative;
  display: flex;
  padding: 60px;
  justify-content: center;
  //align-items: center;

  background: linear-gradient(115.97deg, #181A48 0%, #090A29 106.55%);
  min-height: 240px;

z-index: 2;

  p{
    font-size: clamp(20px,1vw,24px);
  }

}

.title::before{
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../../assets/Public/bubble.svg");

  position: absolute;
  //background-color: red;
  background-size: cover;
  z-index: -2;
}
.term-container{
  background: #191730;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: clamp(8px,1vw,16px);
  max-width: 1120px;
border-radius:12px ;
  margin: 0 auto;
  margin-bottom: clamp(128px,1vw,20px);
}
</style>