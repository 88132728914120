import { render, staticRenderFns } from "./Tomandipotable.vue?vue&type=template&id=b3d54ec2&scoped=true&"
import script from "./Tomandipotable.vue?vue&type=script&lang=js&"
export * from "./Tomandipotable.vue?vue&type=script&lang=js&"
import style0 from "./Tomandipotable.vue?vue&type=style&index=0&id=b3d54ec2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3d54ec2",
  null
  
)

export default component.exports