<template>
  <div v-if="finished" class="timer">
    <a @click.prevent="finished = !finished">ارسال مجدد</a>
  </div>
  <div v-else class="timer">
    {{ display }}
  </div>
</template>

<script>
import {DateTime, Duration} from "luxon";

export default {
  data() {
    return {
      now: DateTime.local().set({milliseconds: 0}),
      end: DateTime.local().set({milliseconds: 0}).plus({seconds: 120}),
      tick: null,
    };
  },
  watch: {
    now() {
      if (this.finished) {
        clearInterval(this.tick);
      }
    },
  },
  computed: {
    remaining() {
      return this.end.diff(this.now).toObject();
    },
    display() {
      return Duration.fromObject(this.remaining).toFormat("mm:ss");
    },
    finished() {
      return this.now >= this.end;
    },
  },
  mounted() {
    this.tick = setInterval(() => {
      this.now = DateTime.local().set({milliseconds: 0});
    }, 1000);
  },
};
</script>

<style>
.timer {

  width: 100%;
  color: var(--gray);
  font-size: 12px;
}
</style>
