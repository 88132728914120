<template>
<div class="cinfo">
  <div class="titles">
    <p class="title">{{state.lan.account.cititle}}</p>
    <a>{{state.lan.account.ciedit}}</a>
  </div>
  <div class="info">
    <div class="titles">
      <p>{{state.lan.account.cin}}:</p>
      <p>{{state.lan.account.ciemail}}:</p>
      <p>{{state.lan.account.cinum}}:</p>
      <p>{{state.lan.account.ciadd}}:</p>
    </div>
    <div class="values">
      <p>{{state.userInfo.mobileNumber}}</p>
      <p>{{state.userInfo.email}}</p>
      <p>{{state.userInfo.telephone}}</p>
      <p>{{state.userInfo.address}}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Contactuser",
  data(){
    return{
      name:'aylin',
      family:'imentalab',
      bd:'1387/9/14',
      id:1234543245,
      mail:'aylin.eimentalab@gmail.com',
      mob: '09123854741',
      fee:+0.4,
      lvl:'3rd',



    }

  }
}
</script>

<style lang="scss" scoped>
.cinfo{
  display: flex;
  flex-direction: column;
  //justify-content: center;
  padding: 13px 20px;
  min-height: 260px;
  background: #191730;
  border-radius: 12px;
  gap: 14.5px;
}
.titles{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title{
    font-size: clamp(16px,1.5vw,20px);
    font-weight: 700;
  }
  a{
    color: var(--light-blue);
    font-size: clamp(12px,1vw,16px);
    font-weight: 400;
  }
}
.info{
  max-width: 344px;
  display: flex;
  justify-content: space-between;
  p{
    font-size: clamp(10px,1vw,16px);
  }
  .titles ,.values{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .titles{
    display: flex;
    align-items: flex-start;
    color: var(--gray);

  }

}
</style>