<template>
  <div class="carosel-container">
    <carousel
      :rtl="state.lang == 'fa' ? true : false"
      :per-page="1"
      :autoplay="true"
      :loop="true"
      :speed="900"
      :per-page-custom="[
        [320, 1],
        [768, 3],
        [600, 2],
        [1000, 4],
      ]"
      :autoplay-timeout="5000"
    >
      <slide
        v-for="(coin, index) in cryptoList"
        :key="index"
        @click="goto($coin2Snake[coin.relatedCoin])"
        class="main-slide"
      >
        <div class="card">
          <div class="img-container" :id="index" :ref="index">
            <img
              :src="`/coins/${$coinUnit[coin.relatedCoin]}.png`"
              :alt="coin.relatedCoin"
              width="40"
              height="40"
            />
            <span v-if="state.lang === 'fa'">{{
              coin.marketType.includes("TOMAN") ? "تومان" : "تتر"
            }}</span>
            <span v-if="state.lang === 'en'">{{
              coin.marketType.includes("TOMAN") ? "TMN" : "USDT"
            }}</span>
          </div>
          <div class="changes">
            <p v-if="state.lang === 'fa'">
              {{ $coinLabel[coin.relatedCoin] }} /
              {{ coin.marketType.includes("TOMAN") ? "تومان" : "تتر" }}
            </p>
            <p v-if="state.lang === 'en'">
              {{ $coinUnit[coin.relatedCoin] }} /
              {{ coin.marketType.includes("TOMAN") ? "Toman" : "Tether" }}
            </p>
            <p>{{ $toLocal(coin.lastPrice, $decimal["TOMAN"]) }}</p>
            <p
              :class="{
                positive: coin.last24HourChange >= 0,
                negative: coin.last24HourChange < 0,
              }"
            >
              % {{ $toPrecision(coin.last24HourChange, 3) }}
            </p>
          </div>
        </div>

        <!-- <div class="slide">

                <div class="information">
                    <span class="crypto-name">
                        {{$coinLabel[coin.relatedCoin]}}
                    </span>
                    <span class="crypto-price">
                        {{$toLocal(coin.lastPrice,$decimal['TOMAN'])}}
                    </span>
                    <span class="crypto-change" :class="{'negetive-change':coin.last24HourChange < 0 ,'positive-change':coin.last24HourChange > 0 }">
                        <span v-if="coin.last24HourChange > 0">+</span> {{$toLocal(Math.abs(coin.last24HourChange),1)}} %
                    </span>
                </div>
                <div class="image">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" class="image-slide" alt="">
                </div>
            </div> -->
      </slide>
    </carousel>
  </div>
</template>

<script>
import * as Vibrant from "node-vibrant";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "slider",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      palette: [],
      colors: "",
      imageSrc: "",
      cryptoList: [],
      TomanCoin:
        "market_type=BITCOIN_TOMAN" +
        "&market_type=BITCOIN_CASH_TOMAN" +
        "&market_type=ETHEREUM_TOMAN" +
        "&market_type=LITE_COIN_TOMAN" +
        "&market_type=BINANCE_COIN_BSC_TOMAN" +
        "&market_type=STELLAR_TOMAN" +
        "&market_type=TRON_TOMAN" +
        "&market_type=CELO_TOMAN" +
        "&market_type=RIPPLE_TOMAN" +
        "&market_type=DOGE_COIN_TOMAN" +
        "&market_type=MATIC_TOMAN" +
        "&market_type=TETHER_TOMAN",
      TetherCoin:
        "market_type=BITCOIN_TETHER" +
        "&market_type=BITCOIN_CASH_TETHER" +
        "&market_type=ETHEREUM_TETHER" +
        "&market_type=CLASSIC_ETHEREUM_TETHER" +
        "&market_type=LITE_COIN_TETHER" +
        "&market_type=BINANCE_COIN_BSC_TETHER" +
        "&market_type=EOS_TETHER" +
        "&market_type=STELLAR_TETHER" +
        "&market_type=TRON_TETHER" +
        "&market_type=RIPPLE_TETHER" +
        "&market_type=DOGE_COIN_TETHER" +
        "&market_type=MATIC_TETHER" +
        "&market_type=CELO_TETHER" +
        "&market_type=DASH_TETHER",
    };
  },
  methods: {
    async setColor() {
      this.cryptoList.map((coin, index) => {
        coin.relatedCoin = this.$coin2Snake[coin.relatedCoin];

        Vibrant.from(
          require("@/assets/coins/" + coin.relatedCoin + ".png")
        ).getPalette((err, palette) => {
          // this.$ref(index).style.backgroundColor = "rgba("+palette.Vibrant.rgb+",1)"
          let coins = document.getElementById(index);

          //   console.log(coin.relatedCoin,palette.Vibrant)
          //   background:' linear-gradient(#e66465, #9198e5)'
          if (coin.relatedCoin == "LTC" && coin.marketType.includes("TOMAN")) {
            coins.style.background =
              "linear-gradient(rgb(100, 124, 236), rgb(22, 18, 219))";
          } else if (
            coin.relatedCoin == "LTC" &&
            coin.marketType.includes("TETHER")
          ) {
            coins.style.background =
              "linear-gradient(rgb(100, 124, 236), rgb(52, 176, 8))";
          }
          coins.style.background = `linear-gradient(${palette.Vibrant.hex} , ${
            coin.marketType.includes("TETHER") ? "#34B008" : "#1612DB"
          })`;
          //   coins.style.background="linear-gradient("+palette.Vibrant.hex+","+(coin.marketType.includes("TETHER"))?'#34B008':'#1612DB'+")"
          //    coins.style.background= palette.Vibrant.hex
        });
      });
    },
    async setData() {
      this.state.loading = true;
      const res = await this.$axios.get(
        "/trades/market-info?" + this.TomanCoin,
        {
          params: {
            weeklyDataIncluded: true,
          },
        }
      );
      this.state.lang == "en" ? (this.cryptoList = res.content) : res.content;
      if (this.cryptoList.length == res.content.length) {
        this.state.loading = false;
        // this.setColor()
      }
    },
    goto(e) {
      this.$router.push("/profile/trade/" + "TOMAN" + `/${e}`);
    },
  },
  watch: {
    // "state.lang": function () {
    //   this.setColor();
    //   this.setData();
    // },

    cryptoList() {
      if (this.cryptoList.length != 0) {
        this.setColor();
      }
    },
  },
  mounted() {
    this.setData();
  },
};
</script>

<style lang='scss' scoped>
.img-container {
  border-radius: 33px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 13px;
    font-weight: 300;
  }
}
.card {
  //  max-width: 282px !important;
  padding: 12px;
  background: #191730;
  border-radius: 12px;
  display: flex;
  gap: 12px;
  min-height: 127px;
}
.changes {
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    font-size: clamp(10px, 1vw, 13px);
  }
  p:nth-child(2) {
    font-size: clamp(12px, 1vw, 16px);
  }
}

.slide {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  /* width: 277px; */
  height: 116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 20px; */
}
.main-slide {
  padding: 0 10px;
}

@supports not (backdrop-filter: blur(10px)) {
  .slide {
    background: #252833 !important;
  }
}

.information,
.image {
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-slide {
  width: 70px;
  height: 70px;
}

.crypto-name {
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
}

.crypto-price {
  font-weight: normal;
  font-size: 18px;
  color: #80cc52;
}

.crypto-change {
  font-weight: normal;
  font-size: 14px;
  direction: ltr;
}

.negetive-change {
  color: rgb(247, 131, 131);
}

.positive-change {
  color: #80cc52;
}
</style>
<style>

.VueCarousel {
  direction: revert !important;
  width: 90%;
  /* max-width: 1400px; */
  margin: 0 auto;
}

.VueCarousel-pagination {
  height: 10px !important;
}

.VueCarousel-dot {
  display: none !important;
  cursor: pointer;
  appearance: none;
  border: none;
  background-clip: content-box;
  box-sizing: content-box;
  padding: 0;
  border-radius: 100%;
  outline: none;
  background-color: black !important;
  margin-top: 0 !important;
}

.VueCarousel-dot--active {
  background-color: gainsboro !important;
}

.VueCarousel-dot-container {
  margin-top: 10px !important;
  display: none 1 !important;
}


</style>