<template>
<div class="trw">
  <vue-trading-view
      style="border-radius: 12px"
      :options="{
              symbol: 'BINANCE:' + 'BTC' + 'USDT',
              height:'100%',
              autosize:true,
             theme:'dark',
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:false,
                      hide_bottom_toolbar:true,
                      // hide_legend:true,
                      locale: 'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }"
  />
</div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
export default {
  name: "Tradingv"
  ,components:{
    VueTradingView
  }
}
</script>

<style lang="scss" scoped>
.trw{
  border-radius: 12px;
  min-height:
      468px;
}
</style>