<template>
  <div class="main-container">
<div class="view">
  <CryptWitAction />
</div>
    <div class="table">
      <Crypwittable :key="$route.params.target" />
    </div>
  </div>
</template>

<script>
import Crypwittable from "@/components/Profile/Wallet/tables/Crypwittable.vue";
import CryptWitAction from "@/components/Profile/Wallet/CryptWitAction.vue";
export default {
  name: "CryptoWithdraw",
components:{
  CryptWitAction,
    Crypwittable
},
  data(){
    return{
      id:null
    }
  },
  methods:{


  },

  }

</script>

<style lang="scss" scoped>
//main container is here
.main-container{
  display: flex;
  flex-direction: column;
  gap:24px;
}
//views is the container that contains the guide and
.views{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .action,.guide{
    display: flex;
    flex-direction: column;
    background: #191730;
    border-radius: 12px;
    min-height: 375px;
    padding: 24px;
  }
  .action{
    flex-grow: 2.7;
    flex-basis: 270px;

  }
  .guide{
    width: 268px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: var(--gray);
    .title{
      font-size: clamp(16px,1vw,20vw);

    }
    .text{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
</style>