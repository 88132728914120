<template>
  <div class="coins-list">
    <div class="content">
      <p class="title">{{ state.lan.coinlist.title }}</p>
      <div class="carosel-container">
        <p>{{ state.lan.coinlist.lasttrade }}</p>
        <div class="carosel-container">
          <carousel
            :rtl="state.lang == 'fa' ? true : false"
            :per-page="4"
            :autoplay="true"
            :loop="true"
            :speed="900"
            :per-page-custom="[
              [320, 1],
              [768, 3],
              [600, 2],
              [1000, 5],
            ]"
            :autoplay-timeout="3000"
          >
            <slide
              v-for="(coin, index) in cryptoList"
              :key="index"
              @click="goto($coin2Snake[coin.relatedCoin])"
              class="main-slide"
            >
        <div class="card">
          <div class="img-container" :id="index" :ref="index">
            <img
              :src="`/coins/${$coinUnit[coin.relatedCoin]}.png`"
              :alt="coin.relatedCoin"
              width="40"
              height="40"
            />
            <span v-if="state.lang === 'fa'">{{
              coin.marketType.includes("TOMAN") ? "تومان" : "تتر"
            }}</span>
            <span v-if="state.lang === 'en'">{{
              coin.marketType.includes("TOMAN") ? "TMN" : "USDT"
            }}</span>
          </div>
          <div class="changes">
            <p v-if="state.lang === 'fa'">
              {{ $coinLabel[coin.relatedCoin] }} /
              {{ coin.marketType.includes("TOMAN") ? "تومان" : "تتر" }}
            </p>
            <p v-if="state.lang === 'en'">
              {{ $coinUnit[coin.relatedCoin] }} /
              {{ coin.marketType.includes("TOMAN") ? "Toman" : "Tether" }}
            </p>
            <p>{{ $toLocal(coin.lastPrice, $decimal["TOMAN"]) }}</p>
            <p
              :class="{
                positive: coin.last24HourChange >= 0,
                negative: coin.last24HourChange < 0,
              }"
            >
              % {{ $toPrecision(coin.last24HourChange, 3) }}
            </p>
          </div>
        </div>

              <!-- <div class="slide">

                <div class="information">
                    <span class="crypto-name">
                        {{$coinLabel[coin.relatedCoin]}}
                    </span>
                    <span class="crypto-price">
                        {{$toLocal(coin.lastPrice,$decimal['TOMAN'])}}
                    </span>
                    <span class="crypto-change" :class="{'negetive-change':coin.last24HourChange < 0 ,'positive-change':coin.last24HourChange > 0 }">
                        <span v-if="coin.last24HourChange > 0">+</span> {{$toLocal(Math.abs(coin.last24HourChange),1)}} %
                    </span>
                </div>
                <div class="image">
                    <img :src="'/coins/'+ $coin2Snake[coin.relatedCoin] + '.png'" class="image-slide" alt="">
                </div>
            </div> -->
            </slide>
          </carousel>
        </div>
        <p>{{ state.lan.coinlist.alltrades }}</p>
        <div class="table-container">
          <table>
            <tr class="tr-head">
              <td class="tablet">#</td>
              <td>{{ state.lan.marketv.coin }}</td>
              <td class="phone">{{ state.lan.marketv.change }}</td>
              <td >{{ state.lan.marketv.lp }}</td>
              <td class="tablet">{{ state.lan.marketv.mc }}</td>
              <td class="tablet">{{ state.lan.marketv.weekly }}</td>
              <td class="tablet chart">{{ state.lan.marketv.chart }}</td>
              <td>{{ state.lan.marketv.action }}</td>
            </tr>
            <tr
              class="tr-body hover"
              v-for="(coin, index) in tableContents"
              :key="index"
            >
              <td class="tablet">{{ index + 1 }}</td>
              <td @click.prevent="marketInfo($coin2Snake[coin.relatedCoin])">
                <div class="image-cont">
                  <span
                    ><img
                      :src="`/coins/${$coinUnit[coin.relatedCoin]}.png`"
                      :alt="coin.relatedCoin"
                      width="32"
                      height="32"
                  /></span>
                  <p class="phone" v-if="state.lang === 'fa'">
                    {{ $coinLabel[coin.relatedCoin] }}
                  </p>
                  <p class="phone" v-if="state.lang !== 'fa'">
                    {{ $coinUnit[coin.relatedCoin] }}
                  </p>
                </div>
              </td>
              <td
              class="phone"
                :class="{
                  positive: coin.last24HourChange >= 0,
                  negative: coin.last24HourChange < 0,
                }"
              >
                % {{ $toPrecision(coin.last24HourChange, 2) }}
              </td>
              <td >
                {{ $toLocal(coin.lastPrice, $decimal["TOMAN"]) }}
              </td>
              <td class="tablet">
                {{ $toLocal(coin.last24HourVolume, $decimal["TOMAN"]) }}
              </td>
              <td
                class="tablet"
                :class="{
                  positive: coin.last24HourChange >= 0,
                  negative: coin.last24HourChange < 0,
                }"
              >
                % {{ $toPrecision(coin.last24HourChange, 1) }}
              </td>
              <td class="tablet">
                <trend-chart :trend="coin.lastWeekData" class="trend" />
              </td>
              <td>
                <a
                  href="/trade"
                  @click.prevent="goto($coin2Snake[coin.relatedCoin])"
                  >{{ state.lan.marketv.trade }}</a
                >
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <footer-component />
  </div>
</template>

<script>
import * as Vibrant from "node-vibrant";
import trendChart from "@/components/Tools/TrendChart.vue";
import { Carousel, Slide } from "vue-carousel";
import crypto from "@/library/crypto";
import FooterComponent from "@/components/Public/Footer";

export default {
  name: "Coinslist",
  components: {
    FooterComponent,
    trendChart,
    Carousel,
    Slide,
  },
  data() {
    return {
      crypto: crypto,
      tableContents: [],
      palette: [],
      colors: "",
      imageSrc: "",
      cryptoList: [],
      TomanCoin:
        "market_type=BITCOIN_TOMAN" +
        "&market_type=BITCOIN_CASH_TOMAN" +
        "&market_type=ETHEREUM_TOMAN" +
        "&market_type=LITE_COIN_TOMAN" +
        "&market_type=BINANCE_COIN_BSC_TOMAN" +
        "&market_type=STELLAR_TOMAN" +
        "&market_type=TRON_TOMAN" +
        "&market_type=CELO_TOMAN" +
        "&market_type=RIPPLE_TOMAN" +
        "&market_type=DOGE_COIN_TOMAN" +
        "&market_type=MATIC_TOMAN" +
        "&market_type=TETHER_TOMAN",
    };
  },
  methods: {
    async setColor() {
      this.cryptoList.map((coin, index) => {
        coin.relatedCoin = this.$coin2Snake[coin.relatedCoin];

        // console.log('hi')
        Vibrant.from(
          require("@/assets/coins/" + coin.relatedCoin + ".png")
        ).getPalette((err, palette) => {
          // this.$ref(index).style.backgroundColor = "rgba("+palette.Vibrant.rgb+",1)"
          let coins = document.getElementById(index);
 
          //   console.log(coin.relatedCoin,palette.Vibrant)
          //   background:' linear-gradient(#e66465, #9198e5)'
          if (coin.relatedCoin == "LTC" && coin.marketType.includes("TOMAN")) {
            coins.style.background =
              "linear-gradient(rgb(100, 124, 236), rgb(22, 18, 219))";
          } else if (
            coin.relatedCoin == "LTC" &&
            coin.marketType.includes("TETHER")
          ) {
            coins.style.background =
              "linear-gradient(rgb(100, 124, 236), rgb(52, 176, 8))";
          }
          coins.style.background = `linear-gradient(${palette.Vibrant.hex} , ${
            coin.marketType.includes("TETHER") ? "#34B008" : "#1612DB"
          })`;
          //   coins.style.background="linear-gradient("+palette.Vibrant.hex+","+(coin.marketType.includes("TETHER"))?'#34B008':'#1612DB'+")"
          //    coins.style.background= palette.Vibrant.hex
        });
      });
    },
    async setData() {
      this.state.loading = true;
      const res = await this.$axios.get(
        "/trades/market-info?" + this.TomanCoin,
        {
          params: {
            weeklyDataIncluded: true,
          },
        }
      );

      this.cryptoList = res.content;
      // if (this.cryptoList.length == res.content.length) {
      //   // this.state.loading = false

      //   // this.setColor();
      // }

    

// while(this.cryptoList.length !=0){
//   this.setColor()
// }




    },
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get(
        "/trades/market-info?" + this.TomanCoin,
        {
          params: {
            weeklyDataIncluded: true,
          },
        }
      );
      this.tableContents = res.content;
        
      console.log(res.content);
    },
    goto(e) {
      this.$router.push("trade/" + "TOMAN" + `/${e}`);
    },
    marketInfo(e) {
      this.$router.push("/market/" + e);
    },
  },
  // watch:{
  //     'cryptoList':function(){
  //         this.setColor()
  //     }
  // },

      watch:{
        'state.lang':function(){
            this.setColor()
          this.setData()
        },
       
'cryptoList'(){
if(this.cryptoList.length !=0 ){

this.setColor()
}
},


    },









  async mounted() {
    await this.getCoins();
    await this.setData()


  },


};
</script>

<style lang="scss" scoped>
.slide {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(5px);
  border-radius: 3px;
  /* width: 277px; */
  height: 116px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 20px; */
}
.main-slide {
  padding: 0 10px;
}

@supports not (backdrop-filter: blur(10px)) {
  .slide {
    background: #252833 !important;
  }
}

.information,
.image {
  margin: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-slide {
  width: 70px;
  height: 70px;
}

.crypto-name {
  color: #ffffff;
  font-weight: normal;
  font-size: 18px;
}

.crypto-price {
  font-weight: normal;
  font-size: 18px;
  color: #80cc52;
}

.crypto-change {
  font-weight: normal;
  font-size: 14px;
  direction: ltr;
}

.negetive-change {
  color: rgb(247, 131, 131);
}

.positive-change {
  color: #80cc52;
}

.img-container {
  border-radius: 33px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  span {
    font-size: 13px;
    font-weight: 300;
  }
}
.chart {
  text-align: center;
}
.coins-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.content {
  margin-top: clamp(12px, 1vw, 32px);
  margin-bottom: clamp(30px, 1vw, 62px);
  display: flex;
  flex-direction: column;
  gap: 32px;
}
//main title of page
.title {
  font-size: clamp(20px, 1vw, 24px);
}

//carosel container
.carosel-container {
  .slide {
    max-width: 282px !important;
    height: 127px;
  }
  .carousel {
    gap: 12px;
  }
  .card {
    //  max-width: 282px !important;
    padding: 12px;
    background: #191730;
    border-radius: 12px;
    display: flex;
    gap: 12px;
    min-height: 127px;
  }
  .changes {
    display: flex;
    flex-direction: column;
    gap: 12px;
    p {
      font-size: clamp(10px, 1vw, 13px);
    }
    p:nth-child(2) {
      font-size: clamp(12px, 1vw, 16px);
    }
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
  p {
    font-size: clamp(16px, 1vw, 20px);
    color: var(--gray);
  }
}

//table
.table-container {
  background: #191730;
  border-radius: 12px;
  padding: 20px 24px;
}

//table
.image-cont {
  display: flex;
  gap: 8px;
  //justify-content: start;
  align-items: flex-start;
}

table {
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
}

td {
  font-size: clamp(12px, 1vw, 16px);
  font-weight: 400;
}

//thead
.tr-head {
  line-height: 50px;
  border-bottom: 1px #787878 solid;
  td {
    font-size: clamp(10px, 1vw, 13px) !important;
    color: var(--gray);
    p {
      font-size: clamp(12px, 1vw, 16px);
    }
  }
  td:first-child {
    width: 80px;
  }
}

//tbody
.tr-body {
  td {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }

  td:nth-child(2) {
    cursor: pointer;
  }
  height: 70px;
}
.tr-body:first-child {
  line-height: 50px;
}

//tablet and phone view


@media screen and (max-width: 1100px) {
  .table-container {
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}
</style>

<style>

.VueCarousel {
  direction: revert !important;
  width: 90%;
  /* max-width: 1400px; */
  margin: 0 auto;
}

.VueCarousel-pagination {
  height: 10px !important;
}

.VueCarousel-dot {
  display: none !important;
  cursor: pointer;
  appearance: none;
  border: none;
  background-clip: content-box;
  box-sizing: content-box;
  padding: 0;
  border-radius: 100%;
  outline: none;
  background-color: black !important;
  margin-top: 0 !important;
}

.VueCarousel-dot--active {
  background-color: gainsboro !important;
}

.VueCarousel-dot-container {
  margin-top: 10px !important;
  display: none 1 !important;
}


</style>
