import Vue from "vue";
import en from "./multiLang/en-lang/en";
import fa from "./multiLang/fa-lang/fa";
Vue.use(require("vue-cookies"));
//we create a state
const that = Vue.prototype;
const state = Vue.observable({
  //a string that carries the name of local language we want
  lang: "en",
  /*this command will save the lang variable as a value for lng key in localstorage
     & it has a ternary statement that say if the value of lng was 'fa' load the farsi language
    else load en language Caution *if you don't want use english please do not return an empty string for lang* */
  lng: localStorage.lang === "en" ? en : fa,
  /*our site has only 2 languages so it is not important to use 2 variable for our languages
we use this variable for our language button we will control it into our navbar so we need it
 */
  lan: en,
  exchangeBuyPrice: 0,
  exchangeSellPrice: 0,
  fontColor: "red",
  theme: "dark",
  light: false,
  coins: false,
  transferFee: 0,
  orderChange: 0,
  // token: localStorage.token || Vue.$cookies.get("--abcd14589"),
  token:'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NTQ1Nzk5MjEsImV4cCI6MTY4NjExNTkyMX0.BLN9uIsQiemluwICs_DdOD5-Hzr_8Cv98vu7e0LTIpc',
  loading: false,
  gotten: false,
  loop: true,
  reload: 0,
  progress: 0,
  towStepAuthenticationType: "",
  userInfo: {
    firstName: "",
    lastName: "",
    email: "",
    remember: false,
    towStepAuthenticationType: "",
    address:'',
    telephone:'',
    mobileNumber:'',
    authenticationType: "",
    authorized: false,
    pending: false,
    supervisor: false,
    tradeAmount: 0,
    nationalCode:'',
    birthDay:'',
    userAccountLevel: "",
    totalTradesVolume: 0,
    fee: 0.4,
  },
  shopInfo: {
    minSell: 0,
    maxBuy: 0,
    lastPrice: 0,
  },
  buyAmount: 0,
  buyPrice: 0,
  sellAmount: 0,
  sellPrice: 0,
});
that.state = state;
export default state;
