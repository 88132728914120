var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"coins-list"},[_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.state.lan.coinlist.title))]),_c('div',{staticClass:"carosel-container"},[_c('p',[_vm._v(_vm._s(_vm.state.lan.coinlist.lasttrade))]),_c('div',{staticClass:"carosel-container"},[_c('carousel',{attrs:{"rtl":_vm.state.lang == 'fa' ? true : false,"per-page":4,"autoplay":true,"loop":true,"speed":900,"per-page-custom":[
            [320, 1],
            [768, 3],
            [600, 2],
            [1000, 5] ],"autoplay-timeout":3000}},_vm._l((_vm.cryptoList),function(coin,index){return _c('slide',{key:index,staticClass:"main-slide",on:{"click":function($event){return _vm.goto(_vm.$coin2Snake[coin.relatedCoin])}}},[_c('div',{staticClass:"card"},[_c('div',{ref:index,refInFor:true,staticClass:"img-container",attrs:{"id":index}},[_c('img',{attrs:{"src":("/coins/" + (_vm.$coinUnit[coin.relatedCoin]) + ".png"),"alt":coin.relatedCoin,"width":"40","height":"40"}}),(_vm.state.lang === 'fa')?_c('span',[_vm._v(_vm._s(coin.marketType.includes("TOMAN") ? "تومان" : "تتر"))]):_vm._e(),(_vm.state.lang === 'en')?_c('span',[_vm._v(_vm._s(coin.marketType.includes("TOMAN") ? "TMN" : "USDT"))]):_vm._e()]),_c('div',{staticClass:"changes"},[(_vm.state.lang === 'fa')?_c('p',[_vm._v(" "+_vm._s(_vm.$coinLabel[coin.relatedCoin])+" / "+_vm._s(coin.marketType.includes("TOMAN") ? "تومان" : "تتر")+" ")]):_vm._e(),(_vm.state.lang === 'en')?_c('p',[_vm._v(" "+_vm._s(_vm.$coinUnit[coin.relatedCoin])+" / "+_vm._s(coin.marketType.includes("TOMAN") ? "Toman" : "Tether")+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$toLocal(coin.lastPrice, _vm.$decimal["TOMAN"])))]),_c('p',{class:{
              positive: coin.last24HourChange >= 0,
              negative: coin.last24HourChange < 0,
            }},[_vm._v(" % "+_vm._s(_vm.$toPrecision(coin.last24HourChange, 3))+" ")])])])])}),1)],1),_c('p',[_vm._v(_vm._s(_vm.state.lan.coinlist.alltrades))]),_c('div',{staticClass:"table-container"},[_c('table',[_c('tr',{staticClass:"tr-head"},[_c('td',{staticClass:"tablet"},[_vm._v("#")]),_c('td',[_vm._v(_vm._s(_vm.state.lan.marketv.coin))]),_c('td',{staticClass:"phone"},[_vm._v(_vm._s(_vm.state.lan.marketv.change))]),_c('td',[_vm._v(_vm._s(_vm.state.lan.marketv.lp))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(_vm.state.lan.marketv.mc))]),_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(_vm.state.lan.marketv.weekly))]),_c('td',{staticClass:"tablet chart"},[_vm._v(_vm._s(_vm.state.lan.marketv.chart))]),_c('td',[_vm._v(_vm._s(_vm.state.lan.marketv.action))])]),_vm._l((_vm.tableContents),function(coin,index){return _c('tr',{key:index,staticClass:"tr-body hover"},[_c('td',{staticClass:"tablet"},[_vm._v(_vm._s(index + 1))]),_c('td',{on:{"click":function($event){$event.preventDefault();return _vm.marketInfo(_vm.$coin2Snake[coin.relatedCoin])}}},[_c('div',{staticClass:"image-cont"},[_c('span',[_c('img',{attrs:{"src":("/coins/" + (_vm.$coinUnit[coin.relatedCoin]) + ".png"),"alt":coin.relatedCoin,"width":"32","height":"32"}})]),(_vm.state.lang === 'fa')?_c('p',{staticClass:"phone"},[_vm._v(" "+_vm._s(_vm.$coinLabel[coin.relatedCoin])+" ")]):_vm._e(),(_vm.state.lang !== 'fa')?_c('p',{staticClass:"phone"},[_vm._v(" "+_vm._s(_vm.$coinUnit[coin.relatedCoin])+" ")]):_vm._e()])]),_c('td',{staticClass:"phone",class:{
                positive: coin.last24HourChange >= 0,
                negative: coin.last24HourChange < 0,
              }},[_vm._v(" % "+_vm._s(_vm.$toPrecision(coin.last24HourChange, 2))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.$toLocal(coin.lastPrice, _vm.$decimal["TOMAN"]))+" ")]),_c('td',{staticClass:"tablet"},[_vm._v(" "+_vm._s(_vm.$toLocal(coin.last24HourVolume, _vm.$decimal["TOMAN"]))+" ")]),_c('td',{staticClass:"tablet",class:{
                positive: coin.last24HourChange >= 0,
                negative: coin.last24HourChange < 0,
              }},[_vm._v(" % "+_vm._s(_vm.$toPrecision(coin.last24HourChange, 1))+" ")]),_c('td',{staticClass:"tablet"},[_c('trend-chart',{staticClass:"trend",attrs:{"trend":coin.lastWeekData}})],1),_c('td',[_c('a',{attrs:{"href":"/trade"},on:{"click":function($event){$event.preventDefault();return _vm.goto(_vm.$coin2Snake[coin.relatedCoin])}}},[_vm._v(_vm._s(_vm.state.lan.marketv.trade))])])])})],2)])])]),_c('footer-component')],1)}
var staticRenderFns = []

export { render, staticRenderFns }