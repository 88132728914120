var crypto = [
    {
        name: "bitcoin",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
        link:'https://bitcoin.com'
    },
    {
        name: "litecoin",
        ENname: "LTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/LITE.png"),
    },
    {
        name: "tether",
        ENname: "THR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "bitcoin ",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
    {
        name: "tether",
        ENname: "ETR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "tether",
        ENname: "ETR",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: 0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/TETHER.png"),
    },
    {
        name: "bitcoin ",
        ENname: "BTC",
        price: 875500,
        buyPriceTooman: 36456981,
        sellPriceTooman: 34987456,
        buyPriceTeter: 756,
        sellPriceTeter: 740,
        change: -0.45,
        lastPrice: 1732450532,
        weeklyChanges: 0.2,
        tradeVolume: 1732450532,
        image: require("../assets/coins/BTC.png"),
    },
];
export default crypto;
