var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"slider"}},[_c('span',{staticClass:"slider-back"}),_vm._l(([0, 0.25, 0.5, 0.75, 1]),function(n){return _c('span',{key:n,staticClass:"dot",style:({
      left: n * 100 + '%',
      transform: 'translate(' + -1 * n * 100 + '%, -50%)',
    }),on:{"click":function($event){$event.preventDefault();_vm.value = n;
      _vm.putcash(_vm.value);}}})}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value),expression:"value"}],staticClass:"slider",attrs:{"type":"range","id":'one' + _vm.id,"min":"0","max":"1","step":"0.01"},domProps:{"value":(_vm.value)},on:{"change":function($event){return _vm.putcash(_vm.value)},"__r":function($event){_vm.value=$event.target.value}}}),_c('label',{staticClass:"rangenumber",style:({
      left: 'min(100%,' + _vm.value * 100 + '%)',
      transform: 'translate(max(-100%,' + -1 * _vm.value * 100 + '%), -50%)',
    }),attrs:{"for":'one' + _vm.id}},[_c('a',[_vm._v(_vm._s((_vm.value * 100).toFixed(0))+"%")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }