<template>
  <div class="bank">
    <div class="titles">
      <p class="title">{{ state.lan.account.banktitle }}</p>
      <a class="add-card" href="" @click.prevent="addaccount">
        <span class="plus">+</span> {{ state.lan.account.addcard }}</a
      >
    </div>
    <div class="forms-add">
      <div class="add-titles"></div>
      <div class="add_form">
        <label>
          <input
            class="normal-input name"
            type="text"
            :placeholder="state.lan.account.bankname"
            v-model="BanksForPost.name"
            disabled
          />
        </label>

        <label>
          <input
            class="normal-input number"
            type="text"
            v-model="BanksForPost.card_number"
            @input="setName"
            :placeholder="state.lan.account.cardname"
            :max="16"
          />
        </label>
        <label>
          <input
            class="normal-input number"
            type="text"
            v-model="BanksForPost.sheba"
            @input="BanksForPost.sheba = $p2e(BanksForPost.sheba)"
            :placeholder="state.lan.account.sheba"
          />
        </label>
      </div>
    </div>
    <div class="bank-existing">
      <div class="bank-titles">
        <p>{{ state.lan.account.bankname }}</p>
        <p>{{ state.lan.account.cardname }}</p>
        <p>{{ state.lan.account.sheba }}</p>
        <p>{{ state.lan.account.remcard }}</p>
      </div>
      <div class="exist-banks">
        <div class="banks-list" v-for="(bank, index) in banks" :key="index">
          <div class="bank-name" id="name">
            <img
              src="../../../assets/banks/Parsian-logo-LimooGraphic.png"
              alt=""
              width="44"
              height="44"
            />
            {{ state.lang === "fa" ? $bank[bank.name] : bank.name }}
          </div>
          <div class="bank-number">
            <span>
              {{ bank.card_number }}
            </span>
          </div>
          <div class="bank-number" id="sheba">
            <span>
              {{ bank.sheba }}
            </span>
          </div>

          <img
            @click="deletRow(index)"
            class="delete-icon"
            src="@/assets/Public/bin.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bankcards",
  data() {
    return {
      id: "",
      reload: false,
      cardErr: "",
      shebaErr: "",
      ccountErr: "",
      deleteModal: false,
      edit: false,
      editId: "",
      BanksData: [],
      BanksForPost: {
        card_number: "",
        sheba: "",
        name: "",
      },
      banks: [
        {
          card_number: "345343434343",
          sheba: "4343434343",
          name: "parsian",
        },
      ],
      errorText: "",
      banksName: {
        603799: "بانک ملی ایران",
        589210: "بانک سپه",
        627648: "بانک توسعه صادرات",
        207177: "بانک توسعه صادرات",
        627353: "بانک تجارت",
        585983: "بانک تجارت",
        627961: "بانک صنعت و معدن",
        603770: "بانک کشاورزی",
        639217: "بانک کشاورزی",
        628023: "بانک مسکن",
        627760: "پست بانک ایران",
        502908: "بانک توسعه تعاون",
        627412: "بانک اقتصاد نوین",
        622106: "بانک پارسیان",
        639194: "بانک پارسیان",
        627884: "بانک پارسیان",
        502229: "بانک پاسارگاد",
        639347: "بانک پاسارگاد",
        639599: "بانک قوامین",
        504706: "بانک شهر",
        502806: "بانک شهر",
        603769: "بانک صادرات",
        610433: "بانک ملت",
        991975: "بانک ملت",
        589463: "بانک رفاه",
        502938: "بانک دی",
        639607: "بانک سرمایه",
        627381: "بانک انصار",
        505785: "بانک ایران زمین",
        636214: "بانک آینده",
        636949: "بانک حکمت ایرانیان",
        621986: "بانک سامان",
        639346: "بانک سینا",
        606373: "بانک قرض الحسنه مهر",
        627488: "بانک کارآفرین",
        502910: "بانک کارآفرین",
        505416: "بانک گردشگری",
        639370: "بانک مهر اقتصاد",
        628157: "موسسه اعتباری توسعه",
        505801: "موسسه اعتباری کوثر",
        504172: " بانک رسالت",
      },
      banksImage: {
        589210: require("../../../assets/banks/Sepah-logo-LimooGraphic.png"),
        622106: require("../../../assets/banks/Parsian-logo-LimooGraphic.png"),
        639194: require("../../../assets/banks/Parsian-logo-LimooGraphic.png"),
        627884: require("../../../assets/banks/Parsian-logo-LimooGraphic.png"),
        610433: require("../../../assets/banks/Mellat-logo-LimooGraphic.png"),
        991975: require("../../../assets/banks/Mellat-logo-LimooGraphic.png"),
      },
    };
  },
  methods: {
    setName() {
      // this.BanksForPost.card_number = $p2e(this.BanksForPost.card_number)
      this.BanksForPost.name =
        this.banksName[
          this.BanksForPost.card_number
            ? this.BanksForPost.card_number.replace(/-/gi, "").slice(0, 6)
            : ""
        ] || "کارت نامعتبر";
    },
    addaccount() {
      if (this.BanksForPost.sheba && this.BanksForPost.card_number) {
        this.banks.push(this.BanksForPost);
        this.BanksForPost = {
          sheba: "",
          card_number: "",
        };
      }
    },
    deletRow(index) {
      this.banks.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>

.bank-existing {
  display: flex;
  flex-direction: column;
  gap: 8px;
  .bank-titles {
    display: flex;
    justify-content: space-between;
    font-size: clamp(10px, 1vw, 13px);
    color: var(--gray);
  }
}

.bank {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  background: #191730;
  gap: 26.5px;
  padding: clamp(5px, 2.5vw, 26px);
  border-radius: 12px;
  min-height: 202px;
}

//titles
.titles {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: clamp(16px, 1.5vw, 20px);
    font-weight: 700;
  }

  a {
    display: flex;
    gap: 4px;
    color: var(--light-blue);
    font-size: clamp(16px, 1vw, 18px);
  }
}

.add-titles {
  display: flex;
  justify-content: space-between;
}

.normal-input {
  flex: 1;
  width: 100%;
  padding: 10px;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 4px;
  color: white !important;
}
.normal-input::placeholder {
  color: #cacace;
  padding: 10px;
}
.normal-input:focus {
  border: 0.6px solid rgba(179, 176, 176, 0.5) !important;
}

.add_form {
  padding: 10px;

  //height: 60px;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.exist-banks {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .banks-list {
    font-size: clamp(10px, 1vw, 16px);
    img {
      background: white;
      border-radius: 5px;
    }
    background: #101127;
    border-radius: 4px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .delete-icon {
      cursor: pointer;
    }

    .bank-number {
      display: flex;
      align-items: center;
      justify-content: space-between;

      box-sizing: border-box;
      border-radius: 4px;
      width: 50%;
      max-width: 148px;
      height: 40px;
      padding: 0 12px;
    }
  }
}

.bank-name {
  display: flex;
  align-items: center;
  gap: 16px;
}

@media (max-width: 600px) {
  .delete-icon {
    width: 16px;
    height: 16px;
  }
  .bank-name {
    img {
      display: none;
    }
    .banks-list {
    }
  }
}

@media (max-width: 490px) {
  #sheba,
  #name {
    display: none;
  }

  .bank-titles {
    p:first-child {
      display: none;
    }
    p:nth-child(3) {
      display: none;
    }
  }
}
</style>
