<template>
  <div class="views">
    <div class="action relative">
      <div class="inventory small gray">

        موجودی:


        {{$toLocal(inventory)}}</div>
      <div class="main-title">
        <p class="title">{{state.lan.wallet.withdraw.toman.witt}}</p>
        <div class="input-container">
          <input type="text" :placeholder="state.lan.wallet.diposit.toman.amountt" v-model="amount" @input="amount =$toLocal(amount)">
      <div class="tmn-container">
        <p>{{state.lan.wallet.diposit.toman.toman}}</p>
        <img src="../../../assets/profile/refresh.svg" alt="">
      </div>
        </div>

      </div>
      <div class="bank_info">
        <button class="choose_bank" @click.prevent="drop=!drop">
          {{select}}
          <img src="../../../assets/Public/selector.svg" alt="" width="14" height="7">
        </button>
        <button @click.prevent="$router.push({name:'Account'})" class="adding"><img src="../../../assets/profile/addbank.svg" alt=""></button>
        <div class="drop" v-if="drop">
          <p v-for="(card,index) in cards"     @click.prevent="selectItem(index)"  >{{card.number}}</p>
        </div>
      </div>
      <button class="submit" :disabled="buttonError" @click.prevent="isWithdraw=true">
<span v-if="!buttonError">
          {{state.lan.wallet.withdraw.toman.sub}}
        <img src="../../../assets/profile/flesh.svg" alt="" :class="{'mirror': state.lang !== 'fa'}">
</span>
        <span v-else>
          {{buttonError}}
        </span>
      </button>
    </div>
    <div class="guide">
      <p class="title">{{state.lan.wallet.diposit.toman.guide}}</p>
      <div class="text">
        <p>{{state.lan.wallet.diposit.toman.text}}</p>
        <p>{{state.lan.wallet.diposit.toman.text2}}</p>
      </div>
    </div>
    <withdraw-modal v-if="isWithdraw"  @close='isWithdraw = !isWithdraw' :amount="amount" :walletAddress="select"/>
  </div>
</template>

<script>
import inventory from "@/components/Profile/Wallet/Inventory";
import WithdrawModal from "@/components/Profile/Wallet/withdrawModal";

export default {
  name: "TomanDepositAction",
  components: {WithdrawModal},
  data(){
    return{
      inventory:0,
      isWithdraw:false,
      amount:null,
      cards:[

        {
          number:'2344455355366'
        },
        {
          number: 'IR-25677855-355366'
        },
        {
          number: 'IR-2344455-355236'
        }
      ],
      select:'',
      drop:false,
    }
  },
  computed:{
    buttonError(){
      let error;




      if(this.state.lang=='fa'){

        if(this.amount == null ||this.amount ==0){
          error = 'لطفا مقدار را وارد کنید'
        }
        else if(this.select == 'شماره شبا'){
          error = 'لطفا آدرس کیف را وارد کنید'
        }


        // else if(this.inventory < this.amount){
        //   error = 'موجودی کافی نیست'
        // }



      }
      if(this.state.lang!=='fa'){


        if(this.amount == '' || this.amount ==0 || this.amount == null){
          error = 'Please enter amount'
        }
        else if(this.walletAddress == ''){
          error = 'Please enter wallet address'
        }

        else if(this.$S2N(this.inventory) < this.$S2N(this.amount)){
          error = 'Insufficient balance'
        }


      }

      return error

    }
  },

  methods:{
    selectItem(index) {
      this.select = this.cards[index].number;
      // this.selectedid = this.coins[index].id
      // this.logo = this.coins[index].image;
      // this.price=this.coins[index].price
      this.drop = false;

    },
    async getWalletData() {
      this.state.loading = true;
      const target = this.$route.params.target;
      const res = await this.$axios(`/wallets/customer-addresses`);
      let entireRes = Object.entries(res);
      const sortCoins = this.$walletSortCoins;
      entireRes.sort(
          (a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0])
      );

      this.inventory = res['TOMAN'].credit

    },

  },
  mounted() {
    this.select= this.state.lan.wallet.withdraw.toman.sheba;
    this.getWalletData()
  },

}
</script>

<style lang="scss" scoped>

.inventory{
  position: absolute;
  top: 40px;
}
.tmn-container{
p{
  color: #343444 !important;
}
  display: flex;
  gap: 4px;
}

.mirror{
  transform: scale(-1,1);
}

.drop{
  cursor: pointer;
  display: flex;
  gap: 10px;
  z-index: 5;
  flex-direction: column;
  background: #131330;
  border-radius: 12px;
  padding: 16px;
  width: 87%;
  border:1px solid rgba(255,255,255,0.2);
  position: absolute;
  top: 50px;
}
.submit{
display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;

  background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
  border-radius: 12px;
  height: 40px;
  width: 40%;

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    gap: 5px;
  }
}

.choose_bank{
  align-items: center;
  width: 87%;
  height: 44px;
  color: var(--gray);
  display: flex;
  justify-content: space-between;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 12px;
}
.bank_info{
  position: relative;
  flex-wrap: wrap;
  display: flex;
  gap: 18px;
  width: 100%;
}
.adding{
  //align-self: flex-end;
  width: 10%;
}
.views{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .action,.guide{
    display: flex;
    flex-direction: column;
    background: #191730;
    border-radius: 12px;
    min-height: 375px;
    padding: 24px;
  }
  .action{
    flex-grow: 2.7;
    flex-basis: 270px;
    display: flex;
    flex-direction: column;
    gap:64px;

  }
  .guide{
    display: flex;
    width: 268px;
    flex-direction: column;
    gap: 32px;
    color: var(--gray);
    .title{
      font-size: clamp(16px,1vw,20vw);

    }
    .text{
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
.main-title{
  display: flex;
  flex-direction: column;
  gap:16px ;
  .title{
    font-size: clamp(20px,2vw,24px) ;
    color: var(--gray);
  }
  .input-container{
    input{
      width: 110px;
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% ;
    height: 44px;
    background: #131330;
    border: 0.6px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 12px;
    p{
      color: var(--gray);
    }
  }
}
@media(max-width: 500px){
  .submit{
    width: 100%;
  }
  .choose_bank{
    width: 100%;
  }
  .adding{
    width: 100%;
    align-self: center;
  }

}
</style>