import Vue from "vue";

// axios
import axios from "axios";
import router from "@/library/router";

const that = Vue.prototype;

if (that.state.token) {
  // console.log('I have token : ', that.state.token)
  axios.defaults.headers.common["Authorization"] = that.state.token;
}

// axios.defaults.baseURL = 'https://beebitex-1.com/api'
// axios.defaults.baseURL = "https://vistadev.xyz/exchange/api";
axios.defaults.baseURL = "https://tiirex.website/p2p/api";
// axios.defaults.baseURL = "https://safexexchange.com/api";

if (process.env.NODE_ENV === "development") {
  // axios.defaults.baseURL = 'https://hakarex.net/api'
}

axios.interceptors.response.use(
  (res) => {
    that.state.loading = false;

    return res.data;
  },
  (err) => {
    that.state.loading = false;

    const res = err.response || err.toJSON();
    // console.log(err.response.data);
    if (res.data.message.includes("429")) {
      return Promise.reject(err);
    }

    if (res.config.responseType === "blob") {
      const vm = that;
      let reader = new FileReader();
      reader.onload = function (e) {
        let test = JSON.parse(e.target.result);
        this.state.lang === "fa"
          ? vm.$error("", vm.$errorHandeling[test.message])
          : vm.$error("", this.message);
      };
      reader.readAsText(err.response.data);
      return Promise.reject(err);
    }

    if (err.response)
      if (res.data.message)
        if (
          res.data.message.includes("JWT") ||
          res.data.message.includes("Invalid Token")
        ) {
          //jwt errors
          that.$logout();
          return Promise.reject(err);
        } else if (err.response.data.message.includes("ShebaCode")) {
          that.state.lang !== "fa"
            ? that.$error("Duplicated card", "")
            : that.$error("شماره شبا یا کارت تکراری است.", "");
          return Promise.reject(err);
        } else if (
          ["GOOGLE_FAILURE", "Can't Access Resource"].includes(
            err.response.data.message
          )
        ) {
          return Promise.reject(err);
          // user identity
        } else if (err.response.data.message === "USER_IDENTITY_DATA") {
          // console.log('in USER_IDENTITY_DATA');

          // mobile number
          const a = {
            MOBILE_IS_INCORRECT: "شماره همراه تکراری میباشد.",
            NATIONAL_CODE_IS_INCORRECT: "کد ملی تکراری میباشد.",
            TELEPHONE_IS_INCORRECT: "شماره تلفن تکراری میباشد.",
          };
          let uniqueErrors = "";
          for (let i = 0; i < err.response.data.errors.length; i++) {
            if (that.state.lang == "fa") {
              uniqueErrors =
                uniqueErrors +
                (a[err.response.data.errors[i]] ||
                  err.response.data.errors[i]) +
                "\n";
            } else
              uniqueErrors =
                uniqueErrors +
                (err.response.data.errors[i] || err.response.data.errors[i]) +
                "\n";
          }
          //showing modal
          state.lang !== "fa"
            ? that.$error("Duplicated information", uniqueErrors)
            : that.$error("اطلاعات تکراری میباشد", uniqueErrors);
          return Promise.reject(err);

          //bad request handling
        } else if (err.response.data.status === "BAD_REQUEST") {
          let a = err.response.data.errors[0];
          // a = JSON.parse(a.match(/\[(.*?)\]/)[1])

          //finotech errors
          const codeError = {
            "invalid otp or mobile": "کد وارد شده اشتباه است",
            "otp used before": "کد منقضی شده است",
          };
          const finoError = {
            "nid is not valid": "کد ملی صحیح نیست",
            "mobile is not valid": "شماره همراه صحیح نیست",
            "nid and mobile is not match":
              "شماره همراه شما با کد ملی مطابقت ندارد",
            "هیچ رکوردی یافت نشد. لطفا مقادیر ورودی را کنترل نمایید":
              "تاریخ تولد با سایر اطلاعات همخوانی ندارد.",
          };
          if (that.state.lang == "fa") {
            if (finoError[a] || codeError[a]) {
              that.$error("خطای احراز هویت", codeError[a] || finoError[a]);
              if (finoError[a]) {
                router.push({ name: "Account" });
              }
              return Promise.reject(err);
            } else if (that.state.lang == "en") {
              if (a) {
                that.$error("Authentication Error", a);
                router.push({ name: "Account" });
              }
              return Promise.reject(err);
            }
          }
        }

    // if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
    //     return Promise.reject(err);
    // }

    let text = err.response
      ? that.$errorHandeling[err.response.data.message] ||
        that.$errorHandeling[err.response.data.status] ||
        res.data.errors ||
        res.data.error
      : res.message;
    if (that.state.lang == "en") {
      text = err.response
        ? err.response.data.message ||
          err.response.data.status ||
          res.data.errors ||
          res.data.error
        : res.message;
    }

    if (
      (err.response && that.$errorHandeling[err.response.data.message]) ||
      that.$errorHandeling[err.response.data.status]
    ) {
      that.$error(err.request.status, text);
    }

    return Promise.reject(err);
  }
);

let func = async (config) => {
  try {
    const res = await axios(config);
    return [res, null];
  } catch (e) {
    return [null, e];
  }
};
const props = {
  get: async (url, config) => {
    try {
      const res = await axios.get(url, config);
      return [res, null];
    } catch (e) {
      return [null, e];
    }
  },
  post: async (url, data, config) => {
    try {
      const res = await axios.post(url, data, config);
      return [res, null];
    } catch (e) {
      return [null, e];
    }
  },
  put: async (url, data, config) => {
    try {
      const res = await axios.put(url, data, config);
      return [res, null];
    } catch (e) {
      return [null, e];
    }
  },
  delete: async (url, config = {}) => {
    try {
      const res = await axios.delete(url, config);
      return [res, null];
    } catch (e) {
      return [null, e];
    }
  },
};
for (let prop in props) {
  func[prop] = props[prop];
}

that.$http = func;
that.$axios = axios;

export const http = func;
export default axios;
