<template>
  <div class="forget">
    <div class="title">
      <h4>{{ state.lan.forget.title }}</h4>
      <p>{{ state.lan.forget.text }}</p>
    </div>
    <div class="mail">
      <label for="email">{{ state.lan.forget.label }}</label>
      <div class="mail-container">
        <label>
          <inline-svg :src="require('../../assets/Authentication/email.svg')"/>
        </label>
        <input id="email" :placeholder="state.lan.signup.EmailPlace" name="email" type="email" v-model="email">
      </div>
    </div>
    <img alt="" src="../../assets/Authentication/recap.png" width="267">
    <button @click.prevent="submition">{{ state.lan.forget.submit }}</button>
  </div>
</template>

<script>
export default {
  name: "Forget",
  data(){
    return{
      email:''
    }
  },
  methods:{
    async  checkMail(){
      if(!this.$validEmail(this.email) || !this.email){
        this.emailError = 'ایمیل صحیح وارد نمایید'
        this.$error('مقادیر اشتباه','ایمیل وارد شده صحیح نیست','','','','2500')
        return Promise.reject('repeat failed : ' + this.email)
      }
      else return this.emailError = ''

    },


    async submition(){
      this.checkMail()
      if(!this.emailError){
        const res = await this.$axios.post('/users/forget-password?email=' + this.email)
        if (res.message === 'code sent successfully.') {
          this.state.userInfo.mobilenumber = res.baseDTO.starTwoStepAuthentication || '';
          this.state.userInfo.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
        } else {
          this.state.userInfo.towStepAuthenticationType = 'EMAIL'
        }
        this.state.userInfo.email =this.email
        await this.$router.push({name:'Newpass'})


      }



    }
  }
}
</script>

<style lang="scss" scoped>
//forget password Main container
.forget {
  width: 100%;
  max-width: 390px;
  min-height: 397px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 36px 24px;
  row-gap: 40px;
}

//title of forget password
.title {
  display: flex;
  flex-direction: column;
  gap: 24px !important;

  p {
    color: var(--gray);
    font-size: clamp(10px, 1vw, 13px);
  }


}

//email container

.mail, {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.mail-container {
  display: flex;
  border: 1px solid rgba(179, 176, 176, 0.5);
  border-radius: 4px;
  background: #131330;
  align-items: center;
  height: 36px;
  padding: 0 9px;
  gap: 5px;

  label {
    display: flex;
  }
}

//recap image alignment
img {
  align-self: center;
}


//submit button
button {
  display: flex;
  border-radius: 12px !important;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px, 1vw, 12px);
  font-weight: 400;
  background: #04235C;
  border: 1px solid rgba(178, 176, 176, 0.5);
  min-height: 40px;
  padding: 0 12px;
}
@media(max-width: 500px){
  .forget{
    width: 90%;
  }
  .mail-container ,.pass-container{
    >label{
      >svg{
        width: 14px;
      }
    }
  }
}

</style>