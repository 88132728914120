<template>
<div class="uinfo">
  <p class="title">{{state.lan.account.uititle}}</p>
  <div class="info">
    <div class="titles">
      <p>{{state.lan.account.uiname}}:</p>
      <p>{{state.lan.account.uifamily}}:</p>
      <p>{{state.lan.account.uibd}}:</p>
      <p>{{state.lan.account.uiid}}:</p>
    </div>
    <div class="values">
      <p>{{state.userInfo.firstName}}</p>
      <p>{{state.userInfo.lastName}}</p>
      <p>{{state.lang !=='fa'?state.userInfo.birthDay:$G2J(state.userInfo.birthDay)}}</p>
      <p>{{state.userInfo.nationalCode}}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Userinformation",
  data(){
    return{
      name:'aylin',
      family:'imentalab',
      bd:'1387/9/14',
      id:1234543245,
      mail:'aylin.eimentalab@gmail.com',
      mob: '09123854741',
      fee:+0.4,
      lvl:'3rd',



    }

  }
}
</script>

<style lang="scss" scoped>
.uinfo{
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 260px;
  background: #191730;
  border-radius: 12px;
  //title of the user info
  .title{
font-size: clamp(16px,1.5vw,20px);
    font-weight: 700;
  }
  //this div contains informations
  .info{
    display: flex;
    justify-content: space-between;
    .titles ,.values{
      display: flex;
      flex-direction: column;
      gap: 16px;
      p{
        font-size: clamp(10px,1vw,16px);
      }
    }
    .titles{
      color: var(--gray);
    }

  }
}
</style>