<template>
  <form class="cont d-flex flex-col " @input="calculate">
    <!--  unit price -->
    <div class="input-container">
      <label for="price">{{ state.lan.trade.shop.prlabel }}</label>
      <div class="input-norm">
        <input type="text" name="" id="price"
               @input="unitPrice = $toLocal(unitPrice, $decimal[tradeTo])"
               v-model="unitPrice"
               :style="{ borderColor: danger ? 'red' : '' }"
               :disabled="!limit"
        />
        <span>{{ state.lan.trade.shop.toman }}</span>
      </div>
    </div>
    <!--  amount-->
    <div class="input-container">
      <div class="label-cont">
          <span>
            <label for="amount">{{ state.lan.trade.shop.amlabel }}</label></span
          >
        <span>
            <label for="amount">
              {{
                state.lan.trade.shop.inventory
              }}{{ $toLocal(cash, $decimal[$route.params.tradeFrom]) }}
              {{
                $coinUnit[$Ex[selectcoin]]
              }}</label
            ></span
        >
      </div>
      <div class="input-norm">
        <input type="text" name="" id="amount"
               @input="amount = $toLocal(amount, $decimal[tradeFrom])"
               v-model="amount"
        />
        <span>{{ $coinUnit[$Ex[selectcoin]] }}</span>
      </div>
    </div>
    <date-picker v-if="limit" :placeholder="state.lan.trade.shop.expire"
                 type="datetime"
                 :format="$dateFormat['en']"
                 :display-format="$dateFormat['faDateTime']"
                 :min="$jmoment().format($dateFormat['en'])"

    />
    <div class="range-container">

      <inputrange id="range" @change="setAmount"/>
      <div class="precentage">
        <span>0%</span>

        <span>100%</span>
      </div>
    </div>
    <div class="advanced">
      <button @click.prevent="advancedDrop = !advancedDrop">
          <span>
            {{ state.lan.trade.shop.advanced }}
            <img
                src="../../../../assets/Public/selector.svg"
                alt=""
                width="14"
                :class="{ trans: advancedDrop }"
            />
          </span>
      </button>
      <div class="advanced-drop justify-space-between w100" v-if="advancedDrop">
<div class="d-flex justify-space-between">
            <span
          >{{ state.lan.trade.shop.total }} :
        




          </span
          >
          <span class="">
                {{
              state.lang === "fa" ? toman : usd
            }}{{
              amount + ' ' + $coinUnit[$Ex[selectcoin]]
            }}
          </span>
</div>
<div class="d-flex justify-space-between">
          <span>{{ state.lan.trade.shop.fee }}:</span>
        <span> {{ state.userInfo.fee }}</span>
</div>
        <span class="in-out d-flex justify-space-between "
        >{{
         state.lan.trade.shop.outcome 
          }}
            <span>{{ totalPrice }}{{ tradeTo == 'TETHER' ? 'USDT' : 'TMN' }}</span></span
        >
      </div>
    </div>


    <button
        class="submit active-sell"
        @click.prevent="place = !place"
        :disabled="!!readonly"
    >
      {{
        !!readonly ? readonly : (state.lang === "fa" ? state.lan.trade.shop.sell + ' ' + $coinLabel[$route.params.tradeFrom] : state.lan.trade.shop.buy + ' ' + $route.params.tradeFrom)
      }}
    </button>
    <place-order-modal
        v-if="place"
        :limit="limit"
        :totalPrice="$S2N(totalPrice)"
        :amount="$S2N(amount)"
        :unit-price="unitPrice"
        :date="eDate"
        :buy="false"
        @close="place = !place"/>
  </form>
</template>

<script>

import Inputrange from "@/components/Profile/Trade/Inputrange";
import {tradeTo, tradeFrom, precision, Loop} from "@/library/reuseableFunction";
import PlaceOrderModal from "@/components/Profile/Trade/Shop/placeOrderModal";

export default {
  name: "Sell",
  components: {
    Inputrange,
    PlaceOrderModal
  },
  props: ['cash', 'limit', 'selectcoin', 'detail'],

  data() {
    return {
      place: false,
      transaction: false,
      advancedDrop: false,
      amount: '',
      unitPrice: '',
      buy: false,
      totalPrice: 0,
      eDate: '',
      withdrawl: false,
      label2: null,
      SuccessSell: false,
      thisModal: true,
      //  min:54654654654,
      //  fee:0.4,
      date_disable: true,
      percent: 0,
      danger: false,
      danger1: false,
      danger2: false,
      show: false,
      value: "",
      checked: "",
      calculating: false,
      getAmount: false,
      amountTemp: "",
      count: 0,
      alive: true,
      loop: undefined,
      coins: "",
      priceError: "",
      amountError: "",
      minAmountOrder: null,
      maxAmountOrder: null,
      disableBuyBtn: true,
      rangeValue: 0,
    };
  },
  computed: {

    now() {
      return this.$jmoment().add(5, 'minute').format(this.$dateFormat['en'])
    },
    readonly() {
      console.log('amount:', this.amount, 'unitPrice:', this.unitPrice)
      let priceError = ""
      if (this.limit && !this.$S2N(this.unitPrice)) {
        priceError = 'قیمت واحد اجباریست'
      } else if (!this.$S2N(this.amount)) {
        priceError = 'مقدار ارز اجباریست'
      } else if (this.$S2N(this.amount) < this.min) {
        priceError = 'مقدار ارز کمتر از حد مجاز است'
      } else if (this.$S2N(this.amount) > this.max) {
        priceError = 'مقدار ارز بیشتر از حد مجاز است'
      } else if (this.$S2N(this.amount) > this.$S2N(this.cash)) {
        priceError = 'موجودی کافی نیست'
      }
          // else if (!this.state.userInfo.supervisor) {
          //     priceError = 'موجودی کافی نیست'
          // }
          //  else if (this.load) {
          //     priceError = 'لطفا صبر کنید...'
      // }
      else if (!this.limit && this.state.userInfo.supervisor) {
        priceError = 'بازارگردان قادر به انجام سفارش بازار نیست'
      }
      return priceError
    },
    load() {
      return this.calculating || this.getAmount
    },
    min() {
      return this.minAmountOrder
    },
    max() {
      return this.maxAmountOrder
    },
    range() {
      return 'حداقل ' + this.$toLocal(this.min) + ' ' + this.$coinLabel[this.tradeFrom] +
          '\n' + 'حداکثر ' + this.$toLocal(this.max) + ' ' + this.$coinLabel[this.tradeFrom]
    },
    tradeTo,
    tradeFrom,
    precision
  },
  watch: {
    'state.sellAmount'() {
      this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
      this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
      this.calculate()
    },
    'state.sellPrice'() {
      this.amount = this.$toLocal(this.state.sellAmount, this.decimal)
      this.unitPrice = this.$toLocal(this.state.sellPrice, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
      this.calculate()
    },
    $route() {
      this.getDetail()
    },

  },

  methods: {
    async getDetail() {
      const res = await this.$axios('/coins')
      const target = this.$route.params.tradeFrom;
      const {content} = res;
      const data = content.find(coin => coin.coin === target);
      this.coin = data;
      this.minAmountOrder = data.minAmount;
      this.maxAmountOrder = data.maxAmount;


    },
    //function that changes range in the label


    setValue(e) {
      this.label = e.target.value / 100;
    },
    disableBtn() {
      if (this.$S2N(this.amount) < this.minAmountOrder) {
        this.amountError = 'حداقل میزان معامله رعایت نشده'
        this.disableBuyBtn = true
      } else if (this.$S2N(this.amount) > this.maxAmountOrder) {
        this.amountError = 'حداکثر میزان معامله رعایت نشده'
        this.disableBuyBtn = true
      } else if (((this.percent > 1 || this.percent <= 0) && !this.state.userInfo.supervisor) ||
          this.load ||
          (!this.limit && this.state.userInfo.supervisor)) {
        this.disableBuyBtn = true
      } else if ((this.$S2N(this.totalPrice) > this.$S2N(this.cash)) && !this.state.userInfo.supervisor) {
        this.priceError = 'مقدار بیش از موجودی شماست'
        this.disableBuyBtn = true
      } else if (!this.amount || !this.unitPrice) {
        this.disableBuyBtn = true
      } else {
        this.disableBuyBtn = false
        this.amountError = ''
        this.priceError = ''
      }
    },

    setAmount(value) {
      if (this.cash >= 0) {
        this.amount = this.$toLocal((this.$S2N(this.cash)) * value, this.$decimal[this.tradeFrom])
      } else this.amount = 0
      this.calculate()
      // this.disableBtn()
    },
    putcash(p) {
      this.percent = p
      let e = this.percent * this.cash
      this.amount = this.$toLocal(e, this.decimal)
      this.calculate()
    },
    setMin() {
      if (this.limit) {
        this.unitPrice = this.$toLocal(this.state.shopInfo.maxBuy, this.tradeTo === 'TOMAN' ? 0 : this.$prcision[this.tradeFrom])
        this.danger = false
        this.calculate()
      }
    },
    localCalculate() {
      const up = this.$S2N(this.unitPrice)
      const a = this.$S2N(this.amount)
      this.totalPrice = this.$toLocal((up * a), this.$decimal[this.tradeTo])
      this.percent = this.$S2N(this.amount) / this.cash
    },
    async serverCalculate() {
      this.loop ? this.loop.stop() : ''
      this.calculating = true
      const a = this.amount
      await this.$sleep(500)
      if (a === this.amount) {
        this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceSell')
        this.loop.start()
      }
    },
    async getPriceFromServer() {

      let amount = this.$S2N(this.amount);
      console.log(amount)
      const [res] = await this.$http.get("/orders/market-buy-sell-whole", {
        params: {
          amount: amount,
          marketType: this.tradeFrom + "_" + this.tradeTo,
          orderType: "MARKET_SELL",
        },
      });
      if (res) {
        // console.log('response server',res)
        this.totalPrice = this.$toLocal(
            res.baseDTO.wholePrice,
            this.precision
        );
        let totalPrice = this.$S2N(this.totalPrice)
        console.log('totalprice', totalPrice / amount)
        this.unitPrice = this.$toLocal(
            this.$S2N(this.totalPrice) / amount,
            this.precision
            // this.tradeTo === "TOMAN" ? 0 : this.$prcision[this.tradeFrom]
        );
        this.percent = this.$S2N(this.totalPrice) / this.cash;
      }
      this.calculating = false;

      console.log('amount', amount)
    },
    calculate() {
      if (this.limit && this.unitPrice && this.amount) {
        this.localCalculate()
      } else if (!this.limit && this.$S2N(this.amount)) {
        this.serverCalculate()
      }
    },
    showmodal() {
      if (!(!this.limit || this.$S2N(this.unitPrice))) {
        this.danger = true
        this.danger1 = false
        this.danger2 = false
      } else if (!this.$S2N(this.amount)) {
        this.danger1 = true
        this.danger2 = false
        this.danger = false
      }
          // else if (this.percent > 1 && !this.state.userInfo.supervisor) {
          //     this.danger2 = true
          //     this.danger1 = false
          //     this.danger = false
      // }
      else {
        this.show = true
        this.danger = false
        this.danger1 = false
        this.danger2 = false
      }
    },
    async getCoins() {
      this.state.loading = true
      const res = await this.$axios.get('/coins/details')
      this.coins = res.content
      this.minAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).minAmount
      this.maxAmountOrder = this.coins.find(a => a.coin == this.tradeFrom).maxAmount
    },
  },
  async mounted() {
    this.getCoins()
    this.getDetail()
  }
}
</script>

<style lang="scss" scoped>
.cont {
  gap: 1rem;
}

//transform selector
.active {
  color: #468dff;
}

.trans {
  transform: rotate(180deg);
  fill: var(--light-blue);
}

.shop {
  padding: clamp(0px, 1vw, 16px);
  display: flex;
  flex-direction: column;
}

.list {
  position: relative;

  button {
    height: 55px;
    background: #13132e;
    border-radius: 0 !important;
    padding: 0 16px;

    span {
      display: flex;
      align-self: center;
      gap: 8px;
      align-items: center;
    }

    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.rtl {
  td:first-child {
    text-align: right;
  }

  td:last-child {
    text-align: left;
  }
}

.ltr {
  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.tr-head {
  td {
    font-size: clamp(9px, 1vw, 11px);
    color: var(--gray);
    border-bottom: 1px solid var(--gray);
    height: 30px;
  }
}

.mt-container {
  z-index: 10;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  top: 55px;
  left: 0;
  padding: 16px;
  background: #201e37;
  width: 100%;
}

.tr-body {
  td:nth-child(2) {
    text-align: center;
  }

  td:first-child {
    cursor: pointer;

    span {
      align-items: center;
      display: flex;
      gap: 8px;
      width: 100px;
      align-self: center;
    }
  }

  td {
    height: 56px;
    font-size: clamp(10px, 1vw, 13px);
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px;
  background: linear-gradient(180deg, #151739 0%, #191b34 100%);
  border: 0.8px solid rgba(83, 79, 79, 0.5);
}

.buttons {
  padding: 6px;
  display: flex;
  justify-content: space-around;
  flex-grow: 2;
  flex-basis: 200px;
  max-width: 286px;
  max-height: 44px;
  background: #585670;
  border-radius: 12px;

  button {
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 36px;
    width: 50%;
  }
}

.buttons-orders {
  display: flex;
  gap: 12px;

  button {
    font-size: clamp(10px, 1vw, 13px);
    max-width: 97px;
    min-width: 44px;
    padding: 0 8px;
    height: 30px;
  }
}

.sell {
  background: #191730;
  border-radius: 12px;
  color: #d01818;
}

.buy {
  background: #191730;
  border-radius: 12px;
  color: #18d04b;
}

.activate {
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active {
  white-space: nowrap;
  font-size: clamp(10px, 1vw, 13px);
  border-radius: 20px;
  border: 1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color: rgba(61, 61, 61, 0.98);
  padding: 0;
}

.input-container {
  label {
    font-size: clamp(10px, 1vw, 13px);
    color: #fff1f1;
  }
}

.input-norm {
  span {
    font-size: clamp(10px, 1vw, 13px);
    color: var(--gray);
  }

  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  background: #131330;
  align-items: center;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;

  input {
    width: 50%;
  }
}

.range-container {
  display: flex;
  flex-direction: column;
  gap: 2px;

  input {
    width: 100%;
    direction: ltr !important;
  }
}

.precentage {
  display: flex;
  width: 100%;
  justify-content: space-between;
  direction: ltr !important;
  font-size: clamp(10px, 1vw, 13px);
}

.label-cont {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.advanced {
  min-height: 44px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  button {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 46px;
  }

  .advanced-drop {
    //top: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //position: absolute;
    font-size: clamp(10px, 1vw, 13px);
  }
}

.in-out {
  display: flex;
  gap: 27px;
}

.submit {
  font-size: clamp(12px, 1vw, 16px);
}

.active-sell {
  background: #f53d3d;
  border-radius: 12px;
  height: 40px;
}

.active-buy {
  background: linear-gradient(
          91.97deg,
          #4fcd72 0%,
          #13c144 60.3%,
          #6ed08a 107.2%
  );
  border-radius: 12px;
  height: 40px;
}
</style>