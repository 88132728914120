<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal">
        <img
          @click="closeModale"
          class="close-icon"
          src="../../../assets/profile/closeIcon.svg"
          alt=""
        />
        <span class="message-title"> {{state.lan.modals.title }} </span>
        <div class="message">
<p>
  {{state.lan.modals.textga}}
</p>
        </div>
                    <img
                        width="180px"
                        :src="'data:image/png;base64,'+qrCode"
                        alt="">
<InputCode
:loading="false"  v-on:complete="onComplete" @change="onChange"
                    ref="code-input"

/>
        <button class="yes" :disabled=disablebtn @click="submit">{{state.lan.modals.submit}}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import Verification from "../../Tools/Verification";
import InputCode from 'vue-verification-code-input';
export default {
  name: "ExitModal",
    props: ['twoStep', 'qrCode', 'qrLink','mobile'],
  components: { Verification , InputCode },
  data() {
    return {
      close: false,
              disablebtn:true,
                    otp:{
            code: '',
        },
    };
  },
  methods: {
    closeModale() {
      this.$emit("closeModal");
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
                async submit() {
                const a = {
                    'EMAIL': 'ایمیل',
                    'SMS': 'پیامک',
                    'GOOGLE_AUTHENTICATOR': 'Google Authenticator'
                }
                if (this.otp.code.toString().length === 6) {
                    if (this.$route.name !== 'Wallet') {
                        this.state.loading = true
                        const [res,] = await this.$http.post(`/users/change-otp?twoStepAuthorization=GOOGLE_AUTHENTICATOR&code=${this.otp.code}`)
                        if(res){
                            this.reset()
                            this.$emit('close')
                            this.$emit('submit', 'شناسایی دو مرحله ای شما به ' + a['GOOGLE_AUTHENTICATOR'] + ' تغییر یافت.')
                        }
                    } else {
                        this.$emit('submit', this.otp.code)
                    }
                }
            },
            
                     onChange(e) {
                this.otp.code = e
            },
        onComplete() {
        this.disablebtn=false
        this.submit()
      },
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".modal , .exit")) {
        this.$emit("closeModal", this.close);
      } else {
        return true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal {
  overflow: auto;
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  align-items: center;
  min-width: 367px;
  min-height: 276px;
  background: #191730;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.message-title {
  font-weight: bold;
  font-size: 18px;
  //color: #febc00;
}

.message {
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.yes {
  background: #19196f;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .modal {
    max-height: 60%;
    width: 90%;
    height: 100%;
    min-width: 300px;
  }
}
</style>
<style lang="scss" scoped>
.react-code-input-container {
  direction: ltr !important;
  display: flex;
  column-gap: 17px;
  width: unset !important;
  align-self: center;
}

.react-code-input {
  display: flex;
  gap: 17px;

  input {
    color:white !important;
    border: 1px solid rgba(178, 176, 176, 0.5) !important;
    border-radius: 4px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    &:focus{
      border: 1px solid var(--light-blue) !important;
    }

  }
}

@media(max-width: 403px) {
  .otp{
    width: 90% !important;
    min-width: unset !important;
  }
  .react-code-input {
    gap: 5px;
  }
}
</style>