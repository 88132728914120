<template>
  <div class="blog">
    <p class="title">{{state.lan.blog.title}}</p>
    <div class="cards">

 <div class="card">
   <div class="fav">
     <p>{{state.lan.blog.fav}}</p>
     <img src="../../assets/Public/blog-fav.png" alt="">
   </div>
   <img src="../../assets/Public/blog.png" alt="">
   <p class="date">{{state.lan.blog.carddate}}</p>
<p class="card-title">{{state.lan.blog.cardtitle}}</p>
<p class="text">
  {{state.lan.blog.cardtext}}
</p>
   <button>{{state.lan.blog.cardbtn}}</button>
 </div>
      <div class="card">
        <div class="fav">
          <p>{{state.lan.blog.fav}}</p>
          <img src="../../assets/Public/blog-fav.png" alt="">
        </div>
        <img src="https://radindev.com/wp-content/uploads/2022/07/COVER.svg" alt="">
        <p class="date">{{state.lan.blog.carddate}}</p>
        <p class="card-title">{{state.lan.blog.cardtitle2}}</p>
        <p class="text">
          {{state.lan.blog.cardtext2}}
        </p>
        <button>{{state.lan.blog.cardbtn}}</button>
      </div>
      <div class="card">
        <div class="fav">
          <p>{{state.lan.blog.fav}}</p>
          <img src="../../assets/Public/blog-fav.png" alt="">
        </div>
        <img src="https://radindev.com/wp-content/uploads/2022/06/binance-clone-github-featured11-1.svg" alt="">
        <p class="date">{{state.lan.blog.carddate}}</p>
        <p class="card-title">{{state.lan.blog.cardtitle3}}</p>
        <p class="text">
          {{state.lan.blog.cardtext3}}
        </p>
        <button>{{state.lan.blog.cardbtn}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
//main container of blog
.blog{
display: flex;
  flex-direction: column;
  gap: 32px;
}
//title of blog
.title{
  font-size: clamp(18px,2vw,24px);
}
//blog cards
.cards{
  display: flex;
  gap:24px;
  //flex-wrap: wrap;
}
.card{
 flex: 1;
  gap: 8px;
  position: relative;

  display: flex;
  flex-direction: column;
  background: #191730;
  border-radius: 12px;
  padding: 16px;
  //date of card
.date , .text{
  color: var(--gray);
  font-size: clamp(10px,1vw,13px);
}

}



//favorite number of card
.fav{
  position: absolute;
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: flex-end;
  background: linear-gradient(115.97deg, rgba(117, 121, 197, 0.57) 0%, #212135 106.55%);
  border-radius: 3px;
  padding: 4px 8px;
  left: 25px;
  top: 25px;
}

//title of cards
.card-title{
  p{
    font-size: clamp(12px,2.5vw,16px);
    font-weight: 700;
  }
}

button{
  justify-content: flex-end;
  background: linear-gradient(115.97deg, rgba(117, 121, 197, 0.57) 0%, #212135 106.55%);
  border-radius: 3px;
  max-width: 95px;
  position: absolute;
      bottom: 10px;
    right: 10px;
}

@media(max-width: 1200px){
  .blog{
    padding: 10px;
  }
  .cards{
    flex-wrap: wrap;

  }
  .card{
    margin: 0 auto;
    width: 90%;

  }
}

//@media(max-width: 400px){
//  .card{
//    margin: 0 auto;
//    max-width: 280px;
//  }
//}
</style>