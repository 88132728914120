  <template>
<!--we should pass the key here for updating languages-->

  <div :key="state.lan + ''" id="app" :dir="state.lang === 'fa'? 'rtl' : 'ltr'">
    <loading-modal v-if="state.loading"/>
    <navbar @profdrop="profDrop=false" />
    <transition enter-active-class="animate__animated animate__fadeIn" leave-active-class="animate__animated animate__fadeOut">
    <router-view/>
    </transition>
  </div>
</template>

<script>
import Navbar from "./components/Public/Navbar.vue";
import LoadingModal from './LoadingModal.vue';

export default {
  components: {
    Navbar,
    LoadingModal,
  },
  data(){
    return{

    }
  }


};
</script>
<style lang="scss"></style>
