<template>
  <div class="fees">
    <div class="title">
      <p>{{ state.lan.fees.title }}</p>
    </div>
    <div class="content">
      <div class="view">
        <p>{{ state.lan.fees.firsttitle }}</p>
        <div class="level-container">
          <p>{{ state.lan.fees.tabletext }}</p>
          <div class="level-table">
            <table :class="{'rtl' : state.lang ==='fa' , 'ltr': state.lang === 'en'}">

              <tr class="tr-head">
                <td>{{ state.lan.fees.tablelevel }}</td>
                <td>{{ state.lan.fees.tablelevela }}</td>
                <td>{{ state.lan.fees.tablelevelwage }}</td>
              </tr>


              <tr class="tr-body" v-for="(item,index) in level" :key="index">
                <td class="text-center">{{ state.lang === 'fa' ? `سطح ${$levels[item.userAccountLevel]} ` : item.userAccountLevel.toLowerCase() }}</td>
                <td class="text-center">{{ state.lang === 'fa' ? `بیشتر از ${$toLocal(item.tradeAmount,$decimal['TOMAN'])}تومان` : `More Than ${$toLocal(item.tradeAmount,$decimal['TOMAN'])} $` }}</td>
                <td class="text-center">%{{ $toPrecision(item.wagePercent,2) }}</td>
              </tr>

            </table>
          </div>

        </div>
        <div class="wages-container">
          <p class="title-text">
            {{ state.lan.fees.secondtitle }}
          </p>
          <p>
            {{ state.lan.fees.secondtext }}
          </p>
          <div class="wage-table">
            <table :class="{'rtl' : state.lang ==='fa' , 'ltr': state.lang === 'en'}">

              <tr class="tr-head">
                <td>{{ state.lan.fees.type }}</td>
                <td> {{ state.lan.fees.min }}</td>
                <td> {{ state.lan.fees.wage }}</td>
              </tr>


              <tr class="tr-body" v-for="(item,index) in detail" :key="index">
                <td class="text-center">{{ state.lang === 'fa' ? $coinLabel[item.coin] : $coinUnit[item.coin] }}</td>
                <td class="text-center">{{ $toLocal(item.minWithdrawAmount) }}<span class="codes">{{item.code}}</span></td>
                <td class="text-center">{{ item.transferFee }}<span class="codes" >{{item.code}}</span></td>
              </tr>


            </table>
          </div>


        </div>
        <div class="rial-trans">
          <p class="title-text">{{ state.lan.fees.rialtitle }}</p>
          <p>{{ state.lan.fees.rialtext }}</p>
        </div>
      </div>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import FooterComponent from "@/components/Public/Footer";

export default {
  name: "Fees",
  components: {FooterComponent},
  data() {
    return {
      level:[],
      detail:[],
      levels: [
        {
          level: 'Bronze',
          amount: ['کمتر از ۲۰ میلیون تومان',
            'less than 1000$'],
          wage: '0.4%',
        },
        {
          level: 'Silver',
          amount: ['کمتر از 50 میلیون تومان'
            ,
            'less than 2000$'],
          wage: '0.4%',
        },
        {
          level: 'Gold',
          amount: ['کمتر از 100 میلیون تومان'
            ,
            'less than 3000$'],
          wage: '0.4%',
        },
        {
          level: 'VIP',
          amount: ['بیشتر از500 میلیون تومان'
            ,
            'less than 4000$'],
          wage: '0.4%',
        },


      ],
      wages: [

        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },
        {
          coin: 'bitcoin',
          amount: '0.1BTC',
          wage: '0.1BTC',
        },

      ]
    }
  },
  methods:{
    async getLevels(){
      const levels = await this.$axios('/users/account-levels')
      this.level = levels

    },
    async getCoinsDetails(){
      const details = await this.$axios('/coins')
      console.log(details)
      this.detail = details.content

    }
  },
mounted() {
    this.getLevels()
  this.getCoinsDetails()
}
}
</script>

<style lang="scss" scoped>
.codes{

    margin: 0 10px;

}
.title {
  position: relative;
  display: flex;
  padding: 60px;
  justify-content: center;
  //align-items: center;

  background: linear-gradient(115.97deg, #181A48 0%, #090A29 106.55%);
  min-height: 240px;

  z-index: 2;

  p {
    font-size: clamp(20px, 1vw, 24px);
  }

}

.title::before {
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../../assets/Public/bubble.svg");

  position: absolute;
  //background-color: red;
  background-size: cover;
  z-index: -2;
}

.content {
  margin-top: clamp(12px, 1vw, 24px);
  margin-bottom: clamp(123px, 1vw, 20px)
}

.title-text {
  font-size: clamp(20px, 1vw, 24px) !important;
}

.view {

  display: flex;
  flex-direction: column;
  gap: 64px;

  p {
    font-size: clamp(14px, 1vw, 18px);
  }

  p:first-child {
    max-width: 886px;
  }
}

.rtl {
  td:first-child {
    text-align: right;
  }

  td:last-child {
    text-align: left;
  }

}

.ltr {
  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }

}


.level-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  table {
    background: #191730;
    border-radius: 12px;
    padding: 20px 10px;
    table-layout: fixed;
    text-align: center;
    min-width: 100%;
    //border-collapse: collapse;

  }

  td {

    font-size: clamp(12px, 1vw, 16px);
    font-weight: 400;
  }

  //td:first-child{
  //  text-align: right;
  //}
  //td:last-child{
  //  text-align: left;
  //}

  //thead
  .tr-head {
    line-height: 50px;
    //border-bottom: 1px  #787878 solid;
    td {
      //font-size: clamp(10px,1vw,13px) !important;
      //color: var(--gray);
      p {
        font-size: clamp(12px, 1vw, 16px);
      }
    }

  }

  //tbody
  .tr-body {
    height: 55px;
   td{

   }
  }

  //.tr-body:first-child{
  //  line-height: 50px;
  //}

}

.wages-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  table {
    background: #191730;
    border-radius: 12px;
    padding: 20px 10px;
    table-layout: fixed;
    text-align: center;
    min-width: 100%;
    //border-collapse: collapse;

  }

  td {

    font-size: clamp(12px, 1vw, 16px);
    font-weight: 400;
  }

  //thead
  .tr-head {
    background: #191730;
    line-height: 50px;
    //border-bottom: 1px  #787878 solid;
    td {
      font-size: clamp(10px, 1vw, 13px) !important;
      color: var(--gray);

      p {
        font-size: clamp(12px, 1vw, 16px);
      }
    }

  }

  //tbody
  .tr-body {
    height: 55px;
  }
}

.rial-trans {
  display: flex;
  flex-direction: column;

}

@media(max-width: 600px){
  .content{
    width: 90%;
    margin: 20px auto;
  }
}
</style>