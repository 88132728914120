<template>
  <div class="invite">
    <div class="view"><invitefriends :Code="refferalsCode" :Link="reffreals" /></div>
    <div class="stats"><stats :friends="invited" :rewards="profit" /></div>
  </div>
</template>

<script>
import Invitefriends from "./Invitefriends.vue";
import Stats from "./stats.vue";
export default {
  components: { Invitefriends, Stats },
  name: "Invite",
  data() {
    return {
      refferalsCode:'',
      reffreals:'https://www.coinex.com/register?refer_code=',
      invited:'',
      profit:'',
    };
  },
  methods:{
        async getCode(){
      const res = await this.$axios('/users/invites')
      console.table(res);
      this.refferalsCode=res.baseDTO.referralCode
      this.reffreals = this.reffreals+ this.refferalsCode
      this.invited = res.baseDTO.countInvitees
      this.profit = res.baseDTO.profit

    },
  },
  mounted(){
   this.getCode()
  }
};
</script>

<style lang="scss" scoped>
.invite {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
</style>
