<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" @click.self="closeModale">
      <div class="modal">
        <img
          @click="closeModale"
          class="close-icon"
          src="../../../assets/profile/closeIcon.svg"
          alt=""
        />
        <span class="message-title">
          {{ state.lan.trade.orders.cancelorder }}
        </span>
        <div class="message">
          <p>
            {{ state.lan.trade.orders.warning }}
          </p>
        </div>

        <button class="yes" @click.prevent="cancelOrder()">
          {{ state.lan.modals.submit }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import Verification from "../../Tools/Verification";
export default {
  name: "ExitModal",
  components: { Verification },
  props:['id'],
  data() {
    return {
      close: false,

    };
  },
  methods: {
    closeModale() {
      this.$emit("closeModal");
    },
    logOut() {
      this.$logout();
      // this.$router.push('/login')
    },
    async cancelOrder(){
let id = this.id
      let fa = this.state.lang ==='fa'
      const res =await this.$axios.delete(`/orders/${id}`)
  console.log(res.message)
      if(res.message =='deleting of order was successful'){


fa? this.$error( 'تراکنش با موفقیت لغو شد'):this.$error('Order successfully canceled')






        this.$emit('closeModal');
      }


    }
  },
};
</script>

<style lang="scss" scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal {
  display: flex;
  row-gap: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 367px;
  min-height: 276px;
  background: #191730;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}
.close-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.message-title {
  font-weight: bold;
  font-size: 18px;
  //color: #febc00;
}

.message {
  max-width: 340px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.yes {
  background: red;
  color: white;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 400px) {
  .modal {
    width: 90%;
    min-width: 300px;
  }
}
</style>
<style lang="scss" scoped>
.react-code-input-container {
  direction: ltr !important;
  display: flex;
  column-gap: 17px;
  width: unset !important;
  align-self: center;
}

.react-code-input {
  display: flex;
  gap: 17px;

  input {
    color: white !important;
    border: 1px solid rgba(178, 176, 176, 0.5) !important;
    border-radius: 4px !important;
    max-width: 35px !important;
    max-height: 35px !important;
    &:focus {
      border: 1px solid var(--light-blue) !important;
    }
  }
}

@media (max-width: 403px) {
  .otp {
    width: 90% !important;
    min-width: unset !important;
  }
  .react-code-input {
    gap: 5px;
  }
}
</style>
