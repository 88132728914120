<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
      <h4 class="green" v-if="buy">
        {{

          state.lang === 'fa' ? 'خرید' + ' ' + $coinLabel[tradeTo] : `buy  ${tradeTo}`

        }}

      </h4>

      <h4 class="red" v-if="!buy">
        {{

          state.lang === 'fa' ? 'فروش' + ' ' + $coinLabel[tradeTo] : `فروش  ${tradeTo}`

        }}

      </h4>


      <div class="d-flex flex-col cont small w100">

        <div class="d-flex justify-space-between w100">
          <span>{{ state.lang === 'fa' ? "مقدار:" : "amount:" }}</span>
          <span>{{ amount }}</span>
        </div>
        <div class="d-flex justify-space-between">
          <span>{{ state.lang === 'fa' ? "قیمت کل:" : "total price:" }}</span>
          <span>{{ totalPrice }}</span>
        </div>
        <div class="d-flex justify-space-between" v-if="date">
          <span>{{ state.lang === 'fa' ? ":انقضا" : "Expire Date:" }}</span>
          <span>{{ state.lang == 'fa' ? $G2J(date) : (date) }}</span>
        </div>

      </div>
      <div class="d-flex buttons w100">
        <button class="active-sell" @click.prevent="$emit('close')">{{
            state.lang === 'fa' ? 'انصراف' : 'back'
          }}
        </button>
        <button @click.prevent="accept" class="active-buy">{{ state.lang === 'fa' ? 'تایید' : 'Confirm' }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {tradeTo, tradeFrom} from '@/library/reuseableFunction'

export default {
  name: "placeOrderModal",
  data() {
    return {
type:'BUY'
    }
  },
  props: {
    limit: {
      type: Boolean,
      default: true,
    },
    amount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: "",
    },
    buy: {
      type: Boolean,
      default: true,
    },
    unitPrice:{
      type: Number,
      default: 0,
    },


  },
  computed: {
    tradeTo, tradeFrom
  },
  methods: {

    async accept() {
      let fa = this.state.lang==='fa'
this.buy ? this.type='BUY' : this.type='SELL'
      let orderDetail;
      let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

      if (this.limit) {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `LIMITED_${this.type}`,
          expiresAt: this.date || null,
          unitPrice: this.$A2N(this.unitPrice),
          wholePrice: this.$S2N(this.totalPrice),
        }


        console.log(orderDetail.orderType)



      } else {
        orderDetail = {
          marketType: marketType,
          amount: this.$S2N(this.amount),
          orderType: `MARKET_${this.type}`,
        }
      }
      try {
        this.state.loading = true;
        await this.$axios.post(`/orders/${this.type.toLowerCase()}`, orderDetail, {
          params: {
            force: true
          }
        })
        this.loading = false
        this.$emit('close')
        this.state.loading = false;
        fa ? this.$error('', 'سفارش با موفقیت ثبت شد', 'success') : this.$error('', 'order successfully registered', 'success')
        ++this.state.orderChange
        console.log(this.state.orderChange)
      } catch (error) {
        this.loading = false
        this.$emit('close')
        if (error.response.data.message) {
          //  === "the amount of order is bigger than market liquidity"
          // await this.$error('', 'حجم درخواستی بیش از مقدار بازار است.')
          this.$error('', error.response.data.message)
        }
      }
      this.state.loading = false;
    },

  }

}
</script>

<style lang="scss" scoped>

.cont {
  gap: 50px;
}


.active-buy {
  background: linear-gradient(
          91.97deg,
          #4fcd72 0%,
          #13c144 60.3%,
          #6ed08a 107.2%
  );
  border-radius: 12px;
  height: 40px;
}

.active-sell {
  background: #f53d3d;
  border-radius: 12px;
  height: 40px;
}

.buttons {
  gap: 20px;

  button {
    flex: 1 1 150px;
  }
}

</style>