<template>
  <div class="auth-container">
    <login v-if="$route.name === 'Login'" />
    <Singup v-if="$route.name === 'Signup'" />
    <OTP v-if="$route.name === 'OTP'" />
    <OTPMail v-if="$route.name === 'OTPMobile'" />
    <Forget v-if="$route.name === 'Forget'" />
    <Newpass v-if="$route.name === 'Newpass'" />
    <SignupOTP v-if="$route.name === 'SignupOTP'" />
  </div>
</template>

<script>
import SignupOTP from "@/components/Authentication/SignupOTP";
import Login from "@/components/Authentication/Login";
import Singup from "@/components/Authentication/Singup";
import OTP from "@/components/Authentication/OTP";
import OTPMail from "@/components/Authentication/OTPMobile";
import Forget from "@/components/Authentication/Forget";
import Newpass from "@/components/Authentication/Newpass";

export default {
  name: "Authentication",
  components: { Newpass, Forget, OTPMail, OTP, Singup, Login, SignupOTP },
};
</script>

<style lang="scss" scoped>
.auth-container {
  padding-top: 32px;
  display: flex;
  justify-content: center;
}
</style>