export default {
  nav: require("./nav.json"),
  footer: require("./footer.json"),
  login: require("./login.json"),
  signup: require("./signup.json"),
  otp: require("./otp.json"),
  otpmobile: require("./otpmobile.json"),
  forget: require("./forget.json"),
  newpass: require("./newpass.json"),
  banner: require("./home/banner.json"),
  homesup: require("./home/support.json"),
  activeusr: require("./home/activeusers.json"),
  vol: require("./home/vol.json"),
  marketv: require("./home/marketview.json"),
  marketvcoin: require("./home/coin.json"),
  blog: require("./home/blog.json"),
  join: require("./home/joinnow.json"),
  terms: require("./public/terms.json"),
  aboutus: require("./public/aboutus.json"),
  coinlist: require("./public/coinlist.json"),
  fees: require("./public/fees.json"),
  guide: require("./public/guide.json"),
  market: require("./public/market.json"),
  side: require("./profile/sidebar.json"),
  dash: require("./profile/dashboard.json"),
  settings: require("./profile/settings.json"),
  notif: require("./profile/notif.json"),
  modals: require("./profile/modals.json"),
  account: require("./profile/account.json"),
  intive: require("./profile/invite.json"),
  history: require("./profile/history.json"),
  wallet:require('./profile/wallet.json'),
  kyc:require('./kyc.json'),
  trade:require('./profile/trade.json')
};
