<template>
  <div>
    <div
      :class="{
        'mobile-btn': state.lang === 'fa',
        'mobile-btn-rtl': state.lang === 'en',
      }"
      @click="mobiles = !mobiles"
      v-if="!mobiles"
    >
      <img src="../../assets/profile/arrow-left-circle.svg" alt="" />
    </div>
    <div class="sidebar">
      <p class="title link-title">{{ state.lan.side.title }}</p>

      <div class="list">
        <a
          href=""
          @click.prevent="$router.push('/profile')"
          :class="{
            active: $route.name === 'Dashboard' && state.lang === 'fa',
            'active-ltr': $route.name === 'Dashboard' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Dashboard'"
          />

          <img src="../../assets/profile/menu.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.dashboard }}</span
          ></a
        >
        <a
          href=""
          @click.prevent="$router.push('/profile/account')"
          :class="{
            active: $route.name === 'Account' && state.lang === 'fa',
            'active-ltr': $route.name === 'Account' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Account'"
          />

          <img src="../../assets/profile/profile.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.profile }}</span
          ></a
        >
        <a
          href=""
          @click.prevent="wallet = !wallet && $router.push('/profile/wallet')"
          :class="{
            active:
              state.lang === 'fa' &&
              ($route.name == 'Wallet' ||
                $route.name == 'CryptoDiposit' ||
                $route.name == 'CryptoWithdrawl'),
            'active-ltr':
              state.lang === 'en' &&
              ($route.name == 'Wallet' ||
                $route.name == 'CryptoDiposit' ||
                $route.name == 'CryptoWithdrawl'),
          }"
          ><inline-svg
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Wallet'"
            class="deactivate sepre"
          />
          <img src="../../assets/profile/wallet.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.wallet }}</span
          ></a
        >
        <div class="wallet-list" v-if="wallet">
          <a href="" @click.prevent="$router.push('/profile/wallet')">
            <inline-svg
              class="deactivate"
              :src="require('../../assets/profile/seprator.svg')"
              width="5px"
              height="40px"
              :class="{ activate: $route.name === 'Wallet' }"
            />

            <span>{{ state.lan.side.inventory }}</span></a
          >
          <a href="" @click.prevent="$router.push('/profile/crypto-dipo/TETHER')">
            <inline-svg
              class="deactivate"
              :src="require('../../assets/profile/seprator.svg')"
              width="5px"
              height="40px"
              :class="{ activate: $route.name === 'CryptoDiposit' }"
            />

            <span>{{ state.lan.side.diposit }}</span></a
          >
          <a
            href=""
            @click.prevent="$router.push('/profile/crypto-wit/TETHER')"
            :class="{
              active: $route.name === 'CryptoWithdrawl' && state.lang === 'fa',
            }"
          >
            <inline-svg
              :src="require('../../assets/profile/seprator.svg')"
              width="5px"
              height="40px"
              class="deactivate"
              :class="{ activate: $route.name === 'CryptoWithdrawl' }"
            />
            <span>{{ state.lan.side.withdraw }}</span></a
          >
        </div>

        <a
          href=""
          @click.prevent="$router.push('/profile/history')"
          :class="{
            active: $route.name === 'History' && state.lang === 'fa',
            'active-ltr': $route.name === 'History' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'History'"
          />

          <img src="../../assets/profile/history.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.history }}</span
          ></a
        >
        <a
          href=""
          @click.prevent="$router.push('/profile/invite')"
          :class="{
            active: $route.name === 'Invite' && state.lang === 'fa',
            'active-ltr': $route.name === 'Invite' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Invite'"
          />

          <img src="../../assets/profile/invite.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.invite }}</span
          ></a
        >
        <a
          href=""
          @click.prevent="$router.push('/profile/notif')"
          :class="{
            active: $route.name === 'Notif' && state.lang === 'fa',
            'active-ltr': $route.name === 'Notif' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Notif'"
          />

          <img src="../../assets/profile/notif.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.notif }}</span
          ></a
        >
        <a
          href=""
          @click.prevent="$router.push('/profile/settings')"
          :class="{
            active: $route.name === 'Settings' && state.lang === 'fa',
            'active-ltr': $route.name === 'Settings' && state.lang === 'en',
          }"
        >
          <inline-svg
            class="deactivate"
            :src="require('../../assets/profile/seprator.svg')"
            width="5px"
            height="40px"
            v-if="$route.name !== 'Settings'"
          />

          <img src="../../assets/profile/settings.svg" alt="" /><span
            class="link-title"
            >{{ state.lan.side.settings }}</span
          ></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      wallet: false,
      mobiles: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.deactivate {
  fill: white !important;
}

.activate {
  //svg{
  //  fill: #2850A8 !important;
  //}
  fill: #2850a8 !important;
  //rect{
  //  fill: #2850A8 !important;
  //}
}

.ltr {
  left: 0;
  top: 60px;
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 8px 0;
  background: #2a2b43;
  border-radius: 12px;
  gap: 18px;
}

//mobile btn

.close-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 20px;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  background: #1b1849;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.close-ltr {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  background: #1b1849;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 8px 0;
  background: #2a2b43;
  border-radius: 12px;
  gap: 18px;
}

.mobile-btn,
.mobile-btn-rtl {
  display: none;
  z-index: 20;
}

.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: 8px 0;
  background: #2a2b43;
  border-radius: 12px;
  gap: 18px;
}

.sidebar-mobile {
  overflow: hidden;
  right: 0;
  bottom: 0;
  top: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 8px 0;
  background: #2a2b43;
  border-radius: 12px;
  gap: 18px;
}

//title of sidebar

.active {
  transition: all 0.7s ease;
  .sepre {
    display: none;
  }
  height: 40px;
  background: linear-gradient(
    90deg,
    #2850a8 0%,
    #1b3161 41.85%,
    rgba(32, 38, 52, 0.21) 100%
  );
  border-radius: 24px 0px 0px 24px;
  img {
    margin-right: 10px;
    min-width: clamp(18px, 2vw, 24px) !important;
    min-height: clamp(18px, 2vw, 24px) !important;
  }
}

.active-ltr {
  transition: all 0.7s ease-out;
  .sepre {
    display: none;
  }

  height: 40px;
  background: linear-gradient(
    -90deg,
    #2850a8 0%,
    #1b3161 41.85%,
    rgba(32, 38, 52, 0.21) 100%
  );
  border-radius: 0px 24px 24px 0px;

  img {
    margin-left: 10px;
    min-width: clamp(18px, 2vw, 24px) !important;
    min-height: clamp(18px, 2vw, 24px) !important;
  }
}

.title {
  margin: 10px 15px;
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 600;
}
.list {
  display: flex;
  flex-direction: column;
  a {
    align-items: center;
    gap: clamp(2px, 0.8vw, 19px);

    display: flex;
    img {
      width: clamp(18px, 2vw, 24px);
      height: clamp(18px, 2vw, 24px);
      align-self: center;
    }
    span {
      font-size: clamp(12px, 1vw, 16px);
    }
  }
  row-gap: 24px;
}

.wallet-list {
  display: flex;
  flex-direction: column;
  a {
    align-items: center;
    gap: clamp(2px, 0.8vw, 19px);
    display: flex;
  }
  span {
    font-size: clamp(10px, 1.2vw, 16px);
  }
  row-gap: 24px;
}

@media (max-width: 1154px) {
  .mobile-btn {
    position: sticky;
    right: -10px;
    top: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    z-index: 10 !important;
    //background: #2A2B43;
  }
  .mobile-btn-rtl {
    z-index: 10;
    position: absolute;
    left: -10px;
    top: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 16px;
    border-radius: 50%;
    transform: scale(-1, 1);

    //background: #2A2B43;
  }

  .sidebar {
    display: none;
  }
  .active {
    img {
      margin-right: 15px;
    }
  }

  .active-ltr {
    img {
      margin-left: 15px;
    }
  }

  .sidebar {
    min-width: 50px;
  }
  .link-title {
    display: none;
  }
}

.fade-enter-active {
  transition: all 1s ease;
  //-moz-transform: translate(12px,12px);
  //-webkit-transform: translate(12px, 12px);
  //-o-transform: translate(12px, 12px);
  //-ms-transform: translate(12px, 12px);
  //transform: translate(12px,12px);
}
.fade-leave-active {
  transition: all 1s ease;
  -moz-transform: translate(-12px, 0px);
  -webkit-transform: translate(-12px, 0px);
  -o-transform: translate(-12px, 0px);
  -ms-transform: translate(-12px, 0px);
  transform: translate(-12px, 0px);
}

.fade-enter {
  width: 0;
}

.fade-leave-to {
  width: 200px;
}
</style>
