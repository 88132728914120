<template>
  <div class="main-container">

    <div class="views">
      <div class="action">
       <div class="drops">
        <div class="title">
          <p class="titles">{{state.lan.wallet.diposit.coin.title}}</p>
          <button @click.prevent="drop=!drop" :key="$route.params.target">
            <p>{{state.lan.wallet.diposit.coin.cointype}}</p>
            <p class="coinselect">{{state.lang==='fa' ?$coinLabel[select]:select.toLowerCase().split('_').join(' ')}} <span> <img src="../../../assets/Public/selector.svg" alt="" width="14" height="7"></span></p>
          </button>
          <div class="drop" v-if="drop">
            <p v-for="(item,index) in wallets" :key="index"
            @click.prevent="selectItem(item)"
            >{{state.lang=='fa' ? $coinLabel[item] : item.toLowerCase().split('_').join(' ')}}</p>

          </div>
          <div class="fees"  v-if="!$route.path.includes('TETHER')">
            <p>{{state.lan.wallet.diposit.coin.fee}}</p>
            <p>{{ wage }}{{$coinUnit[$route.params.target]}}</p>
          </div>
        </div>
         <div class="network" v-if="$route.path.includes('TETHER')">
           <p class="nettitle">{{state.lan.wallet.diposit.coin.network}}</p>
           <div class="select-net">
             <div class="buttons" >
                <button @click.prevent="network ='TRC20',getQR()" :class="{'trc' : network==='TRC20'}">TRC20</button>
               <button @click.prevent="network ='ERC20',getQR()" :class="{ 'erc' : network ==='ERC20'}">ERC20</button>
             </div>
             <div class="fees">
              <p>{{state.lan.wallet.diposit.coin.fee}}</p>
            <p>{{ wage }}{{$coinUnit[$route.params.target]}}</p>
             </div>
           </div>
         </div>
       </div>
        <div class="walleta">
          <button class="address" @click.prevent="copyCode">
            <p>{{state.lan.wallet.diposit.coin.adres}}</p>
            <p class="link" >
              {{link.slice(0,10)+'...'}}
              <span>
                <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.248 0H7.16502C5.70039 0 4.50877 1.19162 4.50877 2.65625V2.98225H2.75195C1.28732 2.98225 0.0957031 4.17387 0.0957031 5.6385V21.3439C0.0957031 22.8085 1.28732 24.0001 2.75195 24.0001H12.8349C14.2996 24.0001 15.4911 22.8085 15.4911 21.3439V21.0179H17.2479C18.7125 21.0179 19.9041 19.8262 19.9041 18.3616V2.65625C19.9042 1.19162 18.7126 0 17.248 0ZM13.7778 21.3439C13.7778 21.8637 13.3548 22.2867 12.835 22.2867H2.75195C2.2321 22.2867 1.80912 21.8637 1.80912 21.3439V5.63838C1.80912 5.11853 2.2321 4.69555 2.75195 4.69555H12.8349C13.3548 4.69555 13.7776 5.11853 13.7776 5.63838V21.3439H13.7778ZM18.1908 18.3616C18.1908 18.8815 17.7678 19.3044 17.248 19.3044H15.4912V5.63838C15.4912 4.17376 14.2996 2.98214 12.835 2.98214H6.22218V2.65613C6.22218 2.13628 6.64517 1.7133 7.16502 1.7133H17.248C17.7678 1.7133 18.1908 2.13628 18.1908 2.65613V18.3616Z" fill="#CACACE"/>
</svg>
</span>
            </p>

          </button>
          <button class="qr" @click="isQrCode=true">
            <img src="../../../assets/profile/qrbtn.svg" alt="">
          </button>
          <h4 class="positive" v-if="alertLink">{{state.lan.intive.copied}}</h4>
        </div>
      </div>
      <div class="guide">
<p class="title">{{state.lan.wallet.diposit.coin.guide}}</p>
        <div class="text" v-if="state.lang =='fa'">
<p>منجر به از دست رفتن دارای شما می شود کنید. واریز هر کوین دیگری به این آدرس تنها بیت کوین را به این آدرس واریز

  حداقل مقدار واریز
  {{  $coinLabel[$route.params.target]}}
  {{wage}}{{$coinUnit[$route.params.target]}} می باشد</p>
        </div>




        <div class="text" v-if="state.lang =='en'">
          <p>
            The address is ONLY available for BTC deposit. Deposit of other assets will lead to permanent asset loss.
        Minimum amount per Deposit
            {{wage}}{{$coinUnit[$route.params.target]}}


          </p>
        </div>










      </div>
    </div>
    <div class="table">
      <Crypdipotable  :targetCoin="select" :wage='wage'  :datas='datas' :key='$route.params.target'/>
    </div>
    <QrModal :qrCode="qrCode"  :coinTarget="select" :tag='tag' v-if="isQrCode"  @close="isQrCode= !isQrCode"/>
  </div>
</template>

<script>
import Crypto from "@/library/crypto";
import Crypdipotable from "./tables/Crypdipotable.vue";
import coins from '../../../library/walletcoins'
import QrModal from './qrModal.vue';
export default {

  name: "Cryptodiposit",
  components: {QrModal,Crypdipotable},
  data() {
    return {
      datas: [],
      isQrCode:false,
      network:'TRC20',
      link: '0x33a2270bab203640858c',
      wage:0,
      address:'',
      tag:null,
      qrCode:null,
      trc: true,
      wallets:[],
      erc: false,
      logo: '',
      drop: false,
      selectedid: 0,
      alertLink:false,
      coins,
      // select:this.coins[0].coin,
      select: ''
    }
  },

  methods: {

    selectItem(item) {
this.$router.push(`/profile/crypto-dipo/${item}`)
      this.select = item;
      this.getCoins();
      this.getWalletData()
      this.getQR()
      this.getDepositHistory();
      this.drop = !this.drop;
    },
    async getWalletData() {
      this.state.loading=true;
      const target = this.$route.params.target;
      const res = await this.$axios(`/wallets/customer-addresses`)
      let entireRes = Object.entries(res);
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))




//set all wallets to dropdown
      this.wallets = entireRes.filter(e => e[0] !== "TOMAN")


     let names=  this.wallets.map(e=> e[0])

this.wallets = names;

// console.log('wallets',this.wallets)

    },
    async getCoins(){
const res = await this.$axios('/coins')
let {content:coins} = res
coins = coins.filter(e=> e.coin == this.$route.params.target)[0]
const {transferFee} = coins
this.wage = transferFee


    },
   async getQR(){

         if(this.$route.params.target ==='TETHER'){


          
            const [,res] = await this.$http(`/wallets/customer/wallet-address?relatedCoin=${this.$route.params.target}&tokenType=${this.network}`)
if(res.response.data.message.includes('Enough')){
  await this.$router.push('/profile/wallet')
  console.log('hi')
}

      let {address , tag , path , qrCode} = res.baseDTO
      this.link = address;
      this.qrCode = qrCode;
      this.tag = tag; 

        
         }



 else{
  const res = await this.$axios(`/wallets/customer/wallet-address?relatedCoin=${this.$route.params.target}`)

if(res.response.data.message.includes('Enough')){
  await this.$router.push('/profile/wallet')

}




      let {address , tag , path , qrCode} = res.baseDTO
      this.link = address;
      this.qrCode = qrCode;
      this.tag = tag; 

 }


   },
    copyCode() {
      navigator.clipboard.writeText(this.link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);


    },
    async getDepositHistory(){
        const res = await this.$axios('/wallets/deposit')
        let  {content:deposits} = res;
       deposits = deposits.filter(item=>item.relatedCoin === this.targetCoin)
       this.datas= deposits

    }

  },
  mounted() {
    this.select = this.$route.params.target;
this.getWalletData()
this.getCoins()
this.getQR()
this.getDepositHistory();


  },

}
</script>

<style lang="scss" scoped>
      .fees{
        flex-grow: 1;
        flex-basis: 100px;
        p{
          font-size: clamp(10px,1vw,13px);
          color: var(--gray);
        }
      }
.erc{

  background: #191730;
  border-radius: 12px;
  color: #d01818;
}
.trc{
  background: #191730;
  border-radius: 12px;
  color: #18D04B;
}

.drops{
  display: flex;
  flex-direction: column;
  gap: 32px;

  .network{
    display: flex;
    flex-direction: column;
    gap:14px ;
    .nettitle{
      color: var(--gray);
    }
    .select-net{
      flex-wrap: wrap;
      display: flex;
      gap: 50px ;
      .buttons{
        padding: 6px;
        display: flex;
        justify-content: space-around;
        flex-grow: 2;
        flex-basis: 200px;
        max-width: 286px;
        height: 44px;
        background: #585670;
        border-radius: 12px;
button{
  transition: linear 700ms;
  align-self: center;
  height: 36px;
  width: 50%;
}
      }

    }
  }
}



//main container is here
.main-container{

  display: flex;
  flex-direction: column;
  gap:24px;
}
//views is the container that contains the guide and
.views{
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
 .action,.guide{
   display: flex;
   flex-direction: column;
   background: #191730;
   border-radius: 12px;
   min-height: 375px;
   padding: 24px;
 }
  .action{
    flex-grow: 2.7;
flex-basis: 270px;

  }
  .guide{
    min-width: clamp(230px,1vw,267px);
    flex-grow:1.13;
    flex-basis: 113px;
  }
}
.drop{
  width: 290px;
  max-height: 200px;
  overflow: auto;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  //width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 120px;
  p{
    //width: 280px;
    display: flex;
    justify-content: space-between;
    font-size: clamp(10px,1vw,14px);
  }
}
.action{
  gap: 64px;
  .drops{
    display: flex;
    flex-direction: column;
    .title{
      position: relative;
      button{
        background: #131330;
        border: 0.6px solid rgba(179, 176, 176, 0.5);
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        width: 286px;
        height: 44px;
        padding:8px 12px ;
      }
      display: flex;
      flex-direction: column;
      gap: 16px;
      .titles{
        font-size:clamp(18px,2vw,24px) ;
        color:#FFF1F1 ;

      }
    }
  }
}
.coinselect{
  display: flex;
  gap: 10px;
}
.guide{
  display: flex;
  flex-direction: column;
  gap: 32px;
  color: var(--gray);
  .title{
font-size: clamp(16px,1vw,20vw);

  }
  .text{
display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.link{
  flex-wrap: wrap;
  gap: 3px;
  display: flex;
  align-items: center;
  span{
    display: flex;
 svg{
   align-self: center;
 }
  }
}
.walleta{
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.address{
  flex-wrap: wrap;
  width: 90%;
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;

}

.qr{
  width: 5%;
}




@media(max-width: 420px){

  .walleta{
    flex-direction: column;
  }

.address{
height: auto;
}

  .drop{
    top: 100px;
    width: 100% !important;
  }
  .title{
    button{
      width: 100% !important;
    }
  }
}
</style>