<template>
  <div class="market">
    <div class="content">
      <div class="title">
<p @click.prevent="$router.push('/coins')">{{state.lan.market.coinlist}}</p>
        <img src="../../assets/Public/breadrtl.svg" alt="" width="24"
        height="24"
        :class="{'ltr':state.lang ==='en'}"
        >
        <p>{{state.lang=='fa'?$coinLabel[$route.params.CoinName] : $route.params.CoinName.toLowerCase().split('_').join(' ')}}</p>
      </div>
      <div class="cards">
<div class="card">
  <img  :src="`/coins/${$coinUnit[coin.relatedCoin]}.png`"  alt="" :class="{ 'bg':state.lang ==='fa' , 'bg-ltr': state.lang === 'en' }" >
  <div class="first-col">
<div class="titles">
<p>{{state.lang ==='fa'? $coinLabel[$route.params.CoinName] : ($route.params.CoinName)}}<span>{{coin.relatedCoin}}</span></p>
  <a target="_blank" :href="crypto[0].link" class="link" >{{crypto[0].link}} <span><img src="../../assets/Public/link.png" alt=""></span></a>
</div>
    <div class="prices">
    <div class="lp">
      <p>{{$toLocal(coin.lastPrice, $decimal['TOMAN'])}}</p>
<div class="chart" >
<p :class="{'positive':coin.last24HourChange>0 , 'negative':coin.last24HourChange<0}">{{$toLocal(coin.last24HourChange,2)}}%</p>
  <img v-if="coin.last24HourChange>0" src="../../assets/Public/rise.png" alt="">
  <img v-if="coin.last24HourChange<0"  src="../../assets/Public/fall.png" alt="">
</div>
    </div>
      <p>{{$toLocal(coin.lastPrice, $decimal['TETHER'])}}<span>{{state.lan.marketv.teter}}</span></p>
    </div>
    <button @click.prevent="gotoTrade">{{state.lan.market.trade}}</button>
  </div>
  <div class="second-col">
<div class="row-one">
  <p>{{state.lan.marketv.vl}}</p>
  <p>{{$toLocal(coin.last24HourVolume,2)}}<span>{{state.lan.marketv.teter}}</span></p>
</div>
    <div class="row-two">
      <p>{{state.lan.marketv.daily}}</p>
      <p>{{$toLocal(coin.last24HourTrades,2)}}<span>{{state.lan.marketv.teter}}</span></p>
    </div>
  </div>
</div>
        <div class="trw">
          <vue-trading-view
          :key="code"
              style="height: 285px"
              :options="{
              symbol: 'BINANCE:' + code + 'USDT',
              // height:'285px',
              autosize:true,
             theme:'dark',
                      timezone: 'Asia/Tehran',
                      style: '3',
                      hide_top_toolbar:true,
                      hide_legend:false,
                      hide_bottom_toolbar:true,
                      // hide_legend:true,
                      locale: 'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }"
          />
        </div>
      </div>
      <div class="textarea">
<template v-if="state.lang =='fa'">
  {{detail}}
</template>

<template v-if="state.lang!=='fa'">
  {{detail}}
</template>


      </div>
      <div class="faq">
        <p>{{state.lan.market.faq}}</p>
        <div
            class="accordian"
            v-for="(item, index) in faqContent"
            :key="index"
        >
          <button  @click.prevent="activeAccordian(index)">
            {{ item.question }}
          <img src="../../assets/Public/selector.svg" />
          </button>

          <transition name="fade">
            <div class="answer" v-if="activeIndex == index">
              <p>
                {{ item.answer }}
              </p>
            </div>
          </transition>
        </div>
      </div>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import crypto from "@/library/cryptoMarket";
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
import FooterComponent from "@/components/Public/Footer";
import fa from '../../library/multiLang/coin histories/fa.json'
import en from '../../library/multiLang/coin histories/en.json'
export default {
  name: "Market",
  components :{
    FooterComponent,
    VueTradingView
  },
  data(){
    return{
      crypto:crypto,
      activeIndex: null,
      coin :[],
      code:'',
      faqContent:[],
      history : this.state.lan.coinlist.history

    }
  },
  computed:{
    detail(){
let target = this.$route.params.CoinName;
console.log(target)
 console.log(fa[target])
 return this.state.lang=='fa'?fa[target]: en[target]
    }
  },
  methods: {
    activeAccordian(Index) {
      this.activeIndex = this.activeIndex == Index ? null : Index;
    },
    async gotoTrade(){
      const fa = this.state.lang==='fa'
      
fa? this.$router.push(`/trade/TOMAN/${this.$route.params.CoinName}`):this.$router.push(`/trade/TETHER/${this.$route.params.CoinName}`)


    },
    async getCoin(){
      this.state.loading = true;
      const res =await  this.$axios(`/trades/market-info?market_type=${this.$route.params.CoinName}_TETHER&weeklyDataIncluded=false`);
      this.coin = res.content[0];

      this.code = this.$coinUnit[this.coin.relatedCoin]
 
    },
        async getFaq(){
      const res = await this.$axios('/faq')

      this.faqContent = res;
    }
  },
  
  async mounted() {
    await this.getCoin();
    this.getFaq()
  },
  }

</script>

<style lang="scss" scoped>
.trw{
  z-index: 2;
}
.ltr{
  transform: rotate(180deg);
}
//main container
.market{
  display: flex;
  flex-direction: column;
  gap: 26px;
}
//main content container 1200px width
.title{
display: flex;
gap: 8px;
p{
  font-size: clamp(10px,1vw,12px);
  cursor: pointer;
}

  p:last-child{
    color: var(--gray);
    font-weight: 400;
    cursor: linear;
  }

}

.cards{
  display: flex;
  background: #191730;
  border-radius: 12px;
  //flex-wrap: wrap;

  .card{
    width: 50%;
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 25px;
    .bg{
      align-self: center;
      left: -100px;
      position: absolute;
      height: 272px;
      width: 272px;
      opacity: 0.7;
      z-index: 1;
    }

    .bg-ltr{
      align-self: center;
      right: -100px;
      position: absolute;
            height: 272px;
      width: 272px;
      opacity: 0.7;
      z-index: 1;
    }


    .first-col{
display: flex;
      flex-direction: column;
      gap: 16px;
      button{
        background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
        border-radius: 12px;
        height: 40px;
        width: 80%;
      }
      //coins titles
      .titles{
        display: flex;
        flex-direction: column;
        gap: 12px;
p:first-child{
  display: flex;
  font-size: clamp(20px,2vw,24px);
  gap: 8px;
}
        a{

          background: #1B1849;
          border-radius: 37px;
          display: flex;
          gap: 8px;
          span{
            display: flex;
            img{
              align-self: center;
            }
          }
        }

      }
      //prices
      .prices{
        display: flex;
        flex-direction: column;
        gap: 12px;

        p{
          display: flex;
          font-size: clamp(20px,2vw,24px);
          span{
            color: var(--gray);
          }
        }
        //last price
        .lp{
          display: flex;
          width: 100%;
          justify-content: space-between;
          p{
            font-size: clamp(10px,1vw,13px);
          }
        }
        //chart
        .chart{
          p{
            font-size: clamp(12px,1vw,16px);
          }
          display: flex;
        }

      }


    }



.second-col{
  z-index: 2;
  height: 100%;
display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 25px;
  .row-one,.row-two{
    gap: 12px;
  }
}


  }
  .trv{
width: 50%;
  }
}

.content{
  display: flex;
  flex-direction: column;
  margin-top: clamp(12px,1vw,26px);
  margin-bottom: clamp(16px,1vw,32px);
  gap: 26px;
}
//text area

.textarea{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: clamp(8px,1vw,12px);
  background: #191730;
  border-radius: 12px;
}

//faq box
.faq{
  padding: 12px;
  border-radius: 12px;
  background: #191730;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.accordian{
  display: flex;
  flex-direction: column;
  gap: 12px;
  button{
    align-items: center;

    display: flex;
    padding: 0 25px;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;
    background: #1C1A3E;
    border-radius: 4px;
  }
}



#vue-trading-view{
  //max-height: 285px !important;
  border-radius: 12px !important;
  width: 600px !important;
}

@media(max-width: 932px){
  .cards{
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .bg , .bg-ltr{
    display: none;
  }
  #vue-trading-view{
    //max-height: 285px !important;
    width: unset !important;
  }
}

@media(max-width: 600px){
  .faq{
    width: 90%;
    align-self: center;
  }
  .textarea{
    width: 90%;
    align-self: center;
  }
  .cards{
    width: 90%;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card{
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    flex-wrap: wrap;
    //align-self: flex-start;
    .first-col ,.second-col{
      width: 100%;
    }
  }
}



.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>