var Crypto = [
    {
        img: require("@/assets/coins/BTC.png"),
        name: "بیت کوین",
        orgname: "BTC",
        remain: 0.0456,
        variant: 2.5,
        price: 998525368000,
        value: 183625927,
        volume: 78635948037,
        indeal: 0.026,
        max: 1836927000,
        min: 1210826000,
        useable: 0.0826,
        year: 1400,
        mounth: 5,
        day: 12,
        hours: 13,
        minute: 13,
        market: "TRC20",
    },
    {
        img: require("@/assets/coins/ETHEREUM.png"),
        name: "اتریوم",
        orgname: "ETH",
        remain: 0.0456,
        price: 502857488000,
        volume: 78635948037,
        variant: 1.3,
        value: 492867305,
        max: 1836927000,
        min: 1210826000,
        indeal: 0.031,
        useable: 0.0999,
        year: 1400,
        mounth: 11,
        day: 12,
        hours: 13,
        minute: 13,
        market: "TRC20",
    },
    {
        img: require("@/assets/coins/RIPPLE.png"),
        name: "ریپل",
        orgname: "XRP",
        remain: 0.0456,
        price: 998525368000,
        volume: 78635948037,
        variant: -2.5,
        max: 1836927000,
        min: 1210826000,
        value: 183625927,
        indeal: 0.026,
        useable: 0.0826,
        year: 1400,
        mounth: 11,
        day: 12,
        hours: 13,
        minute: 13,
        market: "TRC20",
    },
    {
        img: require("@/assets/coins/LITE_COIN.png"),
        name: "لایت کوین",
        orgname: "LTC",
        remain: 0.0456,
        price: 998525368000,
        volume: 78635948037,
        variant: -2.5,
        value: 183625927,
        max: 1836927000,
        min: 1210826000,
        indeal: 0.026,
        useable: 0.0826,
        year: 1400,
        mounth: 11,
        day: 12,
        hours: 13,
        minute: 13,
        market: "TRC20",
    },

    {
        img: require("@/assets/coins/DOGE_COIN.png"),
        name: "دوج کوین",
        orgname: "DOGE",
        remain: 0.0456,
        variant: 2.5,
        price: 998525368000,
        volume: 78635948037,
        max: 1836927000,
        min: 1210826000,
        value: 183625927,
        indeal: 0.026,
        useable: 0.0826,
        year: 1400,
        mounth: 11,
        day: 12,
        hours: 13,
        minute: 13,
        market: "TRC20",
        // class:'no-border',
    },
];
export default Crypto;
