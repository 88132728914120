<template>
<div class="table-container">
  <p class="title">{{state.lan.wallet.diposit.coin.tabt}}</p>
<table v-if="datas.length !==0">
  <tr class="tr-head">
    <td class="phone ">{{state.lan.wallet.diposit.coin.time}}</td>
    <td class="phone ">{{state.lan.wallet.diposit.coin.coin}}</td>
    <td class="phone ">{{state.lan.wallet.diposit.coin.amount}}</td>
    <td class="phone ">{{state.lan.wallet.diposit.coin.fee}}</td>

    <td>{{state.lan.wallet.diposit.coin.stat}}</td>
    <td  :class="{'rtl-head':state.lang==='fa' , 'ltr-head': state.lang==='en'}" >{{state.lan.wallet.diposit.coin.trans}}</td>
  </tr>
  <tr class="tr-body" v-for="(item,index) in datas" :class="{'rtl':state.lang==='fa' , 'ltr': state.lang==='en'}" :key="index" >
    <td class="phone">{{item.createdAtDateTime}}</td>
    <td class="phone">{{state.lang==='fa'?$coinLabel[item.relatedCoin]:item.relatedCoin}}</td>
    <td class="phone">{{item.amount}}</td>
    <td class="phone">{{wage}}</td>

    <td class="pos" v-if="state.lang=='fa'" :class="{'green':item.isDone,'red':!item.isDone}">{{item.isDone ?'تایید شده' : 'تایید نشده'}}</td>
        <td class="pos" v-if="state.lang!=='fa'" :class="{'green':item.isDone,'red':!item.isDone}">{{item.isDone ?'APPROVED' : 'PENDING'}}</td>
    <td><a :href="item.link">{{state.lan.wallet.diposit.coin.detail}}</a></td>
  </tr>
</table>
<div class="d-flex flex-col justify-center align-items-center red" v-if="datas.length ==0">
  <p v-if="state.lang=='fa'">اطلاعاتی جهت نمایش وجود ندارد</p>
  <p v-if="state.lang!=='fa'">There is No data to Display</p>
</div>
</div>
</template>

<script>

export default {
  name: "Crypdipotable",

  props:['selectedcoin','targetCoin','wage'],
  data(){
  return{
datas:[],
    name:'',
    walletadd:'1N4pWYTL9fx9ZGQs8W',

  }
  },
  methods:{
    async getDepositHistory(){
        const res = await this.$axios('/wallets/deposit')
        let  {content:deposits} = res;
       deposits = deposits.filter(item=>item.relatedCoin === this.targetCoin)
       this.datas= deposits

    },
    
  },
  mounted() {
    this.name = this.datas.find(item => item.coin.toLocaleUpperCase() === this.targetCoin)
this.getDepositHistory()


  }
}
</script>

<style lang="scss" scoped>
table{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  overflow: scroll;
}
table::-webkit-scrollbar{
  display: none;

}
.tr-head{
  position: sticky;
  top: 0;
  background: #191730;
}
.rtl-head{
  text-align: left;
}
.ltr-head{
  text-align: right;
}

.rtl{
  td:last-child{
    text-align: left;
  }
}
.ltr{
  td:last-child{
    text-align: right;
  }
}


.pos{
  color: #18D04B !important;
}

.table-container{
  background: #191730;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title{
color:#FFF1F1;
}

table{
  border-collapse: collapse;
}

.tr-head, .tr-body{
  td{
    border-bottom: 1px solid rgba(255,255,225,0.1);
    height: 46px;
    font-size: clamp(10px,1vw,13px);
    color: var(--gray);
  }

}


.tablet {
}

.phone {
}


@media screen and (max-width: 1100px) {
  .table-container{
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}

</style>