<template>
  <div class="stats">
    <div class="first">
      <p class="title">{{state.lan.intive.stat}}</p>
    </div>
    <div class="second">
      <img src="../../../assets/profile/invited.png" alt="">
      <div class="text-cont"><p>{{state.lan.intive.invited}}</p>
      <p>{{friends}} <span>{{state.lan.intive.amountinv}}</span>  </p>
      </div>
    </div>
    <div class="third">
      <img src="../../../assets/profile/totalref.png" alt="">
      <div class="text-cont"><p>{{state.lan.intive.total}}</p>

        <p>
          <span>{{$toLocal(rewards, $decimal["TOMAN"])}} <span>{{state.lan.intive.price}}</span></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "stats",
  props:['friends','rewards'],
  data() {
    return {
      rewardToman: '',
      rewardUSDT: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.title{
  font-size: clamp(16px,1.2vw,20px);
}
.stats {
  gap: 32px;
  min-height: 163px;
  display: flex;
  padding: 24px;
  background: #191730;
  border-radius: 12px;
  flex-wrap: wrap;
}
.first{
  flex-grow: 0.83;
}
.second,.third{
  flex-grow: 1;
  flex-basis: 100px;
}
.second ,.third{
  display: flex;
  padding: clamp(10px,1.2vw,16px);
  background: linear-gradient(259.85deg, #44466D -3.64%, rgba(76, 157, 231, 0.31) 106.99%);
  border-radius: 12px;
  max-height: 104px;
  width:280px;
  gap: 16px;
}
.second ,.third{
  .text-cont{
    display: flex;
    flex-direction: column;
    gap:12px ;
    p:first-child{
      font-size: clamp(10px,1vw,13px);
      color: var(--gray);
    }
    p:last-child{
      font-size: clamp(16px,2.5vw,20px);
    }

  }
}
</style>
