var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"carosel-container"},[_c('carousel',{attrs:{"rtl":_vm.state.lang == 'fa' ? true : false,"per-page":1,"autoplay":true,"loop":true,"speed":900,"per-page-custom":[
      [320, 1],
      [768, 3],
      [600, 2],
      [1000, 4] ],"autoplay-timeout":5000}},_vm._l((_vm.cryptoList),function(coin,index){return _c('slide',{key:index,staticClass:"main-slide",on:{"click":function($event){return _vm.goto(_vm.$coin2Snake[coin.relatedCoin])}}},[_c('div',{staticClass:"card"},[_c('div',{ref:index,refInFor:true,staticClass:"img-container",attrs:{"id":index}},[_c('img',{attrs:{"src":("/coins/" + (_vm.$coinUnit[coin.relatedCoin]) + ".png"),"alt":coin.relatedCoin,"width":"40","height":"40"}}),(_vm.state.lang === 'fa')?_c('span',[_vm._v(_vm._s(coin.marketType.includes("TOMAN") ? "تومان" : "تتر"))]):_vm._e(),(_vm.state.lang === 'en')?_c('span',[_vm._v(_vm._s(coin.marketType.includes("TOMAN") ? "TMN" : "USDT"))]):_vm._e()]),_c('div',{staticClass:"changes"},[(_vm.state.lang === 'fa')?_c('p',[_vm._v(" "+_vm._s(_vm.$coinLabel[coin.relatedCoin])+" / "+_vm._s(coin.marketType.includes("TOMAN") ? "تومان" : "تتر")+" ")]):_vm._e(),(_vm.state.lang === 'en')?_c('p',[_vm._v(" "+_vm._s(_vm.$coinUnit[coin.relatedCoin])+" / "+_vm._s(coin.marketType.includes("TOMAN") ? "Toman" : "Tether")+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$toLocal(coin.lastPrice, _vm.$decimal["TOMAN"])))]),_c('p',{class:{
              positive: coin.last24HourChange >= 0,
              negative: coin.last24HourChange < 0,
            }},[_vm._v(" % "+_vm._s(_vm.$toPrecision(coin.last24HourChange, 3))+" ")])])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }