<template>
<div class="id-upload">
  <div class="titles">
    <p>{{state.lan.kyc.upid.title}}</p>
    <p>{{state.lan.kyc.upid.instruct}}</p>
  </div>
  <div class="text-samples">
    <p>{{state.lan.kyc.upid.hwtitle}}</p>
    <p class="sample">{{state.lan.kyc.upid.hwsample}}</p>
  </div>
  <div class="uploads">
    <div class="samples">
      <p class="title">{{state.lan.kyc.upid.picsamp}}</p>
      <div class="pictures">
        <img src="../../assets/profile/correctkyc.png" alt="">
        <img src="../../assets/profile/notcorrectkyc.png" alt="">
      </div>
    </div>
    <div class="upload-container">
      <p class="title">{{state.lan.kyc.upid.upplace}}</p>
<form class="container" @submit.prevent="upload" @dragover.prevent @dragenter="hovered=!hovered" @dragleave="hovered=!hovered" @drop.stop.prevent="hovered=false;drop($event);" :style="{borderColor : hovered? 'blue' : ''}" >
  <img v-if="path" class="img" :src="path" alt="" width="280" height="280">
  <img v-else src="../../assets/profile/upload.png" alt="">
  <input type="file" name="picture" id="picture" style="display: none" @change="upload($event.target)"  >
  <label for="picture" class="up-btn">
    {{state.lan.kyc.upid.upbtn}} +
  </label>
</form>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Idupload",
  data() {
    return {
      path: '',
      loadedpicture: '',
      hovered: '',
    }
  },
  watch: {
    'data.image'() {
      if (this.data.image)
          // if (typeof this.data.image === 'string')
        this.path = this.data.image
    }
  },
  props:['data'],
  methods: {
    drop(e) {
      let dt = e.dataTransfer
      this.upload(dt)
    },
    upload(evt) {
      let file = evt.files[0]
      this.data.image = file
      let fileReader = new FileReader();
      fileReader.addEventListener("loadstart", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      })
      fileReader.addEventListener("progress", (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
      });
      fileReader.addEventListener('load', (event) => {
        this.loadedpicture = ((event.loaded / event.total) * 100).toFixed(0);
        this.path = event.target.result
      });
      fileReader.readAsDataURL(file)
    },
    hover() {
      this.hovered = true
    }
  }
}
</script>

<style lang="scss" scoped>
.id-upload{
  padding: 24px;
  background: #191730;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.titles{
  display: flex;
  flex-direction: column;
  gap: 12px;
  p:first-child{
    font-weight: 700;
  }
  p:last-child{
    font-size: clamp(10px,1vw,14px);
    color: var(--gray);
  }

}

.text-samples{
  display: flex;
  flex-direction: column;
  gap: 12px;
  p:first-child{
    font-weight: 700;
  }
  .sample{
    font-size: clamp(14px,1.2vw,18px);
    color: var(--light-blue);
  }

}
.uploads{
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;

  .samples{
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title{
      color: #FFF1F1;
    }
    .pictures{
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }
  .upload-container{
    gap: 12px;

    flex-grow: 2;
    display: flex;
    flex-direction: column;
    .title{
      color: #FFF1F1;
    }
    .container{
      gap: 32.5px ;
      height: 245px;
      padding: 31.5px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, rgba(15, 15, 23, 0) 6.14%, rgba(2, 2, 3, 0.51) 100%);
      border: 3px dashed #A7B3CC;
      box-sizing: border-box;
      border-radius: 12px;
      .up-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: clamp(12px,1vw,16px);
        background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
        border-radius: 12px;
        width: 202px;
        min-height: 40px;
        input{
          display: none;
        }

      }
    }
  }

}

</style>