<template>
  <div class="main-container">
<div class="views">
  <TomanWithdrawAction/>
</div>
    <div class="table">
      <Tomanwittable/>
    </div>
  </div>
</template>

<script>
import Tomanwittable from "@/components/Profile/Wallet/tables/Tomanwittable";
import TomanWithdrawAction from "@/components/Profile/Wallet/TomanWithdrawAction";
export default {
  name: "Cryptodiposit",
  components: {TomanWithdrawAction, Tomanwittable}
}
</script>

<style lang="scss" scoped>
//main container is here
.main-container{
  display: flex;
  flex-direction: column;
  gap:24px;
}


</style>