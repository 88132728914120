<template>
  <div class="banner">
    <div class="banner-view">
<div class="content">
<div class="banner-text">
  <div class="contents">
    <h4>{{state.lan.banner.title}}</h4>
    <p>{{state.lan.banner.text}}</p>
  </div>
  <button @click.prevent="$router.push('/login')">{{state.lan.banner.btn}}</button>
</div>
  <div :class="{'anime':state.lang==='en', 'anime-rtl':state.lang==='fa'}" >
<!--   <inline-svg :src="require('../../assets/Public/laptop.svg')"/>-->
    <Laptop/>
  </div>
</div>
    </div>
    <!-- <BannerCards /> -->
    <banner-slider/>
  </div>
</template>

<script>
import Laptop from "@/components/Home-page/Laptop";
import BannerSlider from './BannerSlider.vue';
// import BannerCards from "./BannerCards.vue";
export default {
  name: "HomeBanner",
  components: {
    BannerSlider,
    // BannerCards,
    Laptop
  },
};
</script>

<style lang="scss" scoped>

.banner{
  display: flex;
  flex-direction: column;
}
.banner-view{
  width: 100%;
  height: 100%;
  min-height: 542px;
  background-image: url("../../assets/Public/banner-ltr.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.banner-view-rtl{

  width: 100%;
  height: 100%;
  min-height: 542px;


}




//the conatiner has content of banner in it
.content{

  height: 100%;
  min-height: 542px;
  display: flex;
  justify-content: space-between;
}
//container of texts in banner
.banner-text{
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  button{
    width: 187px;
    height: 44px;
    background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
    border-radius: 12px;
  }
  .contents{
    display: flex;
    flex-direction: column;

    align-items: center;
    //title
    h4{
      font-size: clamp(40px,2vw,48px);
      color: var(--light-blue);
      font-weight: 700;
    }
    //text
    p{
font-size: clamp(20px,1vw,24px);
    }
    //start btn

  }
}

//anime
.anime-rtl{
  align-self: center;
  transform: scale(-1, 1);
}

.anime{
  align-self: center;
}
@media (max-width: 700px) {
  .anime{
    display: none;
  }

  .anime-rtl{
    display: none;
  }
  .banner-view{
    padding: 20px;
    min-height: unset;
  }
.content{
  flex-direction: column;
  min-height: unset ;
}
  button{
    align-self: center;
  ;
  }
  
}
</style>
