<template>
  <div class="guide">
    <div class="title">
      <p>{{state.lan.guide.title}}</p>
      <img src="../../assets/Public/helpcenter.png" alt="helpcenetr" width="120" height="120">
    </div>
<div class="content">
  <div class="cards">
    <div class="row">
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.create}}</p>
        <p>{{ state.lan.guide.creattext}}</p>

        <p></p>
      </div>
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.login}}</p>
        <p>{{ state.lan.guide.loginText}}</p>

        <p></p>
      </div>
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.trade}}</p>
        <p>{{ state.lan.guide.tradeText}}</p>

        <p></p>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.support}}</p>
        <p>{{ state.lan.guide.supportText}}</p>

        <p></p>
      </div>
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.aboutus}}</p>
        <p>{{ state.lan.guide.aboutusText}}</p>

        <p></p>
      </div>
      <div class="card">
        <img src="../../assets/Public/helpchat.png" alt="" width="96" height="96">
        <p class="titles">{{ state.lan.guide.terms}}</p>
        <p>{{ state.lan.guide.termsText}}</p>

        <p></p>
      </div>
    </div>

  </div>
  <div class="faq">
    <p>{{state.lan.market.faq}}</p>
    <div
        class="accordian"
        v-for="(item, index) in questions"
        :key="index"
    >
      <button  class="hover" @click.prevent="activeAccordian(index)">
        {{ item.title }}
        <img src="../../assets/Public/selector.svg" />
      </button>

      <transition name="fade">
       <div class="answer" v-if="activeIndex == index">
                <div v-html="item.html">
                  
                </div>
              </div>
      </transition>
    </div>
  </div>
</div>
<footer-component/>
  </div>
</template>

<script>
import crypto from "@/library/cryptoMarket";
import FooterComponent from "@/components/Public/Footer";
export default {
  name: "Guide",
  components: {FooterComponent},
  data(){
    return{
      crypto:crypto,
      questions:[],
      activeIndex: null,
 
    }
  },
  methods: {
   activeAccordian(Index) {
      this.activeIndex = this.activeIndex == Index ? null : Index;

    },
    async getFaq (){
      this.state.loading=true;
 const res = await this.$axios('/user-manual')
//  console.log(res)
 this.questions = res
 this.state.loading=false;

    },
  },
  mounted() {

    this.getFaq()
  },
}
</script>

<style lang="scss" scoped>
.guide{
  display: flex;
  flex-direction: column;
  gap: 88px;
}
.title{
  position: relative;
  display: flex;
  flex-direction: column;
  //padding: 60px;
  gap: 23px;
  justify-content: center;
  align-items: center;
  //align-items: center;

  background: linear-gradient(115.97deg, #181A48 0%, #090A29 106.55%);
  height: 240px;

  z-index: 2;

  p{
    font-size: clamp(20px,1vw,24px);
  }

}
.title::before{
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../../assets/Public/bubble.svg");

  position: absolute;
  //background-color: red;
  background-size: cover;
  z-index: -2;
}

//cards container that has two rows
.content{
  display: flex;
  flex-direction: column;
  margin-top: clamp(12px,1vw,31px);
  margin-bottom: clamp(20px,1vw,144px);
  gap: clamp(30px,4vw,88px);
}
.cards{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .row{
    flex-wrap: wrap;
display: flex;
    gap: 16px;
    //flex-grow: 1;
    //flex-basis: 100px;
    .card{
      //flex-grow: 1;
      //
      //flex-basis: 100px;
      min-width: 357px;
      flex-wrap: wrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px;
      gap: 12px;
      background: linear-gradient(115.97deg, rgba(56, 59, 131, 0.57) 0%, #07092D 106.55%);
      border-radius: 8px;
    .titles{
        font-size: clamp(20px,2vw,24px);
        font-weight: 700;
      }
      p{
        max-width: 325px;
        font-size: clamp(12px,1vw,16px);

      }
    }
  }
}

.faq{
  padding: 12px;
  //border-radius: 12px;
  //background: #191730;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.accordian{
  display: flex;
  flex-direction: column;
  //gap: 24px;

  button{
    align-items: center;
    background: #191730;
    border-radius: 4px;
    display: flex;
    padding: 0 25px;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    //background: #1C1A3E;
    border-radius: 4px;
  }
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@media(max-width: 600px){
  .cards {
    width: 90%;
    align-self: center;
    flex-direction: column;
    align-items: center;
  }
  .card{
    min-width: 90% !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>