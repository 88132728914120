<template>
<div class="join">
  <p >{{state.lan.join.title}}</p>
  <button @click.prevent="$router.push('/sign-up')">{{state.lan.join.btn}}</button>
</div>
</template>

<script>
export default {
  name: "Join"
}
</script>

<style lang="scss" scoped>
.join{
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 156px;
  border-radius: 12px;
  background-image: url("../../assets/Public/joinnow.svg");
}
p{
  font-size: (16px,2vw,20px);
  font-weight: 700;
}
button{
  background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
  border-radius: 12px;
  width: 208px;
  height: 40px;
}
</style>