<template>
  <div class="views ">
    <div class="action relative">
      <div class="small inventory">
  <span>{{state.lang=='fa'? 'موجودی':'inventory'}}</span>
  : 
  <span>{{inventory}}</span>
</div>
      <div class="drops">
        <div class="title">
          <p class="titles">{{ state.lan.wallet.withdraw.coin.title }}</p>
          <div class="buttons-action">
            <button class="coin-type" @click.prevent="drop = !drop">
              <p>{{ state.lan.wallet.diposit.coin.cointype }}</p>
              <p class="coinselect">
                {{ state.lang === "fa" ? $coinLabel[select] : select }}
                <span>
                  <img
                    src="../../../assets/Public/selector.svg"
                    alt=""
                    width="14"
                    height="7"
                /></span>
              </p>
            </button>
            <div class="input-container">
              <input
                type="text"
                :placeholder="state.lan.wallet.withdraw.coin.req"
                v-model="amount"
                @input='amount = $toLocal(amount)'
              />
              <p class="code-holder">
               
                <img src="../../../assets/profile/refresh.svg" alt="" />
              </p>
            </div>
          </div>
          <div class="drop" v-if="drop">
            <p
              v-for="(item, index) in wallets"
              :key="index"
              @click.prevent="selectItem(item)"
            >
              {{
                state.lang == "fa"
                  ? $coinLabel[item]
                  : item.toLowerCase().split("_").join(" ")
              }}
            </p>
          </div>
        </div>


          <div class="fees fee-outside"  v-if="!$route.path.includes('TETHER')">
            <p>{{state.lan.wallet.diposit.coin.fee}}</p>
            <p>{{ wage }}{{$coinUnit[$route.params.target]}}</p>
          </div>



        <div class="network" >
          <p class="nettitle" v-if="$route.params.target ==='TETHER'">{{ state.lan.wallet.diposit.coin.network }}</p>
          <div class="select-net " v-if="$route.params.target=='TETHER'">
             <div class="buttons" >
                <button @click.prevent="network ='TRC20'" :class="{'trc' : network==='TRC20'}">TRC20</button>
               <button @click.prevent="network ='ERC20'" :class="{ 'erc' : network ==='ERC20'}">ERC20</button>
             </div>
        <div class="fees">
              <p>{{state.lan.wallet.diposit.coin.fee}}</p>
            <p>{{ wage }}{{$coinUnit[$route.params.target]}}</p>
             </div>
          </div>
          <input
            class="wadd"
            :placeholder="state.lan.wallet.withdraw.coin.wadd"
            v-model="walletAddress"
          />

          <button :disabled='buttonError' @click="isWithdraw=true" class="sub">{{buttonError ?buttonError:state.lan.wallet.withdraw.coin.sub }}</button>
        </div>
      </div>
    </div>
    <div class="guide">
      <p class="title">{{ state.lan.wallet.withdraw.coin.guide }}</p>
      <div class="text">
        <div class="text" v-if="state.lang == 'fa'">
          <p>
            منجر به از دست رفتن دارای شما می شود کنید. برداشت هر کوین دیگری به
            این آدرس تنها بیت کوین را به این آدرس برداشت حداقل مقدار واریز
            {{ $coinLabel[$route.params.target] }}
            {{ wage }}{{ $coinUnit[$route.params.target] }} می باشد
          </p>
        </div>

        <div class="text" v-if="state.lang == 'en'">
          <p>
            The address is ONLY available for BTC deposit. Deposit of other
            assets will lead to permanent asset loss. Minimum amount per Deposit
            {{ wage }}{{ $coinUnit[$route.params.target] }}
          </p>
        </div>
      </div>
    </div>
<withdraw-modal v-if="isWithdraw" @close='isWithdraw = !isWithdraw' :amount="amount" :walletAddress="walletAddress" :network="network"/>

  </div>
</template>

<script>
import Crypto from "@/library/crypto";
import coins from "@/library/walletcoins";
import WithdrawModal from './withdrawModal.vue';

export default {
  name: "CryptWitAction",
  components: {
    WithdrawModal
  },

  data() {

    return {
      datas: Crypto,
      isWithdraw:false,
      amount:0,
      walletAddress:'',
      wallets: [],
      link: "0x33a2270bab203640858c",
      network: "TRC20",
      logo: "",
      drop: false,
      wage: 0,
      inventory:0,
      alertLink: false,
      coins,
      // select:this.coins[0].coin,
      select: "",
    };
  },
  methods: {

    selectItem(item) {
      this.$router.push('/profile/crypto-wit/' + item);
      this.select = item;
      this.getWalletData();
      this.getCoins();
      this.drop = false;
    },
    copyCode() {
      navigator.clipboard.writeText(this.link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    async getWalletData() {
      this.state.loading = true;
      const target = this.$route.params.target;
      const res = await this.$axios(`/wallets/customer-addresses`);
      let entireRes = Object.entries(res);
      const sortCoins = this.$walletSortCoins;
      entireRes.sort(
        (a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0])
      );

      //set all wallets to dropdown
      this.wallets = entireRes.filter((e) => e[0] !== "TOMAN");

      let names = this.wallets.map((e) => e[0]);

      this.wallets = names;
      this.inventory = res[this.$route.params.target].credit

    },
    async getCoins() {
      const res = await this.$axios("/coins");
      let { content: coins } = res;
      coins = coins.filter((e) => e.coin == this.$route.params.target)[0];
      const { transferFee } = coins;

      this.wage = transferFee;
      this.state.transferFee = transferFee;
    },
  },
  computed:{
buttonError(){
  let error;


 

if(this.state.lang=='fa'){

  if(this.amount == ''){
    error = 'لطفا مقدار را وارد کنید'
  }
  else if(this.walletAddress == ''){
    error = 'لطفا آدرس کیف را وارد کنید'
  }
else if(this.$S2N(this.inventory) < this.$S2N(this.amount)){
error = 'موجودی کافی نیست'
}

  // else if(this.inventory < this.amount){
  //   error = 'موجودی کافی نیست'
  // }



}
if(this.state.lang!=='fa'){


  if(this.amount == ''){
    error = 'Please enter amount'
  }
  else if(this.walletAddress == ''){
    error = 'Please enter wallet address'
  }

  else if($S2N(this.inventory) < $S2N(this.amount)){
    error = 'Insufficient balance'

  }




}

 return error

}
  },
  mounted() {
    this.select = this.$route.params.target;

    this.getWalletData();
    this.getCoins();
  },
};
</script>

<style lang="scss" scoped>
      .fees {
        flex-grow: 1;
        flex-basis: 100px;
        p {
          font-size: clamp(10px, 1vw, 13px);
          color: var(--gray);
        }
      }
      .fee-outside{
        max-height: 40px !important;
      }
.wadd {
  width: 75%;
  display: flex;
  padding: 6px 12px 6px 12px;
  font-size: clamp(12px, 1vw, 16px);
  align-items: center;
  justify-content: space-between;
  background: #131330;
  color: var(--gray);
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  height: 44px;
}
.sub {
  margin-top: 14px;
  align-self: center;
  width: 208px;
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
  border-radius: 12px;
  height: 40px;
}
.coin-type {
  color: var(--gray);
}
.code-holder {
  color: var(--gray);
  display: flex;
  gap: 16px;
}

.buttons-action {
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
}
.views {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  .action,
  .guide {
    display: flex;
    flex-direction: column;
    background: #191730;
    border-radius: 12px;
    min-height: 375px;
    padding: 24px;
  }
  .action {
    flex-grow: 2.7;
    flex-basis: 270px;
  }
  .guide {
    width: 268px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    color: var(--gray);
    .title {
      font-size: clamp(16px, 1vw, 20vw);
    }
    .text {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}
.erc {
  background: #191730;
  border-radius: 12px;
  color: #d01818;
}
.trc {
  background: #191730;
  border-radius: 12px;
  color: #18d04b;
}

.drops {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .network {
    display: flex;
    flex-direction: column;
    gap: 14px;
    .nettitle {
      color: var(--gray);
    }
    .select-net {
      flex-wrap: wrap;
      display: flex;
      gap: 50px;
      .buttons {
        padding: 6px;
        display: flex;
        justify-content: space-around;
        flex-grow: 2;
        flex-basis: 200px;
        max-width: 286px;
        height: 44px;
        background: #585670;
        border-radius: 12px;
        button {
          align-self: center;
          height: 36px;
          width: 50%;
        }
      }

    }
  }
}

.drop {
  width: 290px;
  max-height: 200px;
  overflow: auto;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  //width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: 120px;
  z-index: 19;
  p {
    //width: 280px;
    display: flex;
    justify-content: space-between;
    font-size: clamp(10px, 1vw, 14px);
  }
}

.drops {
  display: flex;
  flex-direction: column;
  .title {
    position: relative;
    button,
    .input-container {
      background: #131330;
      border: 0.6px solid rgba(179, 176, 176, 0.5);
      box-sizing: border-box;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      width: 286px;
      height: 44px;
      padding: 8px 12px;
    }
    .input-container {
      align-items: center;
    }
    display: flex;
    flex-direction: column;
    gap: 16px;
    .titles {
      font-size: clamp(18px, 2vw, 24px);
      color: #fff1f1;
    }
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 420px) {
  .wadd {
    width: 100%;
  }

  .coin-type,
  .input-container {
    width: 95% !important;
  }
  .input-container {
    input {
      width: 100px;
    }
  }
}
</style>