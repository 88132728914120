<template>
<div class="trendChart-parent">
    <TrendChart :key="test" :datasets="data" :grid="{
        verticalLines: true,
        horizontalLines: true,
      }" >
    </TrendChart>
</div>
</template>

<script>
export default {
    name: "TradingChart",
    components: {},
    props: ["trend"],
    data() {
        return {
            data :[{
                data: [],
                smooth: true,
                fill: true,
            }, ],
            test: 0,
            chartData: [5465, 23000, 5987, 5000, 5999,]
        };
    },
    methods: {

    },
   mounted() {
    if (this.trend) {
      for (var i = 0; i < 20; i++) {
        this.chartData[i] = this.trend[i] / 10
      }
      this.data = [{
        data: this.chartData,
        smooth: true,
        fill: true,
      }, ]
      ++this.test
    } else {
      this.chartData = [0, 0, 0, 0, 0]
    }
  },
};
</script>

<style lang="scss">
.trendChart-parent {
    display: flex;
    justify-content: center;
}
.vtc {
    height: 50px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0;
}

.vtc * {
    stroke-width: 3px;
    stroke: #468DFF;

}

.vertical {
    display: none;
}

.curve {
    height: 100%;
    display: flex;
    flex-grow: 1;

}

.curve path:first-of-type {
    display: none;
}
</style>
