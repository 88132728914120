<template>
<div class="support">
  <div class="first-col">
    <div class="amount">
 <p>{{state.lan.homesup.tradeamount}}</p>
      <h3>{{trades}}</h3>
    </div>
    <div class="sup">
      <p>{{state.lan.homesup.sup}}</p>
      <inline-svg :src="require('../../assets/Public/safe-box.svg')" class="headphone" />
    </div>
  </div>
  <div class="second-col">
    <div class="security">
      <img src="../../assets/Public/safe-box.png" alt="" width="65" height="64">
      <p class="title">{{state.lan.homesup.security}}</p>
      <p class="text">{{state.lan.homesup.securitytext}}</p>
    </div>
    <div class="fees">
      <img src="../../assets/Public/nofee.png" alt="" width="51" height="39">
      <p class="title">{{state.lan.homesup.fee}}</p>
      <p class="text">{{state.lan.homesup.feetext}}</p>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Support.vue",
  props:['trades'],
  data() {
    return {
      manage: this.state.lan.homesup,
      tradeVal:12000,

    }
  }
}
</script>

<style lang="scss" scoped>
//main support container
.support{
display: flex;
  gap: 16px;
  flex-wrap: wrap;
}
/*first col contians amount and 24h support
second col contains security and fees*/
.first-col , .second-col {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.first-col{
  h3{
    align-self: center;
  }

}
//amount container
.amount , .sup ,.security ,.fees{
  flex-basis: 186px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg, rgba(255,255,255,0.11) -22.23%, rgba(0, 0, 0, 0.11) 77.8%);
  mix-blend-mode: screen;
  border-radius: 12px;

}
//they have same font-sizes
.sup ,.security ,.fees{
  p{
    font-size: clamp(12px , 1vw ,18px);
  }
}



//security and fees styling
.security , .fees{
  row-gap: 11px;
  .title{
    font-size: clamp(14px,2vw,18px);
  }
  .text{
    font-size: clamp(13px,1vw,10px);
    color: var(--gray);
  }

}

//amount styling
.amount{
  max-width: 186px;
  min-height: 142px;

  h3{
    margin: auto;
  }


  }
//custom height and svg icon fixed
.sup{
  min-height: 196px;
  .headphone{
    align-self: center;
  }
}

//custom height
.fees{
  min-height: 142px;
  height: 142px;
}

//custom height
.security{
  min-height: 196px;
}
//styling the fees




</style>