<template>
<div class="active-users">
<p class="title">{{state.lan.activeusr.active}}</p>
  <div class="amount">
 <p>{{usersCount}}</p>
    <img src="../../assets/Public/activeusers.svg" >
  </div>
</div>
</template>

<script>
export default {
  name: "Actives",
  data(){
    return{

    }
  },
  
props:['usersCount'],
mounted(){
  console.log(this.usersCount)
},
}


</script>

<style lang="scss" scoped>
//main container of active users
.active-users{

  //min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, rgba(255,255,255,0.11) -22.23%, rgba(0, 0, 0, 0.11) 77.8%);
  mix-blend-mode: screen;
  border-radius: 12px;
}
.title{
font-size: clamp(16px,1vw,20px);
}
.amount{
  position: relative;
display: flex;
  align-items: center;
  flex-direction: column;
  p{
    position: absolute;
    font-size: clamp(32px,2vw,36px);
  }
}


</style>