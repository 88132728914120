<template>
<div class="login-his">
  <p class="title">{{state.lan.settings.table}}</p>
  <div class="login">

    <table class="login-table">
      <tr class="tr-head">
        <td class="th">{{ state.lan.settings.time }}</td>
        <td class="th tablet">{{ state.lan.settings.ip }}</td>
        <td class="th tablet">{{ state.lan.settings.type }}</td>
        <td class="th phone">{{ state.lan.settings.plat }}</td>
        <td class="th phone">{{ state.lan.settings.browser }}</td>
      </tr>
<perfect-scrollbar @ps-y-reach-end="check" ref="scroll">
  <tr class="tr-body" v-for="(item, index) in tableContents" :key="index" :class="{'rtl':state.lang==='fa' , 'ltr':state.lang==='en'}">
    <td class="text"> {{ $G2J(item.loginRequestedDateTime, 'faDateTime') }}</td>
    <td class="text tablet">{{ item.ipAddress }}</td>
    <td class="text tablet">{{ item.deviceType }}</td>
    <td class="text phone">{{ item.osType }}</td>
    <td class="text">{{ item.browser }}</td>
  </tr>
</perfect-scrollbar>

    </table>

  </div>
</div>
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  name: "LoginHistory",
  components: {
    InlineSvg,

  },
  data() {
    return {
      lastPage: 1,
      tableContents: [],
      page: 0,
      table: [
        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },
        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

          {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },
        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },

        {
          time: "15:11 - 1386/07/11",
          ip: "185.212.202.27",
          platform: "Desktop",
          os: "Windows 10.0",
          browser: "Chrome 91.0.4472.114",

        },









      ],

    };
  },
  methods: {
    async check() {
      if (this.page <= this.lastPage && !this.loading) { //for scroll loading
        this.loading = true
        this.page++ //for scroll loading
        const [res,] = await this.$http.get('/users/login-history', {
          params: {
            size: 10,
            page: this.page
          }
        })
        if (res) {
          this.lastPage = res.totalPages
          this.tableContents = [...this.tableContents, ...res.content] //for scroll loading
          // this.tableContents = res.content //for pagination
        }
        this.loading = false
      }
    },
  },
  mounted() {
    this.check()

  }
};
</script>

<style lang="scss" scoped >

.rtl{
  td:last-child{
    text-align: left ;
  }
}
.ltr{

  td:last-child{
    text-align: right;
  }
}




.login{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.login::-webkit-scrollbar {
  display: none;
}



.login-his{
  background:#191730;
  border-radius: 16px;
  padding: 20px;
  p{

    font-size: clamp(16px,1vw,20px);
  }
}
.login {
  position: relative;
  overflow: scroll;
  background:#191730;
  border-radius: 16px;
  height: 348px;
max-height: 564px;



    .login-table {
      //overflow: scroll;
      width: 100%;
      padding: 20px;
      max-height: 300px;

      .tr-body {
        //display: block;
        height: 100%;
        max-height: 300px;
        //overflow: scroll;


        .table-container::-webkit-scrollbar{
          display: none;
        }

      }
      .tr-body::-webkit-scrollbar{
        display: none;
      }

      .tr-head, .tr-body  {
        max-height: 300px;
        display: table;
        width: 100%;
        table-layout: fixed;/* even columns width , fix width of table too*/
      }
      .tr-head{
        width: 100%;
        position: sticky;
        top: 0;
        height: 50px !important;

        background: #191730 !important;
        //width: calc( 100% - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
      }
      table {
        width: 400px;
        max-height: 300px;
      }

      .th {
        color: var(--gray);
        font-size: clamp(12px, 1vw, 16px);
      }

      .tr-body {
        //text-align: center;
        //padding: 20px;
        height: 50px;

        .text {
          font-size: clamp(12px, 1vw, 16px);
        }

        .tablet {
        }

        .phone {
        }
      }
    }
  }






@media screen and (max-width: 768px) {
  .tablet {
    display: none;
  }
  @media screen and (max-width: 450px) {
    .phone {
      display: none;
    }
  }
}
</style>
