<template>
  <div class="management">
    <h4>{{state.lan.homesup.title}}</h4>
    <div class="main">
      <Support class="support" :trades="totaltrad" />
      <Actives  :usersCount="userscount"/>
      <div class=""></div>
      <Volume class="vole" :price="tradprice"/>
    </div>
  </div>
</template>

<script>
import Support from '@/components/Home-page/Support';
import Actives from "@/components/Home-page/Actives.vue";
import Volume from "@/components/Home-page/Volume";
export default {
  name:"Management",
  data() {
    return{
       userscount: 0,
      tradprice: 0,
      totaltrad: 0
    }
  },
  components: {Volume, Actives, Support},
  methods:{
    async getInfo(){
      const res = await this.$axios('trades/market-info-home')
      this.userscount = res.baseDTO.userCount
      this.tradprice = res.baseDTO.tradesValue
      
      this.totaltrad = res.baseDTO.tradesAmount
    }
  },
  mounted() {
    this.getInfo()
  }


}
</script>

<style lang="scss" scoped>
//management container
.management{
min-width: 100% !important;
  display: flex;
  flex-direction: column;
  gap: 44px;
}
//management main view that contains three components
.main{
  width: 100%;
  display: flex;
  gap: 16px;
}

.active , .support , .vole{
  flex: 1;
}


@media (max-width: 1000px) {
  .management{
    flex-wrap: wrap;
    padding: 10px;

  }
  .main{
    flex-wrap: wrap;
  }
}
@media(max-width:500px){
  .management{
    padding: 10px;
  }
.main{
  //padding: 10px;
}
}
</style>