import Vue from "vue";
//importing our state

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import './library/state'
import App from "./App.vue";
import './style/style.scss'
import './style/vdp.scss'
import router from "./library/router.js";
import './library/globalFunctions.js'
import InlineSvg from 'vue-inline-svg';
import TrendChart from "vue-trend-chart";
import VueApexCharts from 'vue-apexcharts';
import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
import "./library/georgianToJalali";
import VueCookies from "vue-cookies";
import "sweetalert2/dist/sweetalert2.min.css";
import "./style/SweetAlertStyle.scss";
import VueSweetalert2 from "vue-sweetalert2";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import './library/axios'
import './library/reuseableFunction.js'
import './library/globalFunctions.js'
import './library/globalConstants.js'
import 'animate.css';

//Extra Components are here
Vue.component('inline-svg', InlineSvg);
Vue.use(TrendChart);
Vue.use(VueCookies)
Vue.use(VueSweetalert2)
Vue.use(PerfectScrollbar)
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);
Vue.component('date-picker', VuePersianDatetimePicker);
Vue.config.productionTip = false;


new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
