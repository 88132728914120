<template>
<div class="dash-table">
  <p class="title">{{state.lan.dash.market}}</p>
  <div class="table-container">
<div class="pad">
      <table>
      <tr class="tr-head">
        <td class="tablet">#</td>
        <td>{{state.lan.marketv.coin}}</td>
        <td class="phone">{{state.lan.marketv.lp}}</td>
        <td class="tablet">{{state.lan.marketv.mc}}</td>
        <td>{{state.lan.marketv.change}}</td>
        <td>{{state.lan.marketv.action}}</td>
      </tr>
<tbody>
        <tr class="tr-body hover" v-for="(coin,index) in tableContents" :key="index">
      <td class="tablet">{{index + 1}}</td>
        <td>
          <div class="image-cont">
            <span
              ><img
                :src="`/coins/${$coinUnit[coin.relatedCoin]}.png`"
                :alt="coin.relatedCoin"
                width="32"
                height="32"
            /></span>
            <p v-if="state.lang === 'fa'">
              {{ $coinLabel[coin.relatedCoin] }}
            </p>
            <p v-else>
              {{      $coinUnit[coin.relatedCoin]
                      }}
            </p>
          </div>
        </td>
        <td class="phone">{{ $toLocal(coin.lastPrice, $decimal["TOMAN"]) }}</td>
          <td>{{$toLocal(coin.last24HourVolume, $decimal['TOMAN'])}}</td>
        <td
          :class="{
            positive: coin.last24HourChange >= 0,
            negative: coin.last24HourChange < 0,
          }"
        >
          % {{ $toLocal(coin.last24HourChange, 3) }}
        </td>
        <td><a href="" @click.prevent="goto($coin2Snake[coin.relatedCoin])">{{state.lan.marketv.trade}}</a></td>
      </tr>
</tbody>
    </table>
</div>
  </div>
</div>
</template>

<script>
import crypto from "@/library/crypto";
export default {
  name: "FaTable",
  data(){
    return{
      coins:crypto,
           tableContents: [],
            TomanCoin:
        "market_type=BITCOIN_TOMAN" +
        "&market_type=BITCOIN_CASH_TOMAN" +
        "&market_type=ETHEREUM_TOMAN" +
        "&market_type=LITE_COIN_TOMAN" +
        "&market_type=BINANCE_COIN_BSC_TOMAN" +
        "&market_type=STELLAR_TOMAN" +
        "&market_type=TRON_TOMAN" +
        "&market_type=CELO_TOMAN" +
        "&market_type=RIPPLE_TOMAN" +
        "&market_type=DOGE_COIN_TOMAN" +
        "&market_type=MATIC_TOMAN" +
        "&market_type=TETHER_TOMAN",
    }
  },
  methods: {
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get(
        "/trades/market-info?" + this.TomanCoin,
        {
          params: {
            weeklyDataIncluded: true,
          },
        }
      );
      this.tableContents = res.content;
      // console.log(res.content);
    },
        goto(e) {
      this.$router.push("trade/" + "TOMAN" + `/${e}`);
    },
  },
  mounted() {
    this.getCoins();
  },
}
</script>

<style lang="scss" scoped>
.table-container{
  max-height: 344px;
  overflow-y: scroll;
}
.title{
  font-size:clamp(16px,1vw,20px)
}
.dash-table{
  gap: 16px;
  display: flex;
  flex-direction: column;

}

.pad{
  padding: 20px 24px;
}

.image-cont{
  display: flex;
  gap: 8px;

  align-items: flex-start;
  span{
    display: flex;
 align-self: center;
  }
}

.table-container{
  background: #191730;
  border-radius: 12px;
  
}

//table
table {
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;

}

td{

  font-size: clamp(12px,1vw,16px);
  font-weight: 400;
}

//thead
.tr-head{
  line-height: 50px;
  border-bottom: 1px  #787878 solid;
  position: sticky;
  top: 0;
      background: #191730;
  td {
    font-size: clamp(10px,1vw,13px) !important;
    color: var(--gray);
    p{
      font-size: clamp(12px,1vw,16px);
    }
  }
  td:first-child{
    width: 80px;
  }
}

//tbody
.tr-body{
  height: 55px;
  overflow: scroll;
  max-height: 384px;
}
.tr-body:first-child{
  line-height: 50px;
}






//tablet and phone view
.tablet {
}

.phone {
}


@media screen and (max-width: 1100px) {
  //.table-container{
  //  justify-content: center;
  //  margin: 0 auto;
  //  width: 90%;
  //}
  .tablet {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}


</style>