<template>
<div class="bainfo">
  <DeleteBankModal v-if="opendelet" @close="opendelet=false" @remove="deleteBank" />
  <p>{{state.lan.kyc.binfo.title}}</p>
  <div class="view">
<div class="first-row">
  <input type="text" :placeholder="state.lan.kyc.binfo.cardname"

         v-model="bankAccount.cardNumber"
         @input="dash2" maxlength="16" />
  <input type="text" :placeholder="state.lan.kyc.binfo.shebaname"
         v-model="bankAccount.shebaCode"
         maxlength="24"
         @input="
            bankAccount.shebaCode = bankAccount.shebaCode.replace(
              /[^\d.]|\.\./gi,
              ''
            );">
  <a  href="" @click.prevent="addaccount" >  + {{state.lan.kyc.binfo.add}}</a>

</div>
    <div class="sec-row">

      <div class="forms-add">
        <div class="add-titles">

        </div>
      </div>
      <div class="bank-existing">

        <div class="exist-banks">
        <p>{{state.lan.kyc.binfo.added}}</p>
          <div class="banks-list" v-for="(bank, index) in data.bankAccounts" :key="index">

            <div class="bank-name" id="name">
              <div class="bankname-cont">
                <span>{{state.lan.kyc.binfo.bankname}}</span>
                <span>{{bank.name}}</span>

              </div>
            </div>
            <div class="bank-number">
         <span>{{state.lan.kyc.binfo.cardname}}</span>
          <span>
                    {{bank.cardNumber}}
                </span>
            </div>
            <div class="bank-number" id="sheba">
              <span>{{state.lan.kyc.binfo.shebaname}}</span>
          <span>
                    {{bank.shebaCode}}
                </span>
            </div>

            <img @click="opendelet=true" class="delete-icon" src='@/assets/Public/bin.svg' />
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
</template>

<script>
const banks = {
  '603799': 'بانک ملی ایران',
  '589210': 'بانک سپه',
  '627648': 'بانک توسعه صادرات',
  '207177': 'بانک توسعه صادرات',
  '627353': 'بانک تجارت',
  '585983': 'بانک تجارت',
  '627961': 'بانک صنعت و معدن',
  '603770': 'بانک کشاورزی',
  '639217': 'بانک کشاورزی',
  '628023': 'بانک مسکن',
  '627760': 'پست بانک ایران',
  '502908': 'بانک توسعه تعاون',
  '627412': 'بانک اقتصاد نوین',
  '622106': 'بانک پارسیان',
  '639194': 'بانک پارسیان',
  '627884': 'بانک پارسیان',
  '502229': 'بانک پاسارگاد',
  '639347': 'بانک پاسارگاد',
  '639599': 'بانک قوامین',
  '504706': 'بانک شهر',
  '502806': 'بانک شهر',
  '603769': 'بانک صادرات',
  '610433': 'بانک ملت',
  '991975': 'بانک ملت',
  '589463': 'بانک رفاه',
  '502938': 'بانک دی',
  '639607': 'بانک سرمایه',
  '627381': 'بانک انصار',
  '505785': 'بانک ایران زمین',
  '636214': 'بانک آینده',
  '636949': 'بانک حکمت ایرانیان',
  '621986': 'بانک سامان',
  '639346': 'بانک سینا',
  '606373': 'بانک قرض الحسنه مهر',
  '627488': 'بانک کارآفرین',
  '502910': 'بانک کارآفرین',
  '505416': 'بانک گردشگری',
  '639370': 'بانک مهر اقتصاد',
  '628157': 'موسسه اعتباری توسعه',
  '505801': 'موسسه اعتباری کوثر',
}
import DeleteBankModal from "@/components/Authorization/DeleteBankModal";
export default {
  name: "Bankinfo",
  components:{
    DeleteBankModal
  },
  data() {
    return {
      bankAccount: {
        shebaCode: '',
        cardNumber: '',
        bankName: ''
      },
      opendelet: false,
      bankIndex: "",
      banks: [{
        card_number: "345343434343",
        sheba: "4343434343",
        name: "parsian"
      },
        // {
        //   card_number: "434343434",
        //   sheba: "343434343",
        //   name: "parsian"
        // },
        // {
        //   card_number: "43434343",
        //   sheba: "4343434343",
        //   name: "parsian"
        // }
      ],
      errorText: "",

    };
  },
  props: ['data', 'error', 'change'],
  methods: {
    addaccount() {
      if (this.bankAccount.shebaCode && this.bankAccount.cardNumber) {
        this.data.bankAccounts.push(this.bankAccount)
        this.$emit('addBank', this.bankAccount)
        this.bankAccount = {
          shebaCode: '',
          cardNumber: '',
          bankName: ''
        }
      }

    },

    deleteBank() {
      this.data.bankAccounts.splice(this.bankIndex, 1)
      this.$emit('confirmDeleteBank')
    },
    dash(e) {
      this.data.bankAccounts[e].bankName = banks[this.data.bankAccounts[e].cardNumber ? this.data.bankAccounts[e].cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      // this.data.bankAccounts[e].cardNumber = this.$separator(this.data.bankAccounts[e].cardNumber)
      this.error = ''
    },

    dash2() {
      this.bankAccount.bankName = banks[this.bankAccount.cardNumber ? this.bankAccount.cardNumber.replace(/-/ig, '').slice(0, 6) : ''] || 'کارت نامعتبر'
      this.bankAccount.cardNumber = this.$A2N(this.bankAccount.cardNumber)
      // this.bankAccount.cardNumber = this.$separator(this.bankAccount.cardNumber)
      this.error = ''
    }
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    for (let [index, item] of this.data.bankAccounts.entries()) {
      this.dash(index)
    }
  }
}
</script>

<style lang="scss" scoped>
.bank-number {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0.5;
  background: rgba(23, 19, 19, 0.22);
  border: 0.6px solid rgba(72, 72, 72, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  color: #CACACE;
  flex-grow: 0.3;

  box-sizing: border-box;
  border-radius: 4px;
  width: 50%;
  //max-width: 148px;
  height: 40px;
  padding: 0 12px;
}





.bank-name{
  display: flex;

  align-items: center;
  justify-content: space-between;
  flex:0.3;
  gap: 16px;
}

.bankname-cont{
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  background: rgba(23, 19, 19, 0.22);
  border: 0.6px solid rgba(72, 72, 72, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  color: #CACACE;
  flex-grow: 1;
}
.bank-existing{

  display: flex;
  flex-direction: column;
  gap: 8px;
  .bank-titles{
    display: flex;
    justify-content: space-between;
    font-size: clamp(10px,1vw,13px);
    color: var(--gray);
  }
}

.exist-banks{
  background: #2B2943;
  padding: 12px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.bank {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  background: #191730;
  gap: 26.5px;
  padding: clamp(5px, 2.5vw, 26px);
  border-radius: 12px;
  min-height: 202px;
}

//titles
.titles {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: clamp(16px, 1.5vw, 20px);
    font-weight: 700;
  }

  a {
    display: flex;
    gap: 4px;
    color: var(--light-blue);
    font-size: clamp(16px, 1.5vw, 20px);


  }
}

.add-titles {
  display: flex;
  justify-content: space-between;

}

.normal-input{
  background: #101127;
  border-radius: 4px;
  color: white !important;
}
.normal-input::placeholder{
  color: white;
  padding: 10px;
}

.add_form {
  padding: 10px;


  //height: 60px;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 100%;
}

.exist-banks {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .banks-list {
    font-size: clamp(10px,1vw,16px);
    img{
      background: white ;
      border-radius: 5px;
    }
    //background: #101127;
    border-radius: 4px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .delete-icon {
      cursor: pointer;
    }


  }
}




@media(max-width: 600px){
  .delete-icon{
    width: 16px;
    height: 16px;
  }
  .bank-name{
    img{
      display: none;
    }

  }
}

@media(max-width: 490px){
  #sheba , #name{
    display: none;
  }

  .bank-titles{
    p:first-child{
      display: none;
    }
    p:nth-child(3){
      display: none;
    }
  }
}



.first-row{
  flex-wrap: wrap;
  input{
    max-width: 398px;
    width: 40px;
    min-width: 40px;
    flex:0.5;
    background: #131330;
    border: 1px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;

  }
  input:focus{
    border: 1px solid rgba(179, 176, 176, 0.5) !important;
  }
  a{
    display: flex;
    align-items: center;
    margin-right: auto;
    color: var(--light-blue);

  }
}
.view{
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.bainfo{
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #191730;
  border-radius: 12px;

}

.first-row{
  display: flex;
  gap: 16px;
}
@media(max-width: 650px){
  .first-row{
    flex-direction: column;
    input{
      max-width: unset;
      flex:unset;
      width: 100%;
    }
  }

}

@media (max-width: 600px) {
  .delete-icon{
    align-self: flex-start;
  }
  .banks-list{
    gap: 10px;
    flex-direction: column;
  }
  .bank-number{
    min-height: 40px;
    width: 100%;
  }

}
</style>