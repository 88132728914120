<template>
<div class="modal-backdrop">
  <div class="modal">
    hello
  </div>
</div>
</template>

<script>
export default {
  name: "TransactionModal"
}
</script>

<style scoped>

</style>