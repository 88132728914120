<template>
  <div class="uinfom">

    <p class="title">
      {{ state.lan.kyc.uinfo.title }}
    </p>
    <div class="view-container">
      <div class="first-row">
        <input type="text"
               :placeholder="state.lan.kyc.uinfo.name"
               v-model="data.firstName"
               @input="data.firstName = $S2Fa(data.firstName)"
               :disabled="readonly"
        >
        <input type="text" :placeholder="state.lan.kyc.uinfo.family"
               v-model="data.lastName"
               :disabled="readonly"
               @input="data.lastName = $S2Fa(data.lastName)"
        >
      </div>
      <div class="sec-row">
        <input type="text" :placeholder="state.lan.kyc.uinfo.id"
               @input="data.nationalCode = $A2N(data.nationalCode)"
               v-model="data.nationalCode"
               :disabled="readonly"
               maxlength="10"

        >
        <div class="date">
          <date-picker :placeholder="state.lan.kyc.uinfo.bd"
                       v-model="data.birthDay"
                       :format="$dateFormat['en']"
                       :display-format="$dateFormat['fa']"
                       :max="$jmoment().format($dateFormat['en'])"
                       :clearable="!state.userInfo.authorized"
                       :disabled="readonly"
                       type="date"

          ></date-picker>
        </div>

        <p class="negative">{{ state.lan.kyc.uinfo.error }}</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Userinfo",
  props: ["data"],
  computed: {
    readonly() {
      return [
        "AUTHORIZED",
        "INFORMATION_CHANGED",
        "INFORMATION_CHANGED_PENDING",
      ].includes(this.state.userInfo.authenticationType);
    },
  },
}
</script>

<style lang="scss" scoped>
.negative {
  align-self: flex-end;
}

.view-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.uinfom {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #191730;
  border-radius: 12px;

}

.first-row {
  flex-wrap: wrap;
  display: flex;
  gap: 16px;

  input {
    max-width: 398px;
    width: 40px;
    min-width: 40px;
    flex: 0.5;
    background: #131330;
    border: 1px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;

  }

  input:focus {
    border: 1px solid rgba(179, 176, 176, 0.5) !important;
  }


}

.date {
  width: 100%;
  max-width: 398px;

}

.sec-row {
  flex-wrap: wrap;


  input {
    height: 40px;


    max-width: 398px;
    flex: 1.41;
    background: #131330;
    border: 1px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
  }

  input:focus {
    border: 1px solid rgba(179, 176, 176, 0.5) !important;
  }

  display: flex;
  gap: 16px;

  p {
    flex: 1;
    flex-basis: 57px;
    font-size: clamp(10px, 1vw, 13px);
  }
}


@media(max-width: 450px) {
  .first-row {
    flex-direction: column;

    input {
      max-width: unset;
      width: 100%;
      min-height: 40px;
    }
  }
  .sec-row {


    flex-direction: column;

    .date, input {
      min-height: 40px;
      width: unset;
    }
  }
}
</style>