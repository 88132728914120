<template>
  <div class="account">
<div class="info"><Info/></div>
    <div class="contacts">
      <div class="uinfo"><Userinformation/></div>
      <div class="cinfo"><Contactuser/></div>
    </div>
<div class="bank"><Bankcards/></div>
  </div>
</template>

<script>
import Info from "@/components/Profile/Account/Info";
import Userinformation from "@/components/Profile/Account/Userinformation";
import Contactuser from "@/components/Profile/Account/Contactuser";
import Bankcards from "@/components/Profile/Account/Bankcards";
export default {
  name: "Account",
  components: {Bankcards, Contactuser, Userinformation, Info}
}
</script>

<style lang="scss" scoped>
.account{
  display: flex;
  flex-direction: column;
  gap: 13px;
}
.info{
  flex-grow: 1;
}

.contacts{
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  gap: 16px;
}
.uinfo{
  flex-basis: 100px;
  flex-grow: 1;
}
.cinfo{
  flex-grow:2;
  flex-basis: 200px;
}
</style>