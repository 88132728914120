<template>
  <div class="navbar">
    <div class="container">
      <div class="nav">
        <div class="images">
          <a
            class="menu-image"
            @click.prevent="mobile = !mobile"
            v-if="
              !$route.path.includes('profile') ||
              !$route.path.includes('/trade')
            "
            ><img alt="" src="../../assets/Public/menu.png" />
          </a>
          <a @click.prevent="$router.push('/')"
            ><img id="img1" alt="" src="../../assets/Public/footerLogo.svg"
          /></a>
          <img
            id="img2"
            alt=""
            src="../../assets/Public/lil-logo.png"
            @click.prevent="$router.push('/')"
          />
        </div>
        <ul class="navigation">
          <li>
            <a @click.prevent="$router.push('/coins')">{{
              nav.MarketsTitle
            }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/guide')">{{ nav.GuideTitle }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/fees')">{{ nav.FeesTitle }}</a>
          </li>
          <li>
            <a
              @click.prevent="$router.push('/blog')"
              v-if="!$route.path.includes('/profile')"
              >{{ nav.BlogTitle }}</a
            >
          </li>
        </ul>
      </div>

      <div class="btns">
        <div
          class="profile-btns"
          v-if="
            $route.path.includes('/profile') || $route.path.includes('/trade')
          "
        >
          <a
            href=""
            class="inventory"
            @click.prevent="$router.push('/profile/wallet')"
          >
            {{ nav.inventory
            }}<img
              src="../../assets/profile/wallet.svg"
              alt=""
              width="16"
              height="16"
          /></a>
          <div class="prof">
            <button @click.prevent="profDrop = !profDrop">
              {{ nav.account }}
              <inline-svg
                :src="require('../../assets/Public/selector.svg')"
              ></inline-svg>
            </button>
            <div class="prof-drop" v-if="profDrop">
              <a
                href=""
                @click.prevent="
                  $router.push('/profile');
                  profDrop = false;
                "
                ><img src="../../assets/profile/menu.svg" alt="" />{{
                  state.lan.side.dashboard
                }}</a
              >
              <a
                href=""
                @click.prevent="
                  $router.push('/profile/account');
                  profDrop = false;
                "
                ><img src="../../assets/profile/profile.svg" alt="" />{{
                  state.lan.side.profile
                }}</a
              >
              <a
                href=""
                @click.prevent="
                  $router.push('/profile/settings');
                  profDrop = false;
                "
                ><img src="../../assets/profile/settings.svg" alt="" />{{
                  state.lan.side.settings
                }}</a
              >
              <a
                href=""
                @click.prevent="
                  $router.push('/profile/invite');
                  profDrop = false;
                "
                ><img src="../../assets/profile/invite.svg" alt="" />{{
                  state.lan.side.invite
                }}</a
              >
              <a
                href=""
                @click.prevent="
                  out = true;
                  profDrop = false;
                "
                ><img src="../../assets/profile/exit.svg" alt="" />{{
                  state.lan.nav.logout
                }}</a
              >
            </div>
          </div>
          <a
            href=""
            @click.p.prevent="$router.push('/profile/notif')"
            class="notif"
            ><img src="../../assets/profile/notif.svg" alt=""
          /></a>
        </div>

        <!-- auth btn when there is no token -->

        <div
          class="auth-btn"
          v-if="
            !$route.path.includes('/profile') &&
            !$route.path.includes('/trade') &&
            !state.token
          "
        >
          <button
            :class="{ 'active-btn': $route.name === 'Login' }"
            class="btn"
            @click.prevent="$router.push('/login')"
          >
            {{ nav.LoginBtn }}
          </button>
          <button
            :class="{ 'active-btn': $route.name === 'Signup' }"
            class="btn"
            @click.prevent="$router.push('/sign-up')"
          >
            {{ nav.SignupBtn }}
          </button>

          <!-- auth token when there is here -->
        </div>

        <div
          class="auth-btn"
          v-if="
            !$route.path.includes('/profile') &&
            !$route.path.includes('/trade') &&
            state.token
          "
          @click="$router.push('/profile')"
        >
          <button
            class="btn"
            :class="{ 'active-btn': $route.name === 'Login' }"
          >
            {{ state.lang == "fa" ? "ورود به حساب " : "Login" }}
          </button>
        </div>
        <div class="lang">
          <button class="lang-btn" @click.prevent="drop = !drop">
            <span id="lang">{{ headerlang.title }}</span>
            <img :src="headerlang.image" alt="" />
            <inline-svg
              :src="require('../../assets/Public/selector.svg')"
            ></inline-svg>
          </button>
          <div
            v-if="drop"
            :class="{ rtl: state.lang === 'fa', ltr: state.lang === 'en' }"
            class="lang-dropdown"
          >
            <span
              v-for="(lang, index) in langMenu"
              :key="index"
              @click.prevent="selectItem(index)"
              >{{ lang.title }}</span
            >
          </div>
        </div>

        <logout v-if="out" @closeModal="out = false" @logout="out = false" />
      </div>
    </div>
    <transition name="fade">
      <div v-if="mobile && state.lang === 'fa'" class="mobile">
        <inline-svg
          :src="require('../../assets/Public/closeIcon.svg')"
          @click="mobile = false"
          class="close"
        />
        <ul class="mobile-nav">
          <li>
            <a @click.prevent="$router.push('/coins') && (mobile = false)">{{
              nav.MarketsTitle
            }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/guide') && (mobile = false)">{{
              nav.GuideTitle
            }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/fees') && (mobile = false)">{{
              nav.FeesTitle
            }}</a>
          </li>
          <li>
            <a
              @click.prevent="$router.push('/blog') && (mobile = false)"
              v-if="!$route.path.includes('/profile')"
              >{{ nav.BlogTitle }}</a
            >
          </li>
        </ul>
      </div>
      <div v-if="mobile && state.lang === 'en'" class="mobile-ltr">
        <inline-svg
          :src="require('../../assets/Public/closeIcon.svg')"
          @click="mobile = false"
          class="close"
        />
        <ul class="mobile-nav">
          <li>
            <a @click.prevent="$router.push('/coins') && (mobile = false)">{{
              nav.MarketsTitle
            }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/guide') && (mobile = false)">{{
              nav.GuideTitle
            }}</a>
          </li>
          <li>
            <a @click.prevent="$router.push('/fees') && (mobile = false)">{{
              nav.FeesTitle
            }}</a>
          </li>
          <li>
            <a
              @click.prevent="$router.push('/blog') && (mobile = false)"
              v-if="!$route.path.includes('/profile')"
              >{{ nav.BlogTitle }}</a
            >
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import en from "@/library/multiLang/en-lang/en";
import fa from "@/library/multiLang/fa-lang/fa";
import logout from "@/components/Public/Logout";

export default {
  name: "NavbarComponent",
  components: {
    logout,
  },
  emits: ["profDrop"],
  data() {
    return {
      close: false,
      out: false,
      //profile dropdown is false for default
      profDrop: false,
      //calling nav from languages
      nav: this.state.lan.nav,
      //activation of mobile menu
      mobile: false,
      langMenu: [
        {
          title: "فارسی",
          image: require("../../assets/Public/persian.png"),
          //value will replace in localstorage
          value: "fa",
        },
        {
          title: "English",
          image: require("../../assets/Public/english.png"),
          //value will replace in localstorage
          value: "en",
        },
      ],
      //language menu in navbar
      //selectLang is for lang btn
      headerlang: {},

      drop: false,
    };
  },
  mounted() {
    //the headerlang is an object that has 2 keys one title and other is img
    // it will store first el of lang menu
    if (this.state.lang === "fa") {
      this.headerlang = this.langMenu[0];
    } else this.headerlang = this.langMenu[1];
  },
  created: function () {
    window.addEventListener("click", (event) => {
      if (!event.target.closest(".navbar")) {
        // console.log('hi')
        this.$emit("closeprof", this.profDrop);
        this.profDrop = false;
      } else return true;
    });
  },
  methods: {
    //select item stands for language dropdown
    selectItem(Index) {
      //it replace headerlang with the value that user click in lang dropdown
      this.headerlang = this.langMenu[Index];
      console.log("hi");
      //it replace the value in menu lang that we chose
      this.state.lang = this.langMenu[Index].value;
      //lan is fa for default and it is an if statement that will chose language and
      //replace it into lan in the state file
      this.state.lan = this.state.lang === "fa" ? fa : en;
      //it is not recommended but you can use your datas that came from your en or fa file to a custom var
      this.nav = this.state.lan.nav;
      //and this is going to close dropdown
      this.drop = !this.drop;
    },
  },
};
</script>

<style lang="scss" scoped>
.inventory {
  display: flex;
  gap: 4px;
}
.prof {
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 10px;
  .prof-drop {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #191730;
    /* Grey text(inactive) */
    padding: 10px;
    border: 0.8px solid #cacace;
    box-sizing: border-box;
    border-radius: 12px;
    position: absolute;
    width: 150px;
    top: 30px;
    a {
      display: flex;
      gap: 18px;
      align-items: center;
      font-size: clamp(10px, 1vw, 13px);
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}

#img2 {
  display: none;
}
//navbar
.navbar {
  position: sticky;
  z-index: 10;
  //position: relative;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--primary);
  height: 56px;
  width: 100%;
  padding: 0 38px;
}

//mobile navigation-bar Persian
.mobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  top: 56px;
  right: 0;
  min-width: 200px;
  height: calc(100vh - 56px);
  background: rgba(69, 78, 248, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 0;
}
.mobiles {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  top: 56px;
  right: 0;
  min-width: 200px;
  height: calc(100vh - 56px);
  background: rgba(69, 78, 248, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 0;
}
.close {
  margin: 10px;
}

//mobile menu for English
.mobile-ltr {
  position: absolute;
  top: 56px;
  left: 0;
  min-width: 200px;
  height: calc(100vh - 56px);
  background: rgba(69, 78, 248, 0.8);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  //border-radius: 10px;
}

//navigation of mobile
.mobile-nav {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 50px;
}

//Extra classes to the container
.container {
  max-height: 57px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 60px;
}

//navigation menu
.navigation {
  display: flex;
  column-gap: 60px;
  cursor: pointer;
}

//auth and lang btn
.btns {
  display: flex;
  align-items: center;
  column-gap: 48px;

  .auth-btn {
    width: 100%;
  }
}

.profile-btns {
  display: flex;
  gap: 40px;
}

.active-btn {
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
}

//lang container
.lang {
  position: relative;
}

.lang-dropdown {
  cursor: pointer;
  padding: 4px;
  position: absolute;
  background: #191730;
  border: 1px solid #cacace;
  border-radius: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 80px;
  width: 100%;
  top: 30px;
}

//langugae btn
.lang-btn {
  display: flex;
  column-gap: 7px;
  align-items: center;
}

//styling the btns
.btn {
  min-width: 120px;
  height: 36px;
}

//navigation a's
a {
  font-size: clamp(12px, 1vw, 16px);
  font-weight: 100;
  display: flex;
  align-items: center;
}

//navbar and logo
.images {
  display: flex;
  column-gap: 8px;
}

.menu-image {
  display: none;
}

.mobile,
.mobile-ltr {
  display: none;
}

//mobile menu effect
.fade-enter-active {
  transition: all 1s ease;
  //-moz-transform: translate(12px,12px);
  //-webkit-transform: translate(12px, 12px);
  //-o-transform: translate(12px, 12px);
  //-ms-transform: translate(12px, 12px);
  //transform: translate(12px,12px);
}
.fade-leave-active {
  transition: all 1s ease;
  -moz-transform: translate(-12px, 0px);
  -webkit-transform: translate(-12px, 0px);
  -o-transform: translate(-12px, 0px);
  -ms-transform: translate(-12px, 0px);
  transform: translate(-12px, 0px);
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
  opacity: 0;
}

@media (max-width: 1110px) {
  .mobile,
  .mobile-ltr {
    display: block;
  }
  .navigation {
    display: none;
  }
  .menu-image {
    display: flex;
  }
}

@media (max-width: 642px) {
  .notif {
    display: none;
  }
  .inventory {
    display: none;
  }
  .navbar {
    padding: 0 3px;
  }
  #img1 {
    display: none;
  }
  #img2 {
    display: block;
  }

  #lang {
    display: none;
  }
  .auth-btn {
    .btn {
      min-width: unset;
      width: 60px;
    }
  }
  .rtl {
    right: -40px;
  }
  .ltr {
    right: 5px;
  }
  .lang-dropdown {
    width: 80px;
  }
}
</style>
<style></style>
