var coins= [

    {
        time:'22/2/22 12:23',
        code:'BTC',
        coin: 'bitcoin',
        amount:0.0045,
        fee:0.0006,
        add:'1N4pWYTL9f',
        stat:'Success',
        id:1,
    },
    {
        time:'22/2/22 12:23',
        coin: 'tether',
        code:'THR',
        amount:0.0045,
        fee:0.0006,
        add:'1N4pWYTL9f',
        stat:'Success',
        id:2,
    },
    {
        time:'22/2/22 12:23',
        coin: 'litecoin',
        code:'LTC',
        amount:0.0045,
        fee:0.0006,
        add:'1N4pWYTL9f',
        stat:'Success',
        id:3,
    },
    {
        time:'22/2/22 12:23',
        coin: 'dogecoin',
        code:'DOGEs',
        amount:0.0045,
        fee:0.0006,
        add:'1N4pWYTL9f',
        stat:'Success',
        id:4,
    },
    {
        time:'22/2/22 12:23',
        coin: 'dash',
        code:'DASH',
        amount:0.0045,
        fee:0.0006,
        add:'1N4pWYTL9f',
        stat:'Success',
        id:5,
    }
]

export default coins