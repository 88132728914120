<template>
  <form class="login" @submit.prevent="registerUser">
    <div class="first-row">
      <div class="title">
        <div class="title-text">
          <h4>{{ this.state.lan.signup.title }}</h4>
          <p class="deactive">{{ this.state.lan.signup.GoogleTitle }}</p>
          <button @click.prevent="googleSso">
            <inline-svg
              :src="require('../../assets/Authentication/google.svg')"
            />
            <span
              :class="{ rtl: state.lang === 'fa', ltr: state.lang === 'en' }"
              >{{ this.state.lan.signup.GoogleBtn }}</span
            >
          </button>
        </div>
      </div>
    </div>
    <div class="second-row">
      <div class="mail">
        <label for="email">{{ state.lan.signup.EmailLable }}</label>
        <div class="mail-container">
          <label>
            <inline-svg
              :src="require('../../assets/Authentication/email.svg')"
            />
          </label>
          <input
            :placeholder="state.lan.signup.EmailPlace"
            name="email"
            type="text"
            v-model="data.email"
          />
        </div>
      </div>
      <div class="pass">
        <label for="pass">{{ state.lan.signup.PassLable }}</label>
        <div class="pass-container">
          <label>
            <inline-svg
              :src="require('../../assets/Authentication/pass.svg')"
            />
          </label>
          <input
            id="pass"
            :placeholder="state.lan.login.PassPlace"
            :type="type"
            v-model="data.password"
          />
          <a @click.prevent="showingPass">
            <inline-svg
              :src="require('../../assets/Authentication/show.svg')"
            />
          </a>
        </div>
        <div class="forget">
          <a href="">{{ state.lan.signup.Forget }}</a>
        </div>
      </div>
      <div class="mail">
        <label for="email">{{ state.lan.signup.invite }}</label>
        <div class="mail-container">
          <label>
            <inline-svg
              :src="require('../../assets/Authentication/email.svg')"
            />
          </label>
          <input
            :placeholder="state.lan.signup.invitePlace"
            name="email"
            id="email"
            type="text"
            v-model="data.referralCode"
          />
        </div>
      </div>
      <div class="terms">
        <input type="checkbox" @change="check = !check" />
        <p>
          <span
            ><a href="">{{ state.lan.signup.Terms }} </a></span
          >{{ state.lan.signup.TermsText }}
        </p>
      </div>
    </div>

    <button :disabled="!data.email || !data.password || !check">
      {{ state.lan.signup.Submit }}
    </button>
    <div class="create">
      <p>{{ state.lan.signup.HaveAccount }}</p>
      <a href="/login" @click.prevent="$router.push('/login')">{{
        state.lan.signup.Login
      }}</a>
    </div>
  </form>
</template>

<script>
export default {
  name: "Signup",
  data() {
    return {
      type: "password",
      data: {
        email: "",
        password: "",
        osType: "",
        deviceType: "",
        browser: "",
        referralCode: "",
      },
      check: false,
    };
  },
  methods: {
    showingPass() {
      if (this.type === "password") {
        this.type = "text";
      } else if (this.type === "text") {
        this.type = "password";
      }
    },
    async checkEmail() {
      if (!this.$validEmail(this.data.email) || !this.data.email) {
        if (this.state.lang == "fa") {
          this.emailError = "ایمیل صحیح وارد نمایید";
          this.$error("اطلاعات وارد شده اشتباه است", "ایمیل صحیح وارد نمایید");
        } else {
          (this.emailError = "Please Enter a correct password"),
            this.$error(
              "The information entered is incorrect",
              "Please Enter a correct password"
            );
        }
        return Promise.reject("repeat failed : " + this.data.email);
      } else {
        this.emailError = "";
      }
    },
    async checkPassword() {
      if (
        !this.$validPass(this.data.password) ||
        this.data.password.length < 8
      ) {
        if (this.state.lang === "fa") {
          this.passError =
            "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد";
          this.$error(
            "اطلاعات وارد شده اشتباه است",
            "رمز باید حداقل 8 کاراکتر باشد و شامل حرف بزرگ ، حرف کوچک و عدد باشد"
          );
        } else {
          this.passError =
            "The password must be at least 8 characters long and contain uppercase, lowercase, and numbers";
          this.$error(
            "The information entered is incorrect",
            "The password must be at least 8 characters long and contain uppercase, lowercase, and numbers"
          );
        }

        return Promise.reject("wrong pass : " + this.data.password);
      } else {
        this.passError = "";
      }
    },
    //axios functions placed in here
    googleSso() {
      window.location.href = this.$axios.defaults.baseURL + "/users/google-sso";
    },
    async registerUser() {
      await this.checkEmail();
      await this.checkPassword();
      // this.state.loading=true;
      const res = await this.$axios.post("users/register", this.data);
      console.log(res);
      if (
        res.message === "user created successfully , verification email sent"
      ) {
        if (res.baseDTO.towStepAuthenticationType === "EMAIL") {
          this.$cookies.set("username", this.data.email);
          this.state.userInfo.email = this.data.email;
          await this.$router.push("/otpSignup");
        }
      } else if (res.token) {

        this.$setToken(res.token, this.state.userInfo.remember);
        await this.state.lang=='fa'?this.$error('کاربر ایجاد شد', '' , 'success'):this.$error('User Created','','success')
        await this.$router.push("/otpSignup");
      }
      console.log("success");
    },
  },
  mounted() {
    //mount platform and etc.
    this.data.osType = this.$detectOs();
    this.data.browser = this.$detectBrowser() || "CHROME";
    this.data.deviceType = this.$detectDevice();
    //save refferal in the localstorage
    console.log(
      "os type",
      this.data.osType,
      "browser type",
      this.data.browser,
      "device type",
      this.data.deviceType
    );
    if (this.$route.query.ref || localStorage.referralCode) {
      localStorage.referralCode =
        this.$route.query.ref || localStorage.referralCode;
      this.data.referralCode =
        this.$route.query.ref || localStorage.referralCode;
    }
  },
};
</script>

<style lang="scss" scoped>
//terms and checkbox box
.terms {
  display: flex;
  column-gap: 5px;
  align-items: center;

  p,
  span,
  a {
    font-size: clamp(10px, 1vw, 14px);
  }

  a {
    color: var(--light-blue);
  }
}

.rtl {
  margin-right: 27%;
}

.ltr {
  margin-left: 25.5%;
}

.pass-container {
  a {
    display: flex;
    align-items: center;
  }
}

.login {
  width: 100%;
  max-width: 390px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px 24px;
  row-gap: 24px;
  //first row contains title and google login
  .first-row {
    //main title
    .title {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .title-text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      button {
        display: flex;
        border-radius: 4px !important;
        align-items: center;
        font-size: clamp(10px, 1vw, 12px);
        font-weight: 400;
        background: #04235c;
        border: 1px solid rgba(178, 176, 176, 0.5);
        min-height: 40px;
        padding: 0 12px;
      }
    }

    //texts of title

  }

  //second row contains inputs
  .second-row {
    //second row styles
    display: flex;
    flex-direction: column;
    gap: 18px;
    //email and password main container
    .mail,
    .pass {
      display: flex;

      flex-direction: column;
      row-gap: 6px;
    }

    #email,
    #pass {
      width: 100%;
    }
    //container of the email tha contains email input and svg in it
    .mail-container,
    .pass-container {
      gap: 5px;
      display: flex;
      border: 1px solid rgba(179, 176, 176, 0.5);
      border-radius: 4px;
      background: #131330;
      align-items: center;
      height: 36px;
      padding: 0 9px;
      label {
        display: flex;
      }
    }
    .forget {
      a {
        color: var(--light-blue);
      }
    }
  }
}
//recaptcha image
//img{
//  align-self: center;
//}
//submit button
button {
  background: linear-gradient(
    91.87deg,
    #6085d5 0%,
    #2850a8 53.83%,
    #7b9eeb 112.8%
  );
  border-radius: 12px;
  height: 40px;
}
//create account text
.create {
  display: flex;
  justify-content: center;
  p,
  a {
    font-size: clamp(10px, 1vw, 13px);
  }
  a {
    color: var(--light-blue);
  }
}

//passed some style to deactive text
.deactive {
  font-size: clamp(10px, 1vw, 13px);
}

@media (max-width: 650px) {
  .login {
    width: 90%;
    //row-gap: 5px;
  }

  @media (max-width: 500px) {
    .mail-container,
    .pass-container {
      > label {
        > svg {
          width: 12px;
        }
      }
    }
  }
}
</style>
