<template>
  <div class="table-container">
    <p class="title">{{state.lan.wallet.diposit.toman.tabt}}</p>
    <table>
      <tr class="tr-head">
        <td class="phone ">{{state.lan.wallet.diposit.coin.time}}</td>
        <td class="phone ">{{state.lan.wallet.diposit.toman.amount}}</td>
        <td class="phone ">{{state.lan.wallet.diposit.toman.sheba}}</td>

        <td>{{state.lan.wallet.diposit.coin.stat}}</td>
        <td  :class="{'rtl-head':state.lang==='fa' , 'ltr-head': state.lang==='en'}" >{{state.lan.wallet.diposit.toman.trans}}</td>
      </tr>
      <tr class="tr-body" v-for="(item,index) in datas" :class="{'rtl':state.lang==='fa' , 'ltr': state.lang==='en'}"  >
        <td class="phone">{{item.createdAtDateTime}}</td>

        <td class="phone">{{item.amount}}</td>
        <td class="phone">{{item.bankAccount}}</td>
        <td class="pos" v-if="state.lang=='fa'" :class="{'green':item.isDone,'red':!item.isDone}">{{item.isDone ?'تایید شده' : 'تایید نشده'}}</td>
        <td class="pos" v-if="state.lang!=='fa'" :class="{'green':item.isDone,'red':!item.isDone}">{{item.isDone ?'APPROVED' : 'PENDING'}}</td>
        <td><a :href="item.link">{{state.lan.wallet.diposit.coin.detail}}</a></td>
      </tr>
    </table>

  </div>
</template>

<script>
import coins from '../../../../library/walletcoins'
import Tomandiposit from "@/components/Profile/Wallet/Tomandiposit";
export default {
  name: "Crypdipotable",

  props:['selectedcoin'],
  data(){
    return{
      datas:[],
      name:'',
      // walletadd:'1N4pWYTL9fx9ZGQs8W',
      sheba:'2222 2222 2222 2222 ',
      bank:'parsian',

    }
  },
  methods:{
    async getTomanDeposit(){
      const res = await this.$axios('/wallets/deposit')
const {content:deposits} = res
const datas = deposits.filter(item=>item.relatedCoin = 'TOMAN')
      console.log(datas)
      this.datas = datas;
    }
  },
  mounted() {
    this.getTomanDeposit()
    // this.name = this.datas.find(item => item.id === this.selectedcoin).coin
    //
    // console.log(this.datas, this.name , this.selectedcoin)

  }
}
</script>

<style lang="scss" scoped>
table{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  overflow: scroll;
}
table::-webkit-scrollbar{
  display: none;

}
.tr-head{
  position: sticky;
  top: 0;
  background: #191730;
}


.rtl-head{
  text-align: left;
}
.ltr-head{
  text-align: right;
}

.rtl{
  td:last-child{
    text-align: left;
  }
}
.ltr{
  td:last-child{
    text-align: right;
  }
}


.pos{
  color: #18D04B !important;
}

.table-container{
  background: #191730;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title{
  color:#FFF1F1;
}

table{
  border-collapse: collapse;
}

.tr-head{

  width: 100%;
}

.tr-head, .tr-body{
  td{
    border-bottom: 1px solid rgba(255,255,225,0.1);
    height: 46px;
    font-size: clamp(10px,1vw,13px);
    color: var(--gray);
  }

}


.tablet {
}

.phone {
}


@media screen and (max-width: 1100px) {
  .table-container{
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .phone {
    display: none;
  }
}

</style>