<template>
  <div class="market-view">
    <p class="title">{{state.lan.marketv.title}}</p>




    <div class="table">
      <fa-table />

    </div>
  </div>
</template>

<script>
import FaTable from "@/components/Home-page/FaTable.vue";
// import EnTable from "@/components/Home-page/EnTable";
import crypto from '@/library/crypto.js'
export default {
  components: {FaTable},
  data(){
return{
  coins:crypto
}
  }
}
</script>

<style lang="scss" scoped>
//title of market view
.title{
  font-size: clamp(20px,2vw,24px);
}
//tables stands here
.market-view{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

</style>