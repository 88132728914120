<template>
<div class="trade-vol">
  <div class="first-row" v-if="state.lang==='fa'">
    <div class="first-col">
      <p class="teter">تتر <span>USDT</span></p>
      <p>بالاترین خرید: <span class="teter">{{$toLocal(content.maxPrice, $decimal['TOMAN'])}}</span> <span class="gray">تومان</span></p>
    </div>
    <div class="second-col">
      <img src="../../../assets/profile/teter.svg" alt="">
      <p :class="{'positive':content.last24HourChange>0,'negative':content.last24HourChange<0}">{{$toPrecision(content.last24HourChange)}}%</p>
    </div>
    <div class="third-col">

      <p class="teter">تومان <span>TMN</span></p>
      <p>پایین ترین خرید: <span class="teter" >{{$toLocal(content.minPrice, $decimal['TOMAN'])}}</span><span class="gray">تومان</span></p>
    </div>
  </div>
  <div class="second-row">
<p v-if="state.lang=='fa'"> {{state.lan.dash.vol}} :<span> &nbsp;{{$toLocal(content.last24HourVolume,$decimal['TOMAN'])}}</span><span class="gray"> {{state.lan.dash.toman}} </span></p>
    <button @click.prevent="$router.push('/trade')" >{{state.lan.dash.trade}}
      <svg
          :class="{'ltr':state.lang==='en'}"
          width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.7838 20.383L6.39385 11.9923L14.7838 3.60163C15.0267 3.35016 15.1611 3.01336 15.1581 2.66376C15.155 2.31417 15.0148 1.97975 14.7676 1.73254C14.5204 1.48533 14.186 1.3451 13.8364 1.34207C13.4868 1.33903 13.15 1.47342 12.8985 1.7163L3.56519 11.0496C3.31522 11.2997 3.1748 11.6387 3.1748 11.9923C3.1748 12.3458 3.31522 12.6849 3.56519 12.935L12.8985 22.2683C13.15 22.5112 13.4868 22.6456 13.8364 22.6425C14.186 22.6395 14.5204 22.4993 14.7676 22.2521C15.0148 22.0048 15.155 21.6704 15.1581 21.3208C15.1611 20.9712 15.0267 20.6344 14.7838 20.383Z" fill="white"/>
        <path d="M10.2317 11.0496C9.98173 11.2997 9.84131 11.6387 9.84131 11.9923C9.84131 12.3458 9.98173 12.6849 10.2317 12.935L19.565 22.2683C19.8165 22.5112 20.1533 22.6456 20.5029 22.6425C20.8525 22.6395 21.1869 22.4993 21.4341 22.2521C21.6813 22.0048 21.8215 21.6704 21.8246 21.3208C21.8276 20.9712 21.6932 20.6344 21.4503 20.383L13.0604 11.9923L21.4503 3.60163C21.6932 3.35016 21.8276 3.01336 21.8246 2.66376C21.8215 2.31417 21.6813 1.97975 21.4341 1.73254C21.1869 1.48533 20.8525 1.3451 20.5029 1.34207C20.1533 1.33903 19.8165 1.47342 19.565 1.7163L10.2317 11.0496Z" fill="#C7D7F9"/>
      </svg>

    </button>
  </div>
</div>
</template>

<script>
export default {
  name: "Tradevol",
  data(){
    return{
      content:[],
      highest:27500,
      lowest:26570,

      vol:4552445532,
      volUSDT:123343245,
    }
  },
  methods:{
    async getUSDT(){
     const response= await this.$axios('trades/market-info?market_type=TETHER_TOMAN',{ params:{
        weeklyDataIncluded:false

      }})
      console.log(response)
      this.content = response.content[0]
    },
  },
  mounted(){
    this.getUSDT()
    this.$getVolumes()
    console.log(this.content)
  }

}
</script>

<style lang="scss" scoped>
.ltr{
  transform: scale(-1,1);
}
.gray{
  margin-right: 4px;
  color: var(--gray);
}
.trade-vol{

  background: #191730;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: clamp(10px,2vw,24px);
}
//first row contains prices
.first-row{
  align-items: center;
  min-height: 133px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  gap: clamp(20px,3vw,105px);
  background: rgba(163, 163, 163, 0.09);
  border-radius: 12px;
.first-col,.second-col,.third-col{
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;

p{
  display: flex;
  align-items: center;

}
  .teter{
    font-size: clamp(20px,2vw,24px);
    margin-right: 12px;
  }
}
}

.second-row{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  p{
    align-self: center;
  }
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
    border-radius: 12px;
    width: 132px;
    height: 40px;

  }
}
</style>