<template>
  <div class="otp">
    <div class="otp-title">

      <h4>{{ state.lan.otpmobile.title }}</h4>
      <p>{{ state.lan.otpmobile.text }}</p>

    </div>
    <div class="input">
      <CodeInput :fields="6" :loading="false" class="input"
      />
      <!--                 v-on:change="onChange"-->
      <!--                 v-on:complete="onComplete"-->


      <div class="timer">
        <Timer/>
      </div>
    </div>
    <button @click.prevent="$router.push('/profile')">
      {{ state.lan.otp.submit }}
    </button>
    <div class="change">
      <a href="">{{ state.lan.otp.change }}</a>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
import Timer from "@/components/Tools/Timer";

export default {
  name: "OTPMobile",
  components: {
    CodeInput,
    Timer
  },
  methods: {
    handleOnChange() {
      console.log('hello')
    }
  }
}
</script>

<style lang="scss" scoped>


// a little change on timer div :)
.timer {
  padding: 0 10px;
}


//main container of otp
.otp {
  width: 100%;
  max-width: 390px;
  background: #191730;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 12px 24px;
  row-gap: 40px;
}

//otp text and titles
.otp-title {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  p {
    color: var(--gray);
    font-size: clamp(10px, 1vw, 13px);
  }
}

//otp input and timer is here
.input {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}


//submit btn is here
button {
  display: flex;
  //border-radius: 4px !important;
  align-items: center;
  justify-content: center;
  font-size: clamp(10px, 1vw, 12px);
  font-weight: 400;
  background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
  border-radius: 12px;
  border: 1px solid rgba(178, 176, 176, 0.5);
  height: 40px;
  padding: 0 12px;

}


//change account
.change {
  align-self: center;

  a {
    color: var(--light-blue);
    font-size: clamp(10px, 1vw, 12px);
    font-weight: 300;
  }

}
</style>
//styles for react code input
<style lang="scss">
.react-code-input-container {
  direction: ltr !important;
  display: flex;
  column-gap: 17px;
  width: unset !important;
  align-self: center;
}

.react-code-input {
  display: flex;
  gap: 17px;

  input {
    color:white !important;
    border: 1px solid rgba(178, 176, 176, 0.5) !important;
    border-radius: 4px !important;
    max-width: 48px !important;
    max-height: 64px !important;

&:focus{
  border: 1px solid var(--light-blue) !important;
}
  }

}

@media(max-width: 403px) {
  .otp{
    width: 90% !important;
    min-width: unset !important;
  }
  .react-code-input {
    gap: 10px;


    input {
      color:white !important;
      border: 1px solid rgba(178, 176, 176, 0.5) !important;
      border-radius: 4px !important;
      max-width: 36px !important;
      max-height: 46px !important;

      &:focus{
        border: 1px solid var(--light-blue) !important;
      }
    }


  }



}
</style>