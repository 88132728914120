<template>
<div class="cinfom">
  <p>{{state.lan.kyc.cinfo.title}}</p>
  <div class="view">
    <div class="first-row">
      <input type="text" :placeholder="state.lan.kyc.cinfo.mobile"
             v-model="data.mobileNumber"
             maxlength="11"
             @input="data.mobileNumber = $A2N(data.mobileNumber)"

      />
      <input type="text" :placeholder="state.lan.kyc.cinfo.wire"
             @input="data.telephone = $A2N(data.telephone)"
             v-model="data.telephone"
             maxlength="11"
      />
    </div>
    <div class="sec-row">
      <input type="text"  id="" :placeholder="state.lan.kyc.cinfo.address" v-model="data.address">
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Contactinfo",
  props: ["data"],
  computed: {
    readonly() {
      return [
        "AUTHORIZED",
        "INFORMATION_CHANGED",
        "INFORMATION_CHANGED_PENDING",
      ].includes(this.state.userInfo.authenticationType);
    },
  },
}
</script>

<style lang="scss" scoped>
.cinfom{
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: #191730;
  border-radius: 12px;
}
.view{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.first-row , .second-row{
  display: flex;
  gap: 16px;
}
.first-row{
  flex-wrap: wrap;
  display: flex;
  gap: 16px;
  input{
    max-width: 398px;
    width: 40px;
    min-width: 40px;
    flex:0.5;
    background: #131330;
    border: 1px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;

  }
  input:focus{
    border: 1px solid rgba(179, 176, 176, 0.5) !important;
  }


}
.sec-row{
display: flex;
  input{
    flex:1;
    max-width: 812px;
    width: 40px;
    //min-width: 40px;
    //flex:0.5;
    background: #131330;
    border: 1px solid rgba(179, 176, 176, 0.5);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 10px;
  }
  input:focus{
    border: 1px solid rgba(179, 176, 176, 0.5) !important;
  }
}

@media(max-width: 450px) {
  .first-row {
    flex-direction: column;

    input {
      max-width: unset;
      width: 100%;
      min-height: 40px;
    }
  }
}
.sec-row{
  input{
    display: flex;
    align-items: flex-start;
    //min-height: 120px;
  }

}
</style>