<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
        <h5 class="align-self-center">
            برداشت {{$coinUnit[$route.params.target]}}
        </h5>

        <div class="d-flex justify-space-between w100 small">
<span>
{{state.lang==='fa'? 'مقدار':'Amount'}}

</span>
<span class="gray">{{amount}}</span>

        </div>
        <div class="d-flex justify-space-between w100 small">
<span>
    {{state.lang==='fa'? 'ادرس مقصد': 'Destination Address'}}
</span>
<span class="gray">{{walletAddress}}</span>

        </div>
<div class="otp d-flex flex-col gap-xss">
<h5 class=" w100 align-self-start small">{{state.lang=='fa'? 'کد دوعاملی' : 'OTP'}}</h5>
      <CodeInput @change="onChange"
                 @complete="submitWithdrawRequest"
                 :fields="num"
                 ref="code-input" class="input"
      />
 <span class="small gray">     {{countDown}}</span>

</div>
        <button class="w100" v-if="countDown !==0">
<span v-if="state.lang==='fa'">درخواست برداشت</span>
<span v-else>withdraw Request</span>


        </button>
        <button @click.prevent="send"  class="w100" v-else>
            <span v-if="state.lang==='fa'">ارسال مجدد </span>
<span v-else>Resend Code </span>
        </button>
    </div>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
export default {
name:'withdrawModal',

components:{CodeInput},
props:['amount','walletAddress','network'],
data(){
return{
     num: 6,
     otp:'',
           timeOut: "",
      countDown: 120,
        transaction: {
        amount: "",
        destinationWalletAddress: "",
        relatedCoin: "",
        tokenType: "",
        code: "",
        walletType: this.exchange ? "EXCHANGE" : "P2P",
      },
}
},
methods:{
    onChange(e){
        this.transaction.code = this.$A2N(e);
    },
        async getWalletData() {
      this.state.loading=true;
      const res = await this.$axios(`/wallets/customer-addresses`)
      let entireRes = Object.entries(res)
console.log(res[this.$route.params.target].credit)




    },


    async submitWithdrawRequest() {
      this.state.loading = true;


      if(this.$route.params.target ==='TETHER'){
         this.transaction.tokenType = this.network
        console.log(this.transaction.tokenType)
        console.log(this.network)
      }

      if(this.$route.params.target ==='TOMAN'){
        delete this.transaction.tokenType
        delete this.transaction.walletType
        this.transaction.destinationWalletAddress.replaceAll('/s','')
      }

      if(this.$route.params.target !== 'TETHER' && this.$route.params.target !== 'TOMAN'){
        delete this.transaction.tokenType
      }


      const [res] = await this.$http.post(
          "/wallets/withdrawal-request",
          this.transaction
      );
      if (res) {
        this.$emit("close");
        this.$error("درخواست شما با موفقیت ثبت شد", "", "success");
        ++this.state.requestChanged;
      }
    },



    async send() {
      this.state.loading = true;


      const res = await this.$axios.post("/users/send-code");
      this.state.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
      if (res.message.includes('successfully')) {
        this.$error(
'Code Sent Successfully',
        );
 this.countDown = 120;
        this.countDownTimer();
        this.state.loading = false;
      }
    },









    async withdraw() {   
        this.state.loading = true;
         this.transaction.code =this.otp;
        (this.transaction.amount = this.$S2N(this.amount)),
            (this.transaction.destinationWalletAddress = this.walletAddress),
            (this.transaction.relatedCoin = this.$route.params.target);
        if ("TETHER" === this.$route.params.target) {
          this.transaction.tokenType = this.network;
        }
        const res = await this.$axios.post("/users/send-code");
        this.state.towStepAuthenticationType = res.baseDTO.towStepAuthenticationType;
if(res.message.includes('successfully')){
    this.countDownTimer();
}
    },

    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOut = setTimeout(() => {
          this.countDown--;
          this.countDownTimer();
        }, 1000);
      } else {
        this.$error("زمان شما به اتمام رسید", "");
      }
    },








},
async mounted(){
    await this.getWalletData()
await this.withdraw()
},
  beforeDestroy() {
    clearTimeout(this.timeOut);
  },
}
</script>

<style lang='scss' scoped>
button{
    background: linear-gradient(91.97deg, #4FCD72 0%, #13C144 60.3%, #6ED08A 107.2%);
border-radius: 12px;
height: 40px;

}

.modal{
    gap: 1.2em ;
}
</style>