<template>
  <div class="shop">
    <div class="list">
      <button @click.prevent="tableMarket = !tableMarket">
        <span>
          <img :src="'/coins/'+$Ex[selectcoin] +'.png'" alt="" width="32"/>
          {{ state.lang === "fa" ? $coinLabel[selectcoin] : $coin2Snake[selectcoin] }}
        </span>
        <span :class="{ active: tableMarket }"
        >{{ state.lan.trade.shop.mc }}
          <img
              src="../../../../assets/Public/selector.svg"
              alt=""
              width="14"
              :class="{ trans: tableMarket }"
          />
        </span>
      </button>
      <div class="mt-container" v-if="tableMarket">
        <table class="market-table">
          <tr
              class="tr-head"
              :class="{ rtl: state.lang === 'fa', ltr: state.lang === 'en' }"
          >
            <td>{{ state.lan.marketv.coin }}</td>
            <td>{{ state.lan.history.price }}</td>
            <td>{{ state.lan.marketv.change }}</td>
          </tr>
          <tr
              class="tr-body pointer"
              :class="{ rtl: state.lang === 'fa', ltr: state.lang === 'en' }"
              v-for="(item, index) in coins"
              :key="index"
              @click.prevent="selectItem(index,item)"
          >
            <td>
              <span>

                <img :src="`/coins/${$Ex[item.relatedCoin]}.png`" alt="" width="32"/>
                {{ state.lang === "fa" ? $coinLabel[item.relatedCoin] : item.relatedCoin }}
              </span>
            </td>
            <td>{{ $toLocal(item.lastPrice, $decimal[tradeTo]) }}</td>
            <td
                :class="{ positive: item.last24HourChange > 0, negative: item.last24HourChange < 0 }"
            >
              {{ item.last24HourChange }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="main">
      <div class="buttons">
        <button @click.prevent="activeBtn" :class="{ buy: buy }">
          <img src="@/assets/profile/buyicon.svg" alt=""/>
          {{ state.lan.trade.shop.buy }}
        </button>

        <button @click.prevent="activeBtn" :class="{ sell: sell }">
          <img src="@/assets/profile/sellicon.svg" alt=""/>
          {{ state.lan.trade.shop.sell }}
        </button>
      </div>
      <div class="buttons-orders">
        <button
            :class="{ activate: limit, 'not-active': limit === false }"
            @click.prevent="(limit = true), (market = false)"
        >
          {{ state.lan.trade.shop.limit }}
        </button>
        <button
            :class="{ activate: market, 'not-active': market === false }"
            @click.prevent="(market = true), (limit = false)"
        >
          {{ state.lan.trade.shop.market }}
        </button>
      </div>
      <!--      <div class="input-container">-->
      <!--        <label for="price">{{ state.lan.trade.shop.prlabel }}</label>-->
      <!--        <div class="input-norm">-->
      <!--          <input type="text" name="" id="price"  v-model="unitPrice" @input="unitPrice = $toLocal(unitPrice,$decimal[$route.params.tradeFrom])" />-->
      <!--          <span>{{ state.lan.trade.shop.toman }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="input-container">-->
      <!--        <div class="label-cont">-->
      <!--          <span>-->
      <!--            <label for="amount">{{ state.lan.trade.shop.amlabel }}</label></span-->
      <!--          >-->
      <!--          <span>-->
      <!--            <label for="amount">-->
      <!--              {{ state.lan.trade.shop.inventory-->
      <!--              }}{{ $toLocal(cash, $decimal[$route.params.tradeFrom]) }}-->
      <!--              {{-->
      <!--                $coinUnit[$Ex[selectcoin]]-->
      <!--              }}</label-->
      <!--            ></span-->
      <!--          >-->
      <!--        </div>-->
      <!--        <div class="input-norm">-->
      <!--          <input type="text" name="" id="amount" v-model="amount" @input="amount = $toLocal(amount,$decimal[$route.params.tradeTo])" />-->
      <!--          <span>{{ $coinUnit[$Ex[selectcoin]] }}</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <date-picker v-if="limit" :placeholder="state.lan.trade.shop.expire" />-->
      <!--      <div class="range-container">-->
      <!--        &lt;!&ndash; <input-->
      <!--          type="range"-->
      <!--          name=""-->
      <!--          id=""-->
      <!--          step="25"-->
      <!--          min="0"-->
      <!--          max="100"-->
      <!--          value="0"-->
      <!--        /> &ndash;&gt;-->
      <!--        <inputrange id="range" />-->
      <!--        <div class="precentage">-->
      <!--          <span>0%</span>-->

      <!--          <span>100%</span>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="advanced">-->
      <!--        <button @click.prevent="advancedDrop = !advancedDrop">-->
      <!--          <span>-->
      <!--            {{ state.lan.trade.shop.advanced }}-->
      <!--            <img-->
      <!--              src="../../../../assets/Public/selector.svg"-->
      <!--              alt=""-->
      <!--              width="14"-->
      <!--              :class="{ trans: advancedDrop }"-->
      <!--            />-->
      <!--          </span>-->
      <!--        </button>-->
      <!--        <div class="advanced-drop" v-if="advancedDrop">-->
      <!--          <span-->
      <!--            >{{ state.lan.trade.shop.total }} :-->
      <!--            {{ state.lang === "fa" ? toman : usd-->
      <!--            }}{{-->
      <!--              state.lang === "fa" ? state.lan.trade.shop.toman : usdt-->
      <!--            }}</span-->
      <!--          >-->
      <!--          <span>{{ state.lan.trade.shop.fee }}: {{ coins[1].change }}</span>-->
      <!--          <span class="in-out"-->
      <!--            >{{-->
      <!--              buy ? state.lan.trade.shop.outcome : state.lan.trade.shop.income-->
      <!--            }}-->
      <!--            <span>{{ coins[1].weeklyChanges }}{{ selectEN }}</span></span-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <button-->
      <!--        class="submit"-->
      <!--        :class="{ 'active-buy': buy, 'active-sell': sell }"-->
      <!--      >-->
      <!--        {{ buy ? state.lan.trade.shop.buy : state.lan.trade.shop.sell }}-->
      <!--        {{ state.lang === "fa" ? $coinLabel[selectcoin] : selectcoin }}-->
      <!--      </button>-->


      <Buy v-if="buy" :limit="limit" :cash="cashBuy" :selectcoin="selectcoin" :detail="detail"/>
      <Sell v-if="sell" :limit="limit" :cash="cashSell" :selectcoin="selectcoin" :detail="detail"/>
    </div>
  </div>
</template>

<script>
import crypto from "@/library/crypto";
import Inputrange from "../Inputrange.vue";
import {tradeTo, tradeFrom} from "@/library/reuseableFunction";
import TransactionModal from "@/components/Profile/Trade/TransactionModal.vue";
import Buy from "@/components/Profile/Trade/Shop/Buy.vue";
import Sell from "@/components/Profile/Trade/Shop/Sell.vue";

export default {
  components: {Sell, Buy, TransactionModal, Inputrange},
  name: "Shop",
  component: {
    Inputrange,
  },
  watch: {
    $route() {
      this.getCoins();
      this.getWalletData();
    }
  },
  data() {
    return {
      coins: crypto,
      transaction: false,
      limit: true,
      advancedDrop: false,
      market: false,
      selectcoin: "",
      amount: '',
      cashBuy :0,
      cashSell:0,
      unitPrice: '',
      cash: null,
      eDate: '',
      selectEN: "",
      toman: 12000000,
      usd: 1298,
      usdt: "USDT",
      logo: "",
      tableMarket: false,
      buy: true,
      sell: false,
      detail: '',

      TomanCoin: 'market_type=BITCOIN_TOMAN' +
          '&market_type=BITCOIN_CASH_TOMAN' +
          '&market_type=ETHEREUM_TOMAN' +
          '&market_type=CLASSIC_ETHEREUM_TOMAN' +
          '&market_type=LITE_COIN_TOMAN' +
          '&market_type=BINANCE_COIN_BSC_TOMAN' +
          '&market_type=EOS_TOMAN' +
          '&market_type=STELLAR_TOMAN' +
          '&market_type=TRON_TOMAN' +
          '&market_type=DASH_TOMAN' +
          '&market_type=RIPPLE_TOMAN' +
          '&market_type=DOGE_COIN_TOMAN' +
          '&market_type=MATIC_TOMAN' +
          '&market_type=CELO_TOMAN' +
          '&market_type=TETHER_TOMAN',
      TetherCoin: 'market_type=BITCOIN_TETHER' +
          '&market_type=BITCOIN_CASH_TETHER' +
          '&market_type=ETHEREUM_TETHER' +
          '&market_type=CLASSIC_ETHEREUM_TETHER' +
          '&market_type=LITE_COIN_TETHER' +
          '&market_type=BINANCE_COIN_BSC_TETHER' +
          '&market_type=EOS_TETHER' +
          '&market_type=STELLAR_TETHER' +
          '&market_type=TRON_TETHER' +
          '&market_type=RIPPLE_TETHER' +
          '&market_type=DOGE_COIN_TETHER' +
          '&market_type=MATIC_TETHER' +
          '&market_type=CELO_TETHER' +
          '&market_type=DASH_TETHER',
    };
  },
  computed:{
    tradeFrom,
    tradeTo
  },
  mounted() {
    this.getWalletData();
    this.getCoins();
  },
  methods: {
    selectItem(index, item) {
      this.selectcoin = item.relatedCoin;
      this.$router.push('/trade/' + this.$route.params.tradeTo + '/' + this.$Ex[item.relatedCoin]);
      this.tableMarket = false;
    },
    activeBtn() {
      if (this.sell) {
        this.buy = true;
        this.sell = false;
      } else if (this.buy) {
        this.sell = true;
        this.buy = false;
      }
    },
    async getCoins() {
      if (this.$route.params.tradeTo == 'TOMAN') {
        let res = await this.$axios('/trades/market-info?'

            + this.TomanCoin


            , {
              params: {
                weeklyDataIncluded: true,
              }
            });

        res = res.content;
// console.log(res)
        this.selectcoin = this.$route.params.tradeFrom;

        const detail = res.filter(item => {
          return this.$Ex[item.relatedCoin] == this.$route.params.tradeFrom
        })

        this.detail = detail[0]
        this.coins = res;
      } else if (this.$route.params.tradeTo == 'TETHER') {
        let res = await this.$axios('/trades/market-info?'

            + this.TetherCoin


            , {
              params: {
                weeklyDataIncluded: false,
              }
            });

        res = res.content;
        this.selectcoin = res[0].relatedCoin
        this.coins = res;
      }


    },
    async getWalletData() {
      this.state.loading = true;
      const res = await this.$axios(`/wallets/customer-addresses`)
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contents = entireRes
      this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
      this.wallets = entireRes.filter(e => e[0] !== "TOMAN")
      let sum = 0;


      this.$route.params.tradeTo ==='TETHER'?this.cashBuy = res['TETHER'].credit:this.cashBuy = res['TOMAN'].credit
      this.cashSell = res[this.$route.params.tradeFrom].credit
// console.log(this.cash)


    },
  },
};
</script>

<style lang="scss" scoped>
//transform selector
.active {
  color: #468dff;
}

.trans {
  transform: rotate(180deg);
  fill: var(--light-blue);
}

.shop {
  padding: clamp(0px, 1vw, 16px);
  display: flex;
  flex-direction: column;
}

.list {
  position: relative;

  button {
    height: 55px;
    background: #13132e;
    border-radius: 0 !important;
    padding: 0 16px;

    span {
      display: flex;
      align-self: center;
      gap: 8px;
      align-items: center;
    }

    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.rtl {
  td:first-child {
    text-align: right;
  }

  td:last-child {
    text-align: left;
  }
}

.ltr {
  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.tr-head {
  td {
    font-size: clamp(9px, 1vw, 11px);
    color: var(--gray);
    border-bottom: 1px solid var(--gray);
    height: 30px;
  }
}

.mt-container {
  z-index: 10;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  top: 55px;
  left: 0;
  padding: 16px;
  background: #201e37;
  width: 100%;
}

.tr-body {
  td:nth-child(2) {
    text-align: center;
  }

  td:first-child {
    cursor: pointer;

    span {
      align-items: center;
      display: flex;
      gap: 8px;
      width: 100px;
      align-self: center;
    }
  }

  td {
    height: 56px;
    font-size: clamp(10px, 1vw, 13px);
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 32px;
  background: linear-gradient(180deg, #151739 0%, #191b34 100%);
  border: 0.8px solid rgba(83, 79, 79, 0.5);
}

.buttons {
  padding: 6px;
  display: flex;
  justify-content: space-around;
  flex-grow: 2;
  flex-basis: 200px;
  max-width: 286px;
  max-height: 44px;
  background: #585670;
  border-radius: 12px;

  button {
    gap: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: 36px;
    width: 50%;
  }
}

.buttons-orders {
  display: flex;
  gap: 12px;

  button {
    font-size: clamp(10px, 1vw, 13px);
    max-width: 97px;
    min-width: 44px;
    padding: 0 8px;
    height: 30px;
  }
}

.sell {
  background: #191730;
  border-radius: 12px;
  color: #d01818;
}

.buy {
  background: #191730;
  border-radius: 12px;
  color: #18d04b;
}

.activate {
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active {
  white-space: nowrap;
  font-size: clamp(10px, 1vw, 13px);
  border-radius: 20px;
  border: 1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color: rgba(61, 61, 61, 0.98);
  padding: 0;
}

.input-container {
  label {
    font-size: clamp(10px, 1vw, 13px);
    color: #fff1f1;
  }
}

.input-norm {
  span {
    font-size: clamp(10px, 1vw, 13px);
    color: var(--gray);
  }

  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  background: #131330;
  align-items: center;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;

  input {
    width: 50%;
  }
}

.range-container {
  display: flex;
  flex-direction: column;
  gap: 2px;

  input {
    width: 100%;
    direction: ltr !important;
  }
}

.precentage {
  display: flex;
  width: 100%;
  justify-content: space-between;
  direction: ltr !important;
  font-size: clamp(10px, 1vw, 13px);
}

.label-cont {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.advanced {
  min-height: 44px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  button {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 46px;
  }

  .advanced-drop {
    //top: 30px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //position: absolute;
    font-size: clamp(10px, 1vw, 13px);
  }
}

.in-out {
  display: flex;
  gap: 27px;
}

.submit {
  font-size: clamp(12px, 1vw, 16px);
}

.active-sell {
  background: #f53d3d;
  border-radius: 12px;
  height: 40px;
}

.active-buy {
  background: linear-gradient(
          91.97deg,
          #4fcd72 0%,
          #13c144 60.3%,
          #6ed08a 107.2%
  );
  border-radius: 12px;
  height: 40px;
}
</style>
