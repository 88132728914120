<template>
  <div class="thistory">
    <Cancelmodal v-if="cancel" :id="id" @closeModal="cancel=false, getOrders()" />
    <div class="buttons">
      <button :class="{'activate': active , 'not-active' : active===false }"
              @click.prevent="active =true , histories=false">{{ state.lan.trade.orders.history }}
      </button>
      <button :class="{'activate': histories , 'not-active' : histories===false }"
              @click.prevent="histories =true , active=false">{{ state.lan.trade.orders.active }}
      </button>
    </div>


    <div class="table-containers" v-if="active">
      <table>
        <tr class="tr-head" :class="{'rtl':state.lang==='fa' , 'ltr':state.lang==='en'}">
          <td class="tablet">{{ state.lan.trade.orders.time }}</td>
          <td>{{ state.lan.trade.orders.trade }}</td>
          <td>{{ state.lan.trade.orders.am }}</td>
          <td class="tablet">{{ state.lan.trade.orders.tot }}</td>
          <td class="tablet">{{ state.lan.trade.orders.price }}</td>
          <td>{{ state.lan.trade.orders.fill }}</td>
          <td>{{ state.lan.trade.orders.stat }}</td>
        </tr>
        <tr class="tr-body" v-for="(item, index) in contents" :key="index">
          <td class="tablet">{{ $J2G(item.createdAtDateTime) }}</td>
          <td>
            {{
              item.orderType.includes('BUY') ? `${state.lang === 'fa' ? $coinLabel[item.destinationCoin] : item.destinationCoin}/ ${state.lang === 'fa' ? $coinLabel[item.sourceCoin] : item.sourceCoin}` :
                  `${state.lang === 'fa' ? $coinLabel[item.sourceCoin] : item.sourceCoin}/ ${state.lang === 'fa' ? $coinLabel[item.destinationCoin] : item.destinationCoin}`
            }}


          </td>
          <td class="tablet"
              :class="{'positive':item.orderType.includes('BUY') , 'negative': item.orderType.includes('SELL')}">
            {{
              state.lang === 'fa' ? $orderType[item.orderType] : item.orderType.toLowerCase().replace('_', ' ')
            }}

          </td>
          <td class="text-center phone">
          <span
              class="positive"
              :class="{ negative: item.amount === 'sell' }"
          >{{
              item.amount
            }}</span
          >/{{ item.executedAmount }}
          </td>
          <td class="phone">{{ item.unitPrice }}</td>
          <td class="phone">{{ item.wholePrice }}</td>
          <td class="tablet">{{ state.userInfo.fee }} %</td>
          <td
              :class="{
            positive: item.orderStatus.includes('FINISHED') ,
            negative: item.orderStatus.includes('IS_OPEN') || item.orderStatus.includes('CANCELLED')
          }"
          >
            {{ state.lang === "fa" ? $orderStatus[item.orderStatus] : item.orderStatus }}
          </td>
        </tr>
      </table>
    </div>


    <div class="table-containers" v-if="histories">
      <table>
        <tr class="tr-head" :class="{'rtl':state.lang==='fa' , 'ltr':state.lang==='en'}">
          <td class="tablet">{{ state.lan.trade.orders.time }}</td>
          <td>{{ state.lan.trade.orders.trade }}</td>
          <td>{{ state.lan.trade.orders.am }}</td>
          <td class="tablet">{{ state.lan.trade.orders.tot }}</td>
          <td class="tablet">{{ state.lan.trade.orders.price }}</td>
          <td>{{ state.lan.trade.orders.fill }}</td>
          <td>{{ state.lan.trade.orders.action }}</td>
        </tr>
        <tr class="tr-body" v-for="(item,index) in activeContents" :key="index"
            :class="{'rtl':state.lang==='fa' ,'ltr':state.lang==='en' }">
          <td class="tablet">{{ $J2G(item.createdAtDateTime) }}</td>
          <td>
            {{
              item.orderType.includes('BUY') ? `${state.lang === 'fa' ? $coinLabel[item.destinationCoin] : item.destinationCoin}/ ${state.lang === 'fa' ? $coinLabel[item.sourceCoin] : item.sourceCoin}` :
                  `${state.lang === 'fa' ? $coinLabel[item.sourceCoin] : item.sourceCoin}/ ${state.lang === 'fa' ? $coinLabel[item.destinationCoin] : item.destinationCoin}`
            }}


          </td>
          <td class="tablet"
              :class="{'positive':item.orderType.includes('BUY') , 'negative': item.orderType.includes('SELL')}">
            {{
              state.lang === 'fa' ? $orderType[item.orderType] : item.orderType.toLowerCase().replace('_', ' ')
            }}

          </td>
          <td class="text-center phone">
          <span
              class="positive"
              :class="{ negative: item.amount === 'sell' }"
          >{{
              item.amount
            }}</span
          >/{{ item.executedAmount }}
          </td>
          <td class="phone">{{ item.unitPrice }}</td>
          <td class="phone">{{ item.wholePrice }}</td>
          <td class="tablet">{{ state.userInfo.fee }} %</td>
          <td class="negative" @click.prevent="cancelOrder(item)"> {{ state.lan.trade.orders.cancel }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Cancelmodal from "@/components/Profile/Trade/Cancelmodal";


export default {
  name: "TradeHistory",
  components: {
    Cancelmodal
  },
  data() {
    return {

      active: true,
      histories: false,
      cancel: false,
      activeBody: [
        {
          time: '22/2/22',
          type: 'sell',
          limit: 'limit',
          amount: 0.00045,
          total: 7428756,
          price: 7428756,
          filled: 0,
          status: 'Completed'
        },
        {
          time: '22/2/22',
          type: 'sell',
          limit: 'limit',
          amount: 0.00045,
          total: 7428756,
          price: 7428756,
          filled: 0,
          status: 'Completed'
        },
        {
          time: '22/2/22',
          type: 'sell',
          limit: 'limit',
          amount: 0.00045,
          total: 7428756,
          price: 7428756,
          filled: 0,
          status: 'Completed'
        },
        {
          time: '22/2/22',
          type: 'sell',
          limit: 'limit',
          amount: 0.00045,
          total: 7428756,
          price: 7428756,
          filled: 0,
          status: 'Completed'
        },

      ],
      contents: [],
      activeContents:[],
      loading: false,
      page: 0,
      lastPage: 1,
      id: null,
    }
  },
  methods: {





    cancelOrder(item){
    this.cancel=true;
    this.id=item.id;
    console.log(item.id)
      },





    async getOrders() {
      this.state.loading = true;
      if (this.page <= this.lastPage && !this.loading) {
        //for scroll loading
        this.loading = true;
        this.page++; //for scroll loading
        const [res] = await this.$http.get("/orders", {
          params: {
            size: 1000,
            page: this.page,


          },
        });

        if (res) {
          this.lastPage = res.totalPages;
          this.contents = res.content;//for scroll loading
          this.activeContents = res.content.filter(item => item.orderStatus == 'IS_OPEN')
          // this.tableContents = res.content //for pagination
        }
        this.loading = false;
      } //for scroll loading
      this.state.loading = false;
      // console.table(this.p2pContents)


    }
  },
  mounted() {
    this.getOrders();
  }


}
</script>

<style lang="scss" scoped>

.negative {
  cursor: pointer;
}

//buttons that changes the tables

.rtl {
  td:first-child {
    text-align: right;
  }

  td:last-child {
    text-align: left;
  }
}

.ltr {
  td:first-child {
    text-align: left;
  }

  td:last-child {
    text-align: right;
  }
}

.buttons {
  display: flex;
  gap: 15px;

  button {
    min-width: 44px;
    padding: 0 12px;
    height: 30px;
  }
}

//activation of buttons
.activate {
  color: white;
  background: rgba(211, 211, 211, 0.48);
  border-radius: 20px;
}

.not-active {
  border-radius: 20px;
  border: 1px solid rgba(61, 61, 61, 0.98);
  background: transparent;
  color: rgba(61, 61, 61, 0.98);

}

.table-containers {
  border-radius: 8px;
  padding: 8px;
  max-height: 200px;
  overflow: auto;
  background: #191730;
  position: relative;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;

}

.tr-body {
  td {
    height: 38px;
  }
}

.tr-head, .tr-body {
  font-size: clamp(10px, 1vw, 13px);
  width: 100%;
  //display: flex;
  //justify-content: space-between;
  text-align: center;

  height: 30px;
}

.tr-head {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  color: var(--gray);
  position: sticky;
  top: 0;
  background: #191730;

}

.tr-body:nth-child(3), .tr-body:last-child {
  background: #211F3D !important;
}

.thistory {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-height: 196px;
}


.tablet {
}

.phone {
}


@media screen and (max-width: 1100px) {
  .table-container {
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 546px) {
  .phone {
    display: none;
  }
}

</style>
