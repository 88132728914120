<template>
  <div class="invite-friends">
    <div class="first-col">
      <div class="title">
        <p>{{ state.lan.intive.text }}</p>
      </div>
      <div class="ref-container">
        <button @click.prevent="copyCode">
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_119_2275)">
              <path
                d="M19.748 0.5H9.66502C8.20039 0.5 7.00877 1.69162 7.00877 3.15625V3.48225H5.25195C3.78732 3.48225 2.5957 4.67387 2.5957 6.1385V21.8439C2.5957 23.3085 3.78732 24.5001 5.25195 24.5001H15.3349C16.7996 24.5001 17.9911 23.3085 17.9911 21.8439V21.5179H19.7479C21.2125 21.5179 22.4041 20.3262 22.4041 18.8616V3.15625C22.4042 1.69162 21.2126 0.5 19.748 0.5ZM16.2778 21.8439C16.2778 22.3637 15.8548 22.7867 15.335 22.7867H5.25195C4.7321 22.7867 4.30912 22.3637 4.30912 21.8439V6.13838C4.30912 5.61853 4.7321 5.19555 5.25195 5.19555H15.3349C15.8548 5.19555 16.2776 5.61853 16.2776 6.13838V21.8439H16.2778ZM20.6908 18.8616C20.6908 19.3815 20.2678 19.8044 19.748 19.8044H17.9912V6.13838C17.9912 4.67376 16.7996 3.48214 15.335 3.48214H8.72218V3.15613C8.72218 2.63628 9.14517 2.2133 9.66502 2.2133H19.748C20.2678 2.2133 20.6908 2.63628 20.6908 3.15613V18.8616Z"
                fill="#CACACE"
              />
            </g>
            <defs>
              <clipPath id="clip0_119_2275">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.5 0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          {{ Code }}
        </button>
        <div class="refs">
          <p>{{ state.lan.intive.linktitle }}</p>
          <div class="links" @click.prevent="copyLink">
            <p>{{ Link }}</p>
            <svg
              width="20"
              height="24"
              viewBox="0 0 20 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.248 0H7.16502C5.70039 0 4.50877 1.19162 4.50877 2.65625V2.98225H2.75195C1.28732 2.98225 0.0957031 4.17387 0.0957031 5.6385V21.3439C0.0957031 22.8085 1.28732 24.0001 2.75195 24.0001H12.8349C14.2996 24.0001 15.4911 22.8085 15.4911 21.3439V21.0179H17.2479C18.7125 21.0179 19.9041 19.8262 19.9041 18.3616V2.65625C19.9042 1.19162 18.7126 0 17.248 0ZM13.7778 21.3439C13.7778 21.8637 13.3548 22.2867 12.835 22.2867H2.75195C2.2321 22.2867 1.80912 21.8637 1.80912 21.3439V5.63838C1.80912 5.11853 2.2321 4.69555 2.75195 4.69555H12.8349C13.3548 4.69555 13.7776 5.11853 13.7776 5.63838V21.3439H13.7778ZM18.1908 18.3616C18.1908 18.8815 17.7678 19.3044 17.248 19.3044H15.4912V5.63838C15.4912 4.17376 14.2996 2.98214 12.835 2.98214H6.22218V2.65613C6.22218 2.13628 6.64517 1.7133 7.16502 1.7133H17.248C17.7678 1.7133 18.1908 2.13628 18.1908 2.65613V18.3616Z"
                fill="#CACACE"
              />
            </svg>
          </div>
          <p v-if="alertLink" class="positive">{{ state.lan.intive.copied }}</p>
        </div>
      </div>
    </div>
    <div class="second-col" :class="{ rtl: state.lang === 'fa' }">
      <img src="../../../assets/profile/invite-img.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "invitefriends",
  props:{
    Code:'',
    Link:'',
  },
  data() {
    return {
      link: "https://www.coinex.com/register?refer_code=",
      // code: 2345,
      alertLink: false,
    };
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.Link);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
    copyCode() {
      navigator.clipboard.writeText(this.Code);
      this.alertLink = true;
      setTimeout(() => {
        this.alertLink = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.rtl {
  transform: scale(-1, 1);
}

.links {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  background: #131330;
  border: 0.6px solid rgba(179, 176, 176, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  p {
    color: var(--gray);
  }
}
.invite-friends {
  position: relative;
  padding: clamp(12px, 2vw, 24px);
  display: flex;
  justify-content: space-between;
  background: #04235c;
  border-radius: 12px;
}
.first-col {
  justify-content: space-between;
  padding-bottom: clamp(24px, 2vw, 45px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  .title {
    p {
      max-width: 500px;
    }
    .second-col {
      position: absolute;
    }
  }
}
.ref-container {
  display: flex;

  flex-direction: column;
  border-radius: 12px;
  gap: 32px;
  padding: clamp(12px, 1.5vw, 24px);
  border: 1px solid #cacace;
  border-radius: 12px;
  button {
    background: #2850a8;
    border-radius: 10px;
    height: 51px;
    align-self: center;
    align-items: center;
    padding: 8px 12px;
    display: flex;
    gap: 14px;
  }
}

.refs{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.second-col {
  img {
    max-width: 300px;
    max-height: 466px;
  }
}

@media (max-width: 640px) {
  .second-col {
    display: none;
  }
}
</style>
