<template>
<div class="container">
  <div class="p2p">
    <div class="shop"><Shop :key="$route.params + ''"/></div>
    <div class="main">
<!--      <MarketCap/>-->
      <Maintrade/>
    </div>
  </div>
</div>
</template>

<script>
import Shop from "@/components/Profile/Trade/Shop/Shop.vue";
import Maintrade from "@/components/Profile/Trade/Maintrade.vue";
import MarketCap from "@/components/Profile/Trade/MarketCap.vue";


export default {
  name: "P2P",
  components: {
    MarketCap, Shop,
    Maintrade}
}
</script>

<style lang="scss" scoped>
.p2p{
  //flex-wrap: wrap;
  padding: 12px;
  display: flex;
  gap: 7px;
}
.shop{

width: 100%;
  max-width: 348px;
}
.main{
  display: flex;
  flex-direction: column;
  gap: 8px;
  //width: 100%;
  //max-width: 1053px;
  flex-grow: 1;
}


@media (max-width: 1025px) {
  .p2p{
    flex-wrap: wrap;
    gap: 30px;
  }
  .shop{
    margin: 0 auto;
    max-width: 500px;
  }

}
@media(max-width: 400px){
  .shop{
    margin-top: 5px;
  }
}
</style>