<template>
  <div class="footer">
    <div class="content">
      <div class="first-col">
        <img src="../../assets/Public/footerLogo.svg" alt="" width="190"
             height="48">
        <p>{{ state.lan.footer.logotitle }}</p>
      </div>
      <div class="second-col">
        <div class="exchange">
          <ul>
            <li class="title">{{ state.lan.footer.exchange }}</li>
            <li><a @click.prevent="$router.push('/')" class="link" href="">{{ state.lan.footer.home }}</a></li>
            <li><a @click.prevent="$router.push('/market')" class="link" href="">{{ state.lan.footer.market }}</a></li>
            <li><a @click.prevent="$router.push('/sign-up')" class="link" href="">{{ state.lan.footer.signup }}</a></li>
          </ul>
        </div>
        <div class="site">
          <ul>
            <li class="title">{{ state.lan.footer.section }}</li>
            <li><a @click.prevent="$router.push('/blog')" class="link" href="">{{ state.lan.footer.blog }}</a></li>
            <li><a @click.prevent="$router.push('/about-us')" class="link" href="">{{ state.lan.footer.aboutus }}</a></li>
            <li><a @click.prevent="$router.push('/fees')" class="link" href="">{{ state.lan.footer.fees }}</a></li>
            <li><a @click.prevent="$router.push('/terms')" class="link" href="">{{ state.lan.footer.terms }}</a></li>
          </ul>
        </div>
        <div class="guide">
          <ul>
            <li class="title">{{ state.lan.footer.guide }}</li>
            <li><a class="link" @click.prevent="$router.push('/guide')" href="">{{ state.lan.footer.guides }}</a></li>
            <li><a class="link" @click.prevent="$router.push('/guide')" href="">{{ state.lan.footer.faq }}</a></li>
          </ul>
        </div>
      </div>
      <div class="third-col">
        <p class="title">{{state.lan.footer.contactus}}</p>
        <p>{{info.number}}</p>
        <p>{{state.lan.footer.answer}}</p>
        <div class="social">
          <a :href="info.facebook"><img src="../../assets/Public/facebook.png" alt=""></a>
          <a :href="info.whatsapp"><img src="../../assets/Public/whatsapp.png" alt=""></a>
          <a :href="info.telegram"><img src="../../assets/Public/telegram.png" alt=""></a>
          <a :href="info.instagram"><img src="../../assets/Public/instagram.png" alt=""></a>
        </div>
      </div>
    </div>
    <!-- <img src="../../assets/Public/selogo.png" alt="" :class="{'se':state.lang==='fa', 'se-ltr':state.lang==='en'}"> -->
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      info:{},
      // exchange:[
      //
      //   {
      //     title: this.footer.home,
      //     address: '/'
      //   },
      //   {
      //     title: this.footer.market,
      //     address: '/market'
      //   },
      //   {
      //     title: this.footer.signup,
      //     address: '/sign-up'
      //   }
      // ]
    }
  },
  methods: {
    async getInfo(){
        const res = await this.$axios.get('/exchange-info')
            this.info = res.baseDTO || {
                address: '',
                appAndroid: '',
                appIOS: '',
                email: '',
                instagram: '',
                linkedin: '',
                number: '',
                telegram: '',
                twitter: ''
            }
    }
  },
  mounted() {
    this.getInfo()
  },
};
</script>

<style lang="scss" scoped>
.social{
  display: flex;
  gap: 29px;
}
.se{
  left: 20px;
  bottom: 20px;
  position: absolute;
}
.se-ltr{
  right: 20px;
  bottom: 20px;
  position: absolute;
}
.footer {
  position: relative;
  flex-wrap: wrap;
  min-height: 280px;
  width: 100%;
  background: linear-gradient(115.97deg, rgba(56, 59, 131, 0.57) 0%, #07092D 106.55%);
}
.content {
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 30px;
}
.title {
  font-size: clamp(10px, 1vw, 13px);
  font-weight: 700;
  margin-bottom: 4px;
}

.link {
  font-size: clamp(10px, 1vw, 13px);
  color: rgba(255, 255, 255, 0.7)
}

ul {
  //min-width: 200px;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

//main container of footer




.first-col, .third-col {

  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //row-gap: 16px;

}

.second-col {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 40px;
  display: flex;
  //column-gap: 112px;
  flex: 1;

  .exchange,

 .guide {
  flex: 1.2;
}

  .site {
    flex: 1.3;
  }
}

.first-col {
  p {
    font-size: clamp(12px, 1vw, 18px);
    color: var(--gray);
  }

  .second-col {

  }
}


.third-col{
  align-self: start;
  margin-top: 17px;
  gap: 16px;
  .title{
    align-self: flex-start;
  }
}
@media (max-width: 850px) {
  .first-col{
    display: none;
  }
//.second-col{
//  flex-direction: column;
//}
  
}

@media (max-width: 627px){
  .se , .se-ltr{
    display: none;
  }
  .content{
    flex-direction: column;
  }
  .second-col{
    width: 100%;
  }
}

@media(max-width: 425px){
  .third-col{
    align-self: center;
  }
  .footer{
    min-height: unset;
  }
  .second-col{
    flex-direction: column;
    gap: 2px;
    .guide{
      display: none;
    }
    .exchange{
      display: none;
    }
    .site{
      display: none;
    }
  }
  //.content{
  //  flex-direction: row;
  //}

}
</style>
