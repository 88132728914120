<template>
  <div class="inventory">
    <div class="inventory-container">
      <div class="title">
        <p>
          {{
            state.lan.wallet.overall
          }}<span><img src="../../../assets/profile/eye.svg" alt=""/></span>
        </p>
        <p>
          {{ overall.toLocaleString() }}
          <span> {{ state.lan.wallet.usdt }} </span>
        </p>
      </div>
      <div class="wallets">
        <div class="wallet-container tetheric">
          <div class="balance">
            <img src="../../../assets/profile/teterwallet.svg" alt=""/>
            <div class="evals">
              <p>{{ state.lan.wallet.balanceteter }}</p>
              <p>
                {{ $toLocal(tethercash,$decimal['TETHER']) }}
                <span> {{ state.lan.wallet.usdt }} </span>
              </p>
            </div>
          </div>
          <div class="buttons">
            <button
                class="dipo"
                @click.prevent="$router.push('/profile/crypto-dipo/TETHER')"
            >
              {{ state.lan.wallet.dipositt }}
            </button>
            <button
                class="wit"
                @click.prevent="$router.push('/profile/crypto-wit/TETHER')"
            >
              {{ state.lan.wallet.withdrawt }}
            </button>
            <button class="trade" @click.prevent="$router.push('/trade')">
              {{ state.lan.wallet.trade }}
            </button>
          </div>
        </div>
        <div class="wallet-container tomanic" v-if="state.lang==='fa'">
          <div class="balance">
            <img src="../../../assets/profile/tomanwallet.svg" alt=""/>
            <div class="evals">
              <p>{{ state.lan.wallet.balancetoman }}</p>
              <p>
                {{ $toLocal(tomancash,$decimal['TOMAN']) }}
                <span> {{ state.lan.wallet.usdt }} </span>
              </p>
            </div>
          </div>
          <div class="buttons">
            <button
                class="dipo"
                @click.prevent="$router.push('/profile/toman-dipo/TOMAN')"
            >
              {{ state.lan.wallet.dipositt }}
            </button>
            <button
                class="wit"
                @click.prevent="$router.push('/profile/toman-wit/TOMAN')"
            >
              {{ state.lan.wallet.withdrawt }}
            </button>
            <button class="trade" @click.prevent="$router.push('/trade')">
              {{ state.lan.wallet.trade }}
            </button>
          </div>
        </div>
        <div class="wallet-container hidewal" v-if="state.lang==='en'">
          <div class="balance">
            <img src="../../../assets/profile/tomanwallet.svg" alt=""/>
            <div class="evals">
              <p>{{ state.lan.wallet.balancetoman }}</p>
            </div>
          </div>
          <div class="buttons tradebtn">
            <button class="trade trades">Activate Wallet</button>
          </div>
        </div>
      </div>
    </div>
    <div class="apex-chart">
      <p>{{ state.lan.wallet.apextitle }}</p>
      <div class="chart">
        <apexchart
            class="apex"
            type="donut"
            width="280"
            height="280"
            :options="chartOptions"
            :series="series"
            :key="series.length + 'P2P' + chartOptions.labels.length + change"
        ></apexchart>
      </div>
      <div class="values"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "WalletInventory",
  props:['tomancash','tethercash','overall'],
  data() {
    return {
      balance: null,
      statistics: '',
      max: null,
      open: '',
      contents: [],
      chartData: '',
      series: [0],
      change: 0,
      wallets: {},
      chartOptions: {
        chart: {
          width: '50px',
          fontFamily: 'poppins',
        },
        colors: [
          '#3f51b5', '#03a9f4', '#4caf50', '#f9ce1d', '#FF9800', '#00E396', '#FF4560', '#775DD0', '#5C4742', '#2b908f', '#A300D6', '#C5D86D'
        ],
        theme: {
          mode: 'light',
          palette: 'palette2'
        },
        stroke: {
          width: 1,
          colors: undefined
        },
        labels: [],
        dataLabels: {
          style: {
            fontFamily: 'Poppins'
          }
        },
        legend: {
          show: false,
        },
        plotOptions: {
          pie: {
            offsetX: 0,
            offsetY: 0,
            donut: {
              size: '65%',
              background: '#001E3D',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'yekan',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  offsetY: -17,
                  formatter: function (val) {
                    return val.name || val
                  }
                },
                value: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'yekan',
                  fontWeight: 400,
                  color: '#F2F2F2',
                  offsetY: 10,
                  formatter: function (val) {
                    return parseFloat(val).toLocaleString()
                  }
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: this.state.lang === 'fa' ? 'ارزش دارایی ها' : 'Total Value',
                  fontSize: '12px',
                  fontFamily: this.state.lang=='fa' ? 'yekan' : 'Poppins',
                  fontWeight: 600,
                  color: '#F2F2F2',
                  formatter: function (w) {
                    w = w.globals.seriesTotals.reduce((a, b) => a + b)
                    return w.toLocaleString() + '\n'
                  }
                }
              }
            },
          }
        },
        tooltip: {
          enabled: true,
          fillSeriesColor: true,
          custom: function ({
                              seriesIndex,
                              w
                            }) {
            return ` ${w.globals.seriesNames[seriesIndex].value.toLocaleString()} ${w.globals.seriesNames[seriesIndex].name} `
          }
        }
      },

    }
  },
  methods: {
    async getWalletData() {
      const res = await this.$axios(`/wallets/customer-addresses?walletType=P2P`)
      let entireRes = Object.entries(res)
      const sortCoins = this.$walletSortCoins
      entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
      this.contents = entireRes
      this.chartData = this.contents.map(e => e[0] === 'TOMAN' ? e[1].totalBalance : e[0].wholePriceOfCredit)
      this.wallets = entireRes
      let sum = 0;

      for (let i = 0; i < entireRes.length; i++) {

        sum += entireRes[i][1].credit
// console.log(sum)
        this.overall = sum;

      }

      this.tomancash = res["TOMAN"].credit
      this.tethercash = res["TETHER"].credit


    },

    chart() {

      let index = 0
      this.wallets.forEach((e) => {
        this.chartOptions.labels[index] = {
          'name': e[0],
          'value': e[1].credit || 0
        }
        this.series[index] = e[1].wholePriceOfCredit || e[1].totalBalance || 0
        index++
      })
      ++this.change


      this.state.loading=false;
    },

    goto(e) {
      console.log(e)
      this.$router.push('profile/trade/' + 'TOMAN' + `/${e}`)
    },

  },
  async mounted() {
this.state.loading=true;
    await this.getWalletData()

this.chart()

this.state.loading=false;
  },
  computed: {
    credit() {
      return e => this.$toLocal(e[0] === 'TOMAN' ? e[1].credit.toFixed(0) : e[1].credit) || 0
    }
  }
};


</script>

<style lang="scss" scoped>
.hidewal {
  opacity: 0.6;

  .balance {
    .evals {
      align-self: center;
    }
  }
}

.tradebtn {
  align-items: center !important;
}

.trades {
  width: 110px !important;
  align-self: center !important;
}

.apex {
  display: flex !important;
  justify-content: center !important;
}

//inventory has two container one is overall of wallet and dipo /wit container and another is apex chart
.inventory {
  width: 100%;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.inventory-container {
  padding: clamp(12px, 2vw, 24px);
  flex-grow: 2.75;
  flex-basis: 300px;
  display: flex;
  flex-direction: column;
  gap: 27px;
  background: #191730;
  border-radius: 12px;
  min-height: 314px;
  //title container
  .title {
    display: flex;
    flex-direction: column;
    gap: 14px;

    p:first-child {
      color: var(--gray);
      font-size: clamp(10px, 1vw, 13px);
      display: flex;
      align-items: center;
      gap: 14px;

      span {
        display: flex;

        img {
          margin-top: 5px;
          align-self: center;
        }
      }
    }

    p {
      font-size: clamp(20px, 2vw, 24px);

      span {
        font-size: clamp(13px, 1vw, 16px);
        font-weight: 300;
        color: var(--gray);
      }
    }
  }
}

//tether container

.wallets {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wallet-container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;

  .balance {
    flex-grow: 1;
    padding: 12px;
    display: flex;
    gap: 12px;

    .evals {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p:first-child {
        font-size: clamp(10px, 1vw, 13px);
        font-weight: 300;
        color: var(--gray);
      }

      p:last-child {
        display: flex;
        gap: 4px;
        font-size: clamp(13px, 1vw, 16px);

        span {
          align-self: center;
          font-size: clamp(10px, 1vw, 13px);
          font-weight: 300;
          color: var(--gray);
        }
      }
    }
  }

  .buttons {
    flex-wrap: wrap;
    flex-grow: 1.1;
    justify-content: flex-end;
    padding: 12px;
    align-self: flex-end;
    display: flex;
    gap: 8px;

    button {
      width: 96px;
      height: 32px;
    }

    .dipo {
      background: linear-gradient(
              91.87deg,
              #47eb57 0%,
              #44ad62 53.83%,
              #41df55 112.8%
      );
      border-radius: 8px;
    }

    .wit {
      border: 1.6px solid #f53d3d;
      box-sizing: border-box;
      border-radius: 8px;
      background: transparent;
      color: #f53d3d;
    }

    .trade {
      border: 1.6px solid #468dff;
      box-sizing: border-box;
      border-radius: 8px;
      color: #468dff;
    }
  }
}

.tetheric {
  background: rgba(35, 63, 54, 0.33);
  border-radius: 12px;
}

.tomanic {
  background: rgba(7, 8, 33, 0.47);
  border-radius: 12px;
}

.apex-chart {
  padding: clamp(6px, 1vw, 12px);
  flex-grow: 1.25;
  flex-basis: 100px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background: #191730;
  border-radius: 12px;
  min-height: 314px;
}

@media (max-width: 439px) {
  .balance {
    justify-content: space-between;
  }
  .wallet-container {
    flex-direction: column;
  }
  .buttons {
    flex-grow: unset;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  button {
    width: 70% !important;
  }
}
</style>
<style lang="scss">
.apex-charts-legend {
  display: flex !important;
}

.apexcharts-legend-series {
  display: flex !important;
  flex-wrap: wrap;
  gap: 8px !important;
}

.apexcharts-legend-text {
  color: white !important;
  font-size: clamp(10px, 1vw, 14px) !important;
  font-weight: 400 !important;
}
</style>
