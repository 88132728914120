<template>
  <div class="info">
    <div class="userinfo">
      <div class="contorlling-icon">
        <div class="icon">
          <img v-if="state.userInfo.authorized ==true" src="@/assets/profile/kyc-ver.svg" alt=""/>
          <img v-else src="@/assets/profile/kyc.svg" alt="">
        </div>
        <p class="uname">
          <span class="name">{{
              state.userInfo.firstName
            }}</span
          ><span class="family">{{
            state.userInfo.lastName
          }}</span>
        </p>
        <p>{{ state.userInfo.email }}</p>
      </div>
    </div>
    <div
        :class="{ card: state.lang === 'en', 'card-rtl': state.lang === 'fa' }"
    >
      <div
          :class="{ 'img-rtl': state.lang === 'fa', img: state.lang === 'en' }"
      >
        <p class="level-info"
           :class="{'dark': state.userInfo.userAccountLevel =='PLATINUM' || state.userInfo.userAccountLevel ==='SILVER'}">
          {{ state.lang == 'fa' ? $levels[state.userInfo.userAccountLevel] : state.userInfo.userAccountLevel.toLowerCase() }}</p>
        <InlineSvg v-if="state.userInfo.userAccountLevel=='BRONZE'" :src="require('../../../assets/profile/bronze.svg')"

        ></InlineSvg>
        <InlineSvg v-if="state.userInfo.userAccountLevel=='SILVER'" :src="require('../../../assets/profile/silver.svg')"

        ></InlineSvg>
        <InlineSvg v-if="state.userInfo.userAccountLevel=='GOLD'" :src="require('../../../assets/profile/gold.svg')"

        ></InlineSvg>
        <InlineSvg v-if="state.userInfo.userAccountLevel=='PLATINUM'" :src="require('../../../assets/profile/plat.svg')"

        ></InlineSvg>
        <InlineSvg v-if="state.userInfo.userAccountLevel=='SUPERVISOR'" :src="require('../../../assets/profile/super.svg')"

        ></InlineSvg>

      </div>
      <div class="level">
        <p>{{ state.lan.account.cardlvl }}</p>
      </div>
      <div class="fees">
        <p>{{ state.lan.account.fee }}</p>
        <p class="fee">
          {{ state.userInfo.fee }}%<span>({{
            $toLocal(min, $decimal['TOMAN'])
          }} - {{  $toLocal(max) }})</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import InlineSvg from "vue-inline-svg"

export default {
  name: "Info",
  components: {InlineSvg},
  data() {
    return {
      levels: [],
      min: 0,
      max: 0,
      level: '',
      colors: {
        'BRONZE': '#8D4F22',
        'SILVER': '#AFB1AE',
        'GOLD': '#ECBD00',
        'PLATINUM': '#F3F3F3',
        'SUPERVISOR': '#4AA5A8'
      },
      strokes: {
        'BRONZE': '#BA7B4E',
        'SILVER': '#AFB1AE',
        'GOLD': '#ECBD00',
        'PLATINUM': '#F3F3F3',
        'SUPERVISOR': '#4AA5A8'
      },
      color: '',
      stroke: '',
      index: '',
    };
  },
  methods: {
    async getLevels() {
      const res = await this.$axios('users/account-levels');
      this.levels = res;
      const users = this.levels.sort((a, b) => {
        a.tradeAmount < b.tradeAmount
      });
      const index = users.findIndex(user => user.userAccountLevel === this.state.userInfo.userAccountLevel)
      this.index = index
      const myUser = users.find(user => this.index === users.indexOf(user))
      const pervUser = users.find(user => (this.index - 1) === users.indexOf(user))
      pervUser==undefined ?this.min =0 : this.min = pervUser.tradeAmount
      this.max = myUser.tradeAmount
      this.color = this.colors[myUser.userAccountLevel]
      this.stroke = this.strokes[myUser.userAccountLevel]
      // console.log(this.stroke)
      // var str = document.querySelector('#strokes')

      //   if(this.state.userInfo.userAccountLevel =="BRONZE"){
      //     str.fill = '#BA7B4E'
      //   }
      //   else if (this.state.userInfo.userAccountLevel == 'SILVER'){
      //     str.fill = '#AFB1AE'
      //   }
      // },

      // switch (this.state.userInfo.userAccountLevel) {
      //   case 'BRONZE':
      //     document.querySelector('#strokes').style.fill = '#BA7B4E'
      //     break;
      //   case 'SILVER':
      //     document.querySelector('#strokes').style.fill = '#AFB1AE'
      //     break;
      //     case 'GOLD':
      //       document.querySelector('#strokes').style.fill = '#ECBD00'
      //         break;
      //         case 'PLATINUM' :
      //
      //           document.querySelector('#strokes').style.fill = '#F3F3F3'
      //         break;
      //           case 'SUPERVISOR':
      //             document.querySelector('#strokes').style.fill = '#4AA5A8'
      //   default :
      //     str = '#BA7B4E'
      //     break;
      // }
    }


  },
  async mounted() {
    await this.getLevels();


  },
};
</script>

<style lang="scss" scoped>
.dark {
  color: black;
}

.contorlling-icon {
  max-width: 206px;
}

//info main container
.level-info {
  margin-top: 5px;
  font-size: clamp(8px, 1.5vw, 11px);
  align-self: center;
}

.info {
  row-gap: 20px;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  min-height: 174px;
  background: #191730;
  border-radius: 12px;
  flex-wrap: wrap;
  flex-grow: 1;
}

.userinfo {
  position: relative;
  flex-grow: 1;
  flex-basis: 100px;
  display: flex;
  flex-direction: column;

  .icon {
    display: flex;
    justify-content: flex-end;
  }

  .name,
  .family {
    font-size: clamp(20px, 2vw, 24px);
    font-weight: 400;
  }

  p:last-child {
    font-size: clamp(12px, 1vw, 16px);
    color: var(--gray);
  }

  .uname {
    display: flex;
    gap: 10px;
  }
}

.card-rtl {
  gap: 22px;
  position: relative;
  max-width: 295px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  min-height: 150px;
  border-radius: 14px;
  //align-items: flex-end;
  flex-basis: 100px;
  flex-grow: 1;
  background-image: url("../../../assets/profile/level.svg");
  background-repeat: no-repeat;

  .img-rtl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 30px;

    p {
      position: absolute;
      top: 40px;
    }

    img {
      width: 72px;
      height: 86px;
    }
  }

  .img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 30px;

    p {
      position: absolute;
      top: 45px;
    }

    img {
      width: 72px;
      height: 86px;
    }
  }
}

.card {
  gap: 31px;
  position: relative;
  max-width: 295px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 20px;
  min-height: 150px;
  border-radius: 14px;
  //align-items: flex-end;
  flex-basis: 100px;
  flex-grow: 1;
  background-image: url("../../../assets/profile/level.svg");
  background-repeat: no-repeat;

  .img-rtl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 30px;

    p {
      position: absolute;
      top: 40px;
    }

    img {
      width: 72px;
      height: 86px;
    }
  }

  .img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 30px;

    p {
      position: absolute;
      top: 45px;
    }

    img {
      width: 72px;
      height: 86px;
    }
  }
}

.level {
}

.fees {
  display: flex;
  flex-direction: column;
  gap: 0;

  .fee {
    font-size: clamp(12px, 3vw, 36px);

    span {
      font-size: clamp(10px, 1vw, 12px);
      color: var(--gray);
    }
  }
}

@media (max-width: 800px) {
}
</style>
