<template>
  <div class="history">
    <div class="title">
      <p>{{ state.lan.history.title }}</p>
      <div class="pagination">
        <p>
          {{ state.lan.history.showing }} {{currentPage}}-{{ totalPages }} {{ state.lan.history.as }} {{ totalPages }}
        </p>


        <div class="pagination-cont">
          <simple-pagination v-if="contents.length"  :pages="totalPages" :currentpage="1" @changePage="cb" vspbutton="vspButton"
                             vspbuttonseelcted="vspButton-selected" vspbuttonfast="vspButtonFast"/>
        </div>


        <!--        <div class="pagination-cont">-->
        <!--          <img src="../../../assets/profile/arrow-right.svg" alt="" id="perv" />-->
        <!--          <img src="../../../assets/profile/arrow-left.svg" alt="" id="next" />-->
        <!--          <a href=""></a>-->
        <!--          <a-->
        <!--            :class="{ 'active-page': firstPage }"-->

        <!--            >1</a-->
        <!--          >-->
        <!--          <a-->
        <!--            :class="{ 'active-page': secondPage }"-->

        <!--            >2</a-->
        <!--          >-->
        <!--          <a-->
        <!--            :class="{ 'active-page': noRecord }"-->

        <!--            >3</a-->
        <!--          >-->
        <!--          <img-->
        <!--            src="@/assets/profile/threedot.svg"-->
        <!--            alt=""-->

        <!--          />-->
        <!--          <a-->
        <!--            :class="{ 'active-page': noRecord2 }"-->

        <!--            >10</a-->
        <!--          >-->
        <!--          <a href=""></a>-->
        <!--        </div>-->
      </div>
    </div>
<div class="table-container w100">
  <table>
    <tr class="tr-head">
      <td class="tablet">{{ state.lan.history.time }}</td>
      <td>{{ state.lan.history.coin }}</td>
      <td class="phone">{{ state.lan.history.trade }}</td>
      <td class="phone">{{ state.lan.history.filled }}</td>
      <td class="phone">{{ state.lan.history.price }}</td>
      <td class="phone">{{ state.lan.history.total }}</td>
      <td class="tablet">{{ state.lan.history.wages }}</td>
      <td>{{ state.lan.history.status }}</td>
    </tr>
    <tr class="tr-body" v-for="(item, index) in contents" :key="index">
      <td class="tablet">{{ $G2J(item.createdAtDateTime) }}</td>
      <td>
        {{
          item.orderType.includes('BUY')  ? `${state.lang === 'fa' ? $coinLabel[item.destinationCoin] : item.destinationCoin}/ ${state.lang === 'fa' ? $coinLabel[item.sourceCoin] : item.sourceCoin}`:
              `${state.lang === 'fa' ? $coinLabel[item.sourceCoin] :item.sourceCoin}/ ${state.lang === 'fa' ? $coinLabel[item.destinationCoin] :item.destinationCoin}`
        }}


      </td>
      <td class="tablet" :class="{'positive':item.orderType.includes('BUY') , 'negative': item.orderType.includes('SELL')}">
        {{
          state.lang==='fa'? $orderType[item.orderType]:item.orderType.toLowerCase().replace('_', ' ')
        }}

      </td>
      <td class="text-center phone">
          <span
              class="positive"
              :class="{ negative: item.amount === 'sell' }"
          >{{
              item.amount
            }}</span
          >/{{ item.executedAmount}}
      </td>
      <td class="phone">{{ item.unitPrice }}</td>
      <td class="phone">{{ item.wholePrice }}</td>
      <td class="tablet">{{ state.userInfo.fee}} %</td>
      <td
          :class="{
            positive: item.orderStatus.includes('FINISHED') ,
            negative: item.orderStatus.includes('IS_OPEN') || item.orderStatus.includes('CANCELLED')
          }"
      >
        {{ state.lang === "fa" ? $orderStatus[item.orderStatus] : item.orderStatus }}
      </td>
    </tr>
  </table>
</div>


  </div>
</template>

<script>
import SimplePagination from '@celdotro/vue-simplepagination/dist/simple-pagination.js';

export default {
  name: "History",
  components: {
    SimplePagination,
  },
  data() {
    return {



      contents: [],
      currentPage: 1,
      totalPages: 0,
    };
  },
  methods: {
    cb(e) {
      this.currentPage = e;
      this.getOrders();
    },
    async getOrders() {
      this.state.loading=true;
      const res = await this.$axios('/orders', {
        params: {
          page: this.currentPage
        }
      })

      this.contents = res.content
this.totalPages = res.totalPages
    }
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style lang="scss" scoped>
.active-page {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #468dff;
  color: #468dff;
  border-radius: 5px;
  width: 20px;
}

table {
  max-height: 310px;
  //display: flex;
  position: relative;
  //flex-direction: column;
  gap: 19px;
  table-layout: fixed;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

table::-webkit-scrollbar {
  display: none;
}

//head of the table
.tr-head,
.tr-body {
  table-layout: fixed;
  //display: flex;
  //align-items: center;
  column-gap: 10px;
  //width: 100% !important;
  font-size: clamp(10px, 1vw, 13px);
  text-align: center;
}

td {
height: 45px;
}

//body of the table
.tr-body:last-child {
  border: none;
}

.tr-body {
  height: 38px;

  border-collapse: collapse;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);

  td {
    margin-bottom: 20px;
  }
}

.tr-head {
  position: sticky;
  top: 0;
  background: #191730;

  td:nth-child(3) {
    text-align: center;
  }
}

//main container
.history {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #191730;
  border-radius: 12px;
  padding: 24px;
}

//title and pagination stands in this container
.title {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  //this is the title
  p {
    font-size: clamp(20px, 2vw, 24px);
  }
}

//pagination is here
.pagination {
  p {
    font-size: clamp(10px, 1vw, 13px);
    color: var(--gray);
  }

  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.pagination-cont {
  position: relative;
  display: flex;
  background: #48474e;
  gap: 20px;
  align-items: center;
  padding: 0 10px;
  border-radius: 12px;
  height: 30px;

  p {
    //margin: 0 10px;
    font-size: clamp(12px, 2vw, 16px);
    color: white;
  }

  #perv {
    position: absolute;
    right: 10px;
  }

  #next {
    position: absolute;
    left: 10px;
  }
}

//tablet and phone view
.tablet {
}

.phone {
}

@media screen and (max-width: 1100px) {
  .table-container {
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 546px) {
  .phone {
    display: none;
  }
}
</style>
<style>
.vspButton {
  height: 22px;
  padding: 2px 7px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: transparent;
}

button[class="vspButton"]:hover {
  background-color: transparent;
  border: 1px solid #2850a8;
}

.vspButton-selected {
  background-color: transparent;
  border: 1px solid #2850a8 ;
}

.vspButtonFast {
  background-color: transparent;
  color: #b5b9bc;
}
</style>
