<template>
  <div class="dashboard">
    <div class="userinfo"><Userinfo/></div>
    <div class="tradevol" ><Tradevol/></div>
    <div class="dashtable"><Dashtable/></div>
  </div>
</template>

<script>
import Userinfo from "@/components/Profile/Dashboard/Userinfo";
import Tradevol from "@/components/Profile/Dashboard/Tradevol";
import Dashtable from "@/components/Profile/Dashboard/Dashtable";
export default {
  name: "Dashboard",
  components: {Dashtable, Tradevol, Userinfo}
}
</script>

<style lang="scss" scoped>
.dashboard{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>