<template>
  <div class="volume">
    <div class="vol">
    <div class="title">
      {{state.lan.vol.vol}}
    </div>
    <div class="amount">
      <p>{{$toLocal(price,$decimal['TOMAN'])}}</p>
    </div>

    </div>
<div :class="{'variety':state.lang==='en', 'varietyrtl':state.lang==='fa'}">
  <p class="title">{{state.lan.vol.variety}}</p>
  <p class="text">{{state.lan.vol.varietytext}}</p>
  <div class="bg">
<!--    <img class="chart" src="../../assets/Public/chart.svg" alt="chart" width="171" height="171">-->
<!--    <img  class="coins" src="../../assets/Public/coins.svg" alt="coin" width="154" height="41">-->
  </div>
</div>
  </div>
</template>
<script>
export default {
  name:"volume",
  props:['price'],
  data() {
    return {
      // manage: this.state.lan.homesup
      amount:231289284932
    }
  }
}


</script>
<style lang="scss" scoped>
.volume{
  display: flex;
  flex-direction: column;
  gap: 22px;
  background-size:cover ;
}
//first column that has volume trades
.vol{
  z-index: 1;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
justify-content: center;
  gap: 20px;
  height: 177px;
  border-radius: 16px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/Public/volume-bg.png");
  background-repeat: no-repeat;
    background-size: cover !important;
  .amount{
    align-self: center;
p{
  font-size: clamp(32px,2vw,40px);
}
  }
}
//second column that has variety of bazar
.varietyrtl{
  position: relative;
  height: 196px;
  padding: 24px 12px;
  gap:17px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(0deg, rgba(255,255,255,0.11) -22.23%, rgba(0, 0, 0, 0.11) 77.8%);
  background-size:cover !important;
  mix-blend-mode: screen;
  background-origin: left;
  border-radius: 12px;

  .title{
    font-size: clamp(20px,1.5vw,24px);
  }
  .text{
    font-size: clamp(13px,1vw,10px);
    //color: var(--gray);
  }



&::before{
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  position: absolute;
  background-image: url("../../assets/Public/coinvarrtl.svg");
    background-size:cover !important;
}
}
.variety{
  background-image: url("../../assets/Public/coinvar.svg");
  background-size: cover;

  background-position: center;
  background-repeat: no-repeat;
  height: 196px;
  padding: 24px 12px;
  gap:17px;
  display: flex;
  flex-direction: column;
  //justify-content: flex-end;

  mix-blend-mode: screen;
  border-radius: 12px;

  .title{
    font-size: clamp(20px,1.5vw,24px);
  }
  .text{
    max-width: 109px;
    font-size: clamp(13px,1vw,10px);
    //color: var(--gray);
  }

}

.bg{


position: absolute;

}

.coins{
  z-index: -1;
  position: absolute;
  top: 30px;
}
.chart{

}
</style>