<template>
  <div class="main">
    <div class="markc"><MarketCap :key="$route.params + 'paramscoins'"/></div>
    <div class="middle-view">
      <div class="trv">
        <Tradingv :key="$route.params + 'trview'"/>
      </div>
      <div class="depth"><Depth :key="$route.params + 'depth'" /></div>
    </div>
    <div class="history">
      <TradeHistory :key="$route.params + 'histories'"/>
    </div>
  </div>
</template>

<script>
import MarketCap from "@/components/Profile/Trade/MarketCap";
import Depth from "@/components/Profile/Trade/Depth";
import TradeHistory from "@/components/Profile/Trade/TradeHistory";
import Tradingv from "@/components/Profile/Trade/Tradingv";
export default {
  name: "View",
  components: {Tradingv, TradeHistory, Depth, MarketCap}
}
</script>

<style lang="scss" scoped>
.main{
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.middle-view{
  gap: 8px;
  flex-wrap: wrap;
  display: flex;
  .trv{
border-radius: 12px;
flex-grow: 1;
    min-width: 300px;


  }

  .depth{
min-width: 296px;
    //background: linear-gradient(180deg, #151739 0%, #191B34 100%);
    //border: 0.8px solid rgba(83, 79, 79, 0.5);
    //box-sizing: border-box;
  }
}


@media(max-width: 400px){
  .middle-view{
    gap: 30px;
  }
  .history{
    margin-top: 30px;
  }
}
</style>