<template>
  <div class="main-container">
<div class="views">
  <TomanDepositAction :banks=banks />
</div>
    <div class="table">
      <Tomandipotable/>
    </div>
  </div>
</template>

<script>

import Tomandipotable from "@/components/Profile/Wallet/tables/Tomandipotable.vue";
import TomanDepositAction from "@/components/Profile/Wallet/TomanDepositAction.vue";
export default {
  name: "TomanDiposit",
  data(){
    return{
      banks:''
    }
  },
  components:{
    TomanDepositAction,

    Tomandipotable
  },
  methods:{
    async getBanks(){
      const res = await this.$axios.get('/users/verified-bank-accounts')
      this.banks = res.content
    
    }
  },
  mounted(){
    this.getBanks()
  }
}
</script>

<style lang="scss" scoped>
//main container is here
.main-container{
  display: flex;
  flex-direction: column;
  gap:24px;
}
//views is the container that contains the guide and

</style>