<template>
  <div class="main-table">
    <div class="title">
      <p>{{ state.lan.wallet.coins }}</p>
      <div class="checkbox-container">
        <div class="">
          <input
            type="checkbox"
            name=""
            value="1"
            id="toman"
            @click="toman = !toman"
            ref="toman"
          />
          <label for="toman">{{ state.lan.wallet.showtoman }}</label>
        </div>
        <div class="">
          <input
            type="checkbox"
            name=""
            value="2"
            id="hide"
            @click="zero = !zero"
            ref="zero"
          />
          <label for="hide">{{ state.lan.wallet.hidezero }}</label>
        </div>
      </div>
    </div>
    <div class="table-container" :key="zero">
      <table>
        <tr class="tr-head">
          <td>{{ state.lan.marketv.coin }}</td>
          <td class="phone">{{ state.lan.wallet.value }}</td>
          <td class="intrade tablet">{{ state.lan.wallet.intrade }}</td>
          <td class="tablet">
            {{
              toman == true ? state.lan.wallet.totalt : state.lan.wallet.total
            }}
          </td>

          <td>{{ state.lan.marketv.action }}</td>
        </tr>
        <tr class="tr-body hover" v-for="(item, index)  in filteredWallet" :key="index + zero">
          <td>
            <div class="image-cont">
              <span
                >

                  <img :src="'/coins/'+ item[0] + '.png' " :alt="item[0]" width="28" height="28"/>



              </span>
              <p class="small">
                {{ state.lang === "fa" ? $coinLabel[item[0]] : item[0].toLowerCase().split("_").join(" ") }}
              </p>
            </div>
          </td>
          <td class="phone">
            {{ $toLocal(item[1].credit, 0) }}
          </td>
          <td class="tablet">
   0
          </td>
          <td class="tablet">
            {{ $toLocal(item[1].wholePriceOfCredit, 2) }}
          </td>
          <td>
            <a href="" @click.prevent="$router.push('/trade')">{{
              state.lan.marketv.trade
            }}</a>
            <a
              class="phone positive"
              href=""
              @click.prevent="$router.push(`/profile/crypto-dipo/${item[0]}`)"
              >{{ state.lan.wallet.dipositt }}</a
            >
            <a
              href=""
              class="phone negative"
              @click.prevent="$router.push(`/profile/crypto-wit/${item[0]}`)"
              >{{ state.lan.wallet.withdrawt }}</a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Crypto from "@/library/cryptoWallet";

export default {
  name: "WalletTable",
  props:['wallets'],
  data() {
    return {
      coins: Crypto,
      usdt: true,
      toman: false,
      zero: false,
      tomancash:0,
      sum:0,
    };
  },
computed:{
    filteredWallet(){

      if(this.wallets.length > 0){

       return  this.zero ? this.wallets.filter(e => e[1].credit !== 0) : this.wallets
      }
else return []




    }

},
  // watch:{
  //   wallets(){
  //    !this.wallets.length ? this.state.loading : !this.state.loading;
  //   }
  // },
//   methods: {
//     async getWallets() {
//       this.state.loading = true
//       // const coinsList="ETHEREUM,BITCOIN_CASH,TRON,RIPPLE,TETHER,TOMAN,BITCOIN"
//       this.loading = true
//       const [res,] = await this.$http.get('/wallets/customer-addresses?walletType=P2P')
//
//       if (res) {
//         let entireRes = Object.entries(res)
//         const sortCoins = this.$walletSortCoins
//         entireRes.sort((a, b) => sortCoins.indexOf(a[0]) - sortCoins.indexOf(b[0]))
//         this.tomancash = res["TOMAN"].credit
//         this.wallets = entireRes.filter(e => e[0] !== "TOMAN")
//
//
//
//
//
//       }
//       this.state.loading = false
//
//     },
//
//     async getData() {
//       this.state.loading = true
//       const res = await this.$axios.get('/coins/details')
//       this.coins = res.content
// this.state.loading =false
//     },
//
//
//
//
//
//   },
//   async mounted() {
//     this.state.loading = true
//     await this.getWallets()
//     await this.getData()
//     this.state.loading= false
//   }
};
</script>

<style lang="scss" scoped>
.image-cont {
  display: flex;
  gap: 8px;
  width: 120px;
  //justify-content: start;
  align-items: flex-start;
}
.table-container{
  padding-top: 0 !important ;
  max-height: 380px;
  overflow: auto;
}

table {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  overflow: scroll;
}
table::-webkit-scrollbar {
  display: none;
}
.tr-head {
  position: sticky;
  top: 0;
  background: #191730;
}

//table
table {
  table-layout: fixed;
  min-width: 100%;
  border-collapse: collapse;
}

td {
  font-size: clamp(12px, 1vw, 16px);
  font-weight: 400;
}

//thead
.tr-head {
  line-height: 50px;
  border-bottom: 1px #787878 solid;

  td {
    font-size: clamp(10px, 1vw, 13px) !important;
    color: var(--gray);

    p {
      font-size: clamp(12px, 1vw, 16px);
    }
  }

  td:first-child {
    width: 80px;
  }
}

.tr-head {
  td:last-child {
    padding: 0 75px;
  }
}

//tbody
.tr-body {
  height: 73px;
  border-collapse: collapse;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  td:last-child {
    display: flex;
    align-self: center;
    min-height: 55px;
    align-items: center;
    gap: 24px;
    justify-content: center;
  }
}

.tr-head,
.tr-body {
  td:nth-child(2) {
    text-align: center;
  }
}

.table-container {
  background: #191730;
  border-radius: 12px;
  padding: 20px 24px;
}

.tr-body:first-child {
  line-height: 80px;
}

//tablet and phone view
.tablet {
}

.phone {
}

.title {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: clamp(10px, 2vw, 32px);

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  input {
    width: clamp(14px, 2vw, 20px);
    height: clamp(14px, 2vw, 20px);
  }
}

.main-table {
  display: flex;
  flex-direction: column;
  gap: clamp(12px, 1.3vw, 24px);
}

.table-container {
  background: #191730;
  border-radius: 12px;

  .title {
  }
}

@media screen and (max-width: 1100px) {
  .table-container {
    justify-content: center;
    margin: 0 auto;
    width: 90%;
  }
  .tablet {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .tr-head {
    td:last-child {
      padding: 0;
    }
  }
  .phone {
    display: none;
  }
}
</style>
