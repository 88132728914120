<template>
<div class="uinfo">
  <div class="first-row">
<p v-if="state.userInfo.authorized==false">{{state.userInfo.email}} <img src="../../../assets/profile/kyc.svg" alt=""></p>

<p v-if="state.userInfo.authorized==true">{{state.userInfo.email}}</p>

    <a v-if="state.userInfo.authorized ==false" @click.prevent="$router.push('/kyc')">{{state.lan.dash.kyc}}</a>
    <a v-if="state.userInfo.authorized ==true"  href="" @click.prevent="$router.push('/kyc')">{{state.lang=='fa'? 'تغییر اطلاعات ':'Change information'}}</a>
  </div>
  <div class="second-row">
<p v-if="state.userInfo.authorized ==false">{{state.lan.dash.level}} <span>{{state.lan.dash.confirm}}</span></p>
    <p v-if="state.userInfo.authorized ==false">{{state.lan.dash.infotext}}</p>
    <p v-if="state.userInfo.authorized ==true">{{state.lang=='fa'? 'کاربر احراز هویت شده است.':'User is Authorized'}}</p>
  </div>
</div>
</template>

<script>
export default {
  name: "Userinfo",
  mounted(){
    console.log(this.state.userInfo)
  }
}
</script>

<style lang="scss" scoped>
//user info main container that has 2   rows
.uinfo{
  flex-direction: column;
  min-height: 188px;
  padding: 24px 18px;
  display: flex;
  background: #191730;
  border-radius: 12px;
  gap: 33px;
}
.first-row{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  p{
    font-size: clamp(20px,2vw,24px);
    font-weight: 400;
    color: var(--gray);
  }
  a{
    color: var(--light-blue);
  }
}
.second-row{
display: flex;
  flex-direction: column;
  gap: 16px;

p:last-child{
  font-size: clamp(10px,1vw,13px);
  color: var(--gray);
}
}
</style>