<template>
  <div class="home-page">
    <banner class="banner" />
<div class="content">
  <div class="main ">
    <market-view />
    <support-vue />
    <blog />
    <Join/>
  </div>
</div>
    <footer-vue />
  </div>
</template>

<script>
import Banner from "./Banner.vue";
import MarketView from "./MarketView.vue";
import SupportVue from "./Management.vue";
import Blog from "./Blog.vue";
import FooterVue from "../Public/Footer.vue";
import Join from "@/components/Home-page/Join";


export default {
  name: "HomePage",
  components: {
    Join,
    Banner,
    MarketView,
    SupportVue,
    Blog,
    FooterVue,
  },
  mounted(){
    console.log('I have token :' , 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJqb2RhdHBhbmFoMkBnbWFpbC5jb20iLCJpYXQiOjE2NTQ1Nzk5MjEsImV4cCI6MTY4NjExNTkyMX0.BLN9uIsQiemluwICs_DdOD5-Hzr_8Cv98vu7e0LTIpc' )
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  gap: 81px;
  display: flex;
  flex-direction: column;
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 104px;
  padding: 0 ;
}

.banner{
  width: 100% !important;

}

@media(max-width: 768px){
  .main{
    row-gap: 20px;
  }
}
</style>
