<template>
  <div class="about-us">
    <div class="title">
<div class="content">
  <div class="banner-text">
<div class="contents">
  <h4>{{state.lan.aboutus.title}}</h4>
  <p>{{state.lan.aboutus.text}}</p>
</div> 
</div>
  <div class="anime">
    <img src="../../assets/Public/aboutsvg.svg" alt="">
  </div>
</div>

    </div>
    <div class="cont">
      <div class="card">
        <p>{{state.lan.aboutus.amount}}</p>
        <p>{{$toLocal(totaltrad,$decimal['TOMAN'])}}</p>
      </div>
      <div class="card">
        <p>{{state.lan.aboutus.vol}}</p>
        <p>{{$toLocal(tradprice,$decimal['TOMAN'])}}</p>
      </div>
      <div class="card">
        <p>{{state.lan.aboutus.ausers}}</p>
        <p>{{$toLocal(userCount, $decimal['TOMAN'])}}</p>
      </div>
    </div>
    <footer-component/>
  </div>
</template>

<script>
import FooterComponent from "@/components/Public/Footer";
export default {
  name: "Aboutus",
  components: {FooterComponent},
  data(){
    return{
      userCount:0,
      tradprice:0,
      totaltrad:0
    }
  },
    methods:{
    async getInfo(){
      const res = await this.$axios('trades/market-info-home')
      this.userscount = res.baseDTO.userCount
      this.tradprice = res.baseDTO.tradesValue
      this.totaltrad = res.baseDTO.tradesAmount
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style lang="scss" scoped>
.about-us{
display: flex;
  flex-direction: column;
  gap: 36px;
}
//title that contains texts and some svg's
.title{
  position: relative;
  display: flex;
  flex-direction: column;
  //padding: 60px;
  gap: 23px;
  justify-content: center;
  align-items: center;
  //align-items: center;

  //background: linear-gradient(115.97deg, #181A48 0%, #090A29 106.55%);
  height: 424px;

  z-index: 2;

  p{
    font-size: clamp(20px,1vw,24px);
  }

}
.title::before{
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: url("../../assets/Public/bubble.svg");

  position: absolute;
  //background-color: red;
  background-size: cover;
  z-index: -2;
}

.content{
  width: 100%;
  height: 100%;
  min-height: 542px;
  display: flex;
  justify-content: space-between;


}

//container of texts in banner
.banner-text{
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  button{
    width: 187px;
    height: 44px;
    background: linear-gradient(91.87deg, #6085D5 0%, #2850A8 53.83%, #7B9EEB 112.8%);
    border-radius: 12px;
  }
  .contents{
    display: flex;
    flex-direction: column;
    //align-items: center;
    width: 65%;
    gap: 32px;
    //title
    h4{
      font-size: clamp(24px,2vw,48px);
      color: var(--light-blue);
      font-weight: 700;
    }
    //text
    p{
      width: 70%;
      font-size: clamp(12px,1vw,16px);
    }
    //start btn

  }
}

//anime
.anime{
  align-self: center;
}

//container of volumes and bluh...
.cont{
  display: flex;
  width:100%;
  max-width:1200px;
  margin: 0 auto;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: clamp(20px,3vw,90px);
}
.card{
  p:first-child{
    font-size: clamp(12px,2vw,16px);
  }
  p:last-child{
    font-size: clamp(28px,2vw,36px);
  }
flex-grow: 1;
  flex-basis: 100px;
  min-height: 138px;
  background: linear-gradient(115.97deg, rgba(56, 59, 131, 0.57) 0%, #07092D 106.55%);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}





@media (max-width: 876px) {
  .title{
    height: unset;
  }
  .content{
    min-height: unset;
  }
  .title{
    min-height: unset;
  }
.banner-text{
  flex-direction: column;
  .contents {

    padding: 30px;
        width: 100%;
    p {
      width: 100%;
    }
  }
  }
  .anime{
    display: none;
  }
  //.banner-view{
  //  padding: 20px;
  //  min-height: unset;
  //}
  //.content{
  //  flex-direction: column;
  //  min-height: unset ;
  //
  //}
  button{
    align-self: center;
  ;
  }

}

@media(max-width: 400px){
.cont{
  padding: 20px;
}
}
</style>