<template>
  <div class="modal-backdrop" @click.self="$emit('close')">
    <div class="modal">
        <div class="d-flex flex-col gap-xss" v-if="state.lang=='fa'">
            <h5 class="align-self-center">واریز {{$coinUnit[coinTarget]}}</h5>
<p>برای بررسی واریز لطفا تصویر را اسکن نمایید
    
</p>
<span class="small" v-if="tag">تگ: {{tag}}</span>

        </div>
        <div class="d-flex flex-col gap-xss" v-if="state.lang!=='fa'">
                        <h5 class="align-self-center">Deposit {{$coinUnit[coinTarget]}}</h5>
<p>Please Scan picture for check transaction</p>
<span class="small" v-if="tag">tag: {{tag}}</span>

        </div>
         <img :src="'data:image/png;base64,'+qrCode" alt="" width="200" height="200"/>


<button class="btn-primary" @click="checkWallet">
    {{state.lang=='fa'?'بررسی واریز':'Check Transaction'}}
</button>


    </div>

  </div>
</template>

<script>
export default {
name:'qrModal',
props:['qrCode','coinTarget','tag'],
methods:{
        async checkWallet() {
      this.state.loading = true
      // const token = this.token ? 'ERC20' : 'TRC20'
      let query = {
        relatedCoin: this.coinTarget,

      }
      if (this.txIdIsNeeded) {
        query.txid = this.txid
      }
      const res = await this.$axios.get('/wallets/check-confirmed-transaction', {
        params: query
      })
      let text
if(this.state.lang ==='fa'){
          if (res.baseDTO.amount) {
        text = `مقدار ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} واریز شده و تایید ${res.baseDTO.transactionIsDone ? 'شده' : 'نشده'}`
      } else {
        text = 'مقدار واریزی شناسایی نشد'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
}

if(this.state.lang !=='fa'){
              if (res.baseDTO.amount) {
        text = `amount ${this.$toLocal(res.baseDTO.amount) || 0} ${this.$coinLabel[this.relatedCoin[0]]} Deposit and approved ${res.baseDTO.transactionIsDone ? 'done' : 'undone'}`
      } else {
        text = 'amount deposit not found'
      }
      this.$error('', text, res.baseDTO.transactionIsDone ? 'success' : 'info')
}
    },
}
}
</script>

<style lang='scss' scoped>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(111, 111, 112, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}
.modal {
  display: flex;
  row-gap: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 367px;
  min-height: 276px;
  background: #191730;
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 15px;
}
button{
    background: linear-gradient(91.87deg, #47EB57 0%, #44AD62 53.83%, #41DF55 112.8%);
border-radius: 8px;
height: 48px;
width: 100%;
}
</style>